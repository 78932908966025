import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import { checkIfTheFiltersAreEmpty, constructSearchContentQuery } from './helpers/advanced-content-filter.helper';
import AdvancedFilterModel from './models/advanced-content-filter.model';
import ConditionalRenderWrapper from '../../BaseComponents/ConditionalRenderWraper/ConditionalRenderWrapper';
import { Subject } from 'rxjs';
import Project from '../../../../models/project/Project';
import Category from '../../../../models/category/Category';
import ContentAttributes from '../../../../models/content-attributes/content-attributes-model';
import DateTimePickerContainer from '../../Sidebar/GeneralContentAttributes/subcomponents/DateTimePicker/DateTimePickerContainer';
import {
	returnObjectForJWVideoFiltersUpdate,
	returnObjectForVideoFiltersUpdate,
} from '../../../../store/action-creators/VideoActionCreator';
import { returnObjectForGalleryFiltersUpdate } from '../../../../store/action-creators/GalleryActionCreator';
import {
	returnObjectForGettyImagesFiltersUpdate,
	returnObjectForImageFiltersUpdate,
	returnObjectForImagoImagesFiltersUpdate,
	returnObjectForPlayingSurfaceFiltersUpdate,
} from '../../../../store/action-creators/ImageActionCreator';
import AdvancedFiltersFields from './advanced-filters-fields.component';
import { ContentTypes, MainContentTypes } from '../../../../constants/content-types';
import SportsTypesModel from '../../../../models/v2/sports-types/sports-types.model';
import { appendJWFilter, hideClearFiltersButtonForJWSection, isJWSection } from '../../../Resources/Videos/Helpers/VideoHelper';
import {
	appendCorrectArticleFilter,
	articleOrigins,
	getArticleFilters,
	getArticleFiltersUpdate,
	hideClearFiltersButtonForAiArticlesSection,
	hideClearFiltersButtonForExternalArticlesSection,
	isAiArticleSection,
	isExternalArticleSection,
} from '../../../Resources/Articles/Helpers/ArticleHelper';
import { updateAiArticlesAdvancedSearchFilters } from '../../../../store/action-creators/ai-articles-action-creator';

export type Properties = {
	currentProject: Project;
	t: any;
	updateAdvancedSearchText: (input: string) => any;
	searchContent: any;
	allCategories: Category[];
	profileId: string;
	filtersInSidebar: boolean;
	getAllContent: any;
	contentSearchInput: string;
	imageOrigins: ContentAttributes[];
	videoOrigins: ContentAttributes[];
	showOriginSelect: boolean;
	calledFrom?: string;
	contentType?: string;
	saveFiltersHistory?: boolean;
	articleTypes: ContentAttributes[];
	statuses: ContentAttributes[];
	articleFiltersUpdate: (filters: any) => void;
	videoFiltersUpdate: (filters: any) => void;
	galleryFiltersUpdate: (filters: any) => void;
	imageFiltersUpdate: (filters: any) => void;
	playingSurfaceFiltersUpdate: (filters: any) => void;
	imagoImageFiltersUpdate: (filters: any) => void;
	gettyFiltersUpdate: (filters: AdvancedFilterModel) => void;
	showTypeSelect: boolean;
	sports?: SportsTypesModel[];
	aiArticlesFiltersUpdate: (filters: AdvancedFilterModel) => void;
};

export type State = {
	advancedContentModel: AdvancedFilterModel;
	toggleAdvancedFilters: boolean;
};

class AdvancedContentFilter extends React.Component<Properties, State> {
	readonly clearService = new Subject<void>();

	constructor(props: any) {
		super(props);
		this.state = {
			advancedContentModel: props[`${this.props.contentType}Filters`]
				? props[`${this.props.contentType}Filters`]
				: AdvancedFilterModel.builder().build(),
			toggleAdvancedFilters: false,
		};
	}

	componentDidMount() {
		const advancedContentSearchText = constructSearchContentQuery(this.state.advancedContentModel);
		if (advancedContentSearchText && advancedContentSearchText.length > 0) {
			this.setState({ toggleAdvancedFilters: true });
		}
		this.props.updateAdvancedSearchText(advancedContentSearchText);
		const areAdvancedFIltersEmpty = checkIfTheFiltersAreEmpty(this.props[`${this.props.contentType}Filters`]);
		!areAdvancedFIltersEmpty && this.toggleAdvanceFilters();
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>, snapshot?: any) {
		if (this.props.currentProject !== prevProps.currentProject) {
			this.props.articleFiltersUpdate(AdvancedFilterModel.builder().build());
			this.props.videoFiltersUpdate(AdvancedFilterModel.builder().build());
			this.props.galleryFiltersUpdate(AdvancedFilterModel.builder().build());
			this.props.imageFiltersUpdate(AdvancedFilterModel.builder().build());
			this.props.playingSurfaceFiltersUpdate(AdvancedFilterModel.builder().build());
			this.props.imagoImageFiltersUpdate(AdvancedFilterModel.builder().build());
			this.props.gettyFiltersUpdate(AdvancedFilterModel.builder().build());
			this.props.aiArticlesFiltersUpdate(AdvancedFilterModel.builder().build());
			this.setState({ advancedContentModel: AdvancedFilterModel.builder().build() });
		}
	}

	onDateSelect = (dates: string[]) => {
		const advancedContentModel = AdvancedFilterModel.builder(this.state.advancedContentModel).withDate(dates).build();
		this.updateAdvancedSearchText(advancedContentModel);
	};

	updateAdvancedSearchText = (advancedContentModel: AdvancedFilterModel) => {
		const { contentType } = this.props;
		const advancedContentSearchText = constructSearchContentQuery(advancedContentModel);

		this.props.updateAdvancedSearchText(advancedContentSearchText);

		if (this.props.saveFiltersHistory !== false) {
			// Update filters in the store
			let filters = AdvancedFilterModel.builder(advancedContentModel)
				.withSearchText(this.props.contentSearchInput)
				.withSportsType(advancedContentModel.sportsType)
				.build();
			this.props[`${contentType}FiltersUpdate`](filters);
		}
		this.setState({ ...this.state, advancedContentModel });
		if (contentType !== 'imagoImage') {
			if (
				this.props.contentSearchInput.match(/^ *$/) !== null &&
				advancedContentSearchText.match(/^ *$/) !== null &&
				!advancedContentModel.sportsType
			) {
				this.props.getAllContent();
			}
		}

		if (contentType !== ContentTypes.GETTY) {
			if (
				this.props.contentSearchInput.match(/^ *$/) !== null &&
				advancedContentSearchText.match(/^ *$/) !== null &&
				!advancedContentModel.sportsType
			) {
				this.props.getAllContent();
			}
		}
	};

	clearAdvancedFilters = () => {
		this.clearService.next();
		let advancedContentFilters = AdvancedFilterModel.builder().build();
		const filtersType = isExternalArticleSection() ? articleOrigins.EXTERNAL : articleOrigins.AI_ARTICLE;
		isJWSection() && appendJWFilter(advancedContentFilters);
		(isExternalArticleSection() || isAiArticleSection()) && appendCorrectArticleFilter(advancedContentFilters, filtersType);
		this.updateAdvancedSearchText(advancedContentFilters);
	};

	toggleAdvanceFilters = () =>
		this.setState({
			...this.state,
			toggleAdvancedFilters: !this.state.toggleAdvancedFilters,
		});

	render() {
		const {
			currentProject,
			t,
			filtersInSidebar,
			showOriginSelect,
			calledFrom,
			profileId,
			contentType,
			showTypeSelect,
			articleTypes,
			sports,
		} = this.props;
		const { advancedContentModel } = this.state;
		const showClearFiltersButton = checkIfTheFiltersAreEmpty(this.props[`${contentType}Filters`]);
		const hideClearFilters =
			hideClearFiltersButtonForJWSection() ||
			hideClearFiltersButtonForExternalArticlesSection() ||
			hideClearFiltersButtonForAiArticlesSection();

		return (
			<>
				<Row>
					<Col className='d-flex'>
						<button className='btn btn-link ml-auto' onClick={this.toggleAdvanceFilters}>
							{this.state.toggleAdvancedFilters ? `${t('hide_advanced_filters')}` : `${t('show_advanced_filters')}`}
						</button>
						{!showClearFiltersButton || hideClearFilters ? null : (
							<button className='btn btn-link text-danger' onClick={this.clearAdvancedFilters}>
								{t('clear_filters')}
							</button>
						)}
					</Col>
				</Row>
				{this.state.toggleAdvancedFilters && (
					<ConditionalRenderWrapper expectedPermissions={['read_admins']}>
						<Row>
							<AdvancedFiltersFields
								t={t}
								profileId={profileId}
								filtersInSidebar={filtersInSidebar}
								updateAdvancedSearchText={this.updateAdvancedSearchText}
								currentProject={currentProject}
								advancedContentModel={this.state.advancedContentModel}
								allCategories={this.props.allCategories}
								showOriginSelect={showOriginSelect}
								imageOrigins={this.props.imageOrigins}
								videoOrigins={this.props.videoOrigins}
								calledFrom={calledFrom}
								contentType={contentType as MainContentTypes}
								articleTypes={articleTypes}
								showTypeSelect={showTypeSelect ? showTypeSelect : false}
								statuses={this.props.statuses}
								sports={sports}
							/>
							<Col col='6' sm='12' md={`${filtersInSidebar ? '0' : '4'}`} className={`${filtersInSidebar ? 'mb-2' : 'mb-0'}`}>
								<FormGroup>
									<Label htmlFor='date'>{t('date_from_to')}</Label>
									<DateTimePickerContainer
										id={`advanced-filter-date-${calledFrom ? calledFrom : ''}`}
										date={advancedContentModel.date}
										dateFormat='Y.m.d'
										resetButton
										clearService={this.clearService}
										t={t}
										mode='range'
										enableTime={false}
										onDateChange={this.onDateSelect}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row className='mt-3 mb-3'>
							<Col>
								<Button type='button' color='primary' id='advanced-filters-search-button' onClick={this.props.searchContent}>
									<i className={'fa fa-search'}>&nbsp;</i>
									{t('search')}
								</Button>
								{hideClearFilters ? null : (
									<Button
										id='advanced-filters-clear-button'
										type='button'
										color='white'
										className='btn btn-outline-danger ld-ext-right px-2 ml-2'
										onClick={this.clearAdvancedFilters}
									>
										{t('clear_filters')}
									</Button>
								)}
							</Col>
						</Row>
					</ConditionalRenderWrapper>
				)}
			</>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		currentProject: state.project.currentProject,
		allCategories: state.category.allCategories,
		profileId: state.profile.profile.id,
		imageOrigins: state.origins.imageOrigins,
		videoOrigins: state.origins.videoOrigins,
		articleFilters: getArticleFilters(state),
		videoFilters: isJWSection() ? state.video.jwFilters : state.video.filters,
		galleryFilters: state.gallery.filters,
		imageFilters: state.image.filters,
		playingSurfaceFilters: state.image.playingSurfaceFilters,
		imagoFilters: state.image.imagoFilters,
		gettyFilters: state.image.gettyFilters,
		articleTypes: state.types.articleTypes,
		statuses: state.statuses,
		sports: state.sports.sportsTypes,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		articleFiltersUpdate: getArticleFiltersUpdate(dispatch),
		aiArticlesFiltersUpdate: (filters: AdvancedFilterModel) => dispatch(updateAiArticlesAdvancedSearchFilters(filters)),
		videoFiltersUpdate: (filters: AdvancedFilterModel) =>
			dispatch(isJWSection() ? returnObjectForJWVideoFiltersUpdate(filters) : returnObjectForVideoFiltersUpdate(filters)),
		galleryFiltersUpdate: (filters: AdvancedFilterModel) => dispatch(returnObjectForGalleryFiltersUpdate(filters)),
		imageFiltersUpdate: (filters: AdvancedFilterModel) => dispatch(returnObjectForImageFiltersUpdate(filters)),
		playingSurfaceFiltersUpdate: (filters: AdvancedFilterModel) => dispatch(returnObjectForPlayingSurfaceFiltersUpdate(filters)),
		imagoImageFiltersUpdate: (filters: AdvancedFilterModel) => dispatch(returnObjectForImagoImagesFiltersUpdate(filters)),
		gettyFiltersUpdate: (filters: AdvancedFilterModel) => dispatch(returnObjectForGettyImagesFiltersUpdate(filters)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedContentFilter);
