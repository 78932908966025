import { SelectMenuOptionType } from '../../../../../../../models/v2/general/select.model';
import BasketballRoundModel from '../../../../../../../models/v2/round/entity/basketball-round.model';

export const basketballRoundsToOptions = (rounds: BasketballRoundModel[]) => {
	if (rounds && rounds.length > 0) {
		return rounds.map((round) => {
			return basketballRoundToOption(round);
		});
	}

	return [] as SelectMenuOptionType[];
};

export const basketballRoundToOption = (round: BasketballRoundModel): SelectMenuOptionType => {
	if (round) {
		return {
			value: round.uuid,
			label: round.name,
			data: round,
		};
	} else {
		return {} as SelectMenuOptionType;
	}
};
