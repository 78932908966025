import { Properties, State } from './properties/football-connections-container.properties';
import * as React from 'react';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import HttpService from '../../../../../../services/rest/HttpService';
import { customOption } from './option-component';
import _ from 'lodash';
import {
	addClubInPlayerFootballConnection,
	convertResultToOption,
	extractSeasonFromRelated,
	extractSeasonTournamentFromRelated,
	footballRelatedToOptions,
	importTournamentInSeasons,
	seasonsToOptions,
	seasonToOption,
	selectionToRelated,
	tournamentToOption,
} from './helpers/football-conections-container.helper';
import { connect } from 'react-redux';
import { relatedConstants } from '../../../../../../constants/related.constants';
import Related from '../../../../../../models/related/Related';
import { Label } from 'reactstrap';

const colorStyles = {
	multiValue: (styles: any, state: any) => {
		const color = colors[state.data.type];

		return {
			...styles,
			borderBottom: `2px solid ${color}`,
		};
	},
};

export const colors = {
	team: '#fc1111',
	player: '#27db3f',
	tournament: '#adccf7',
	coach: '#215bac',
	venue: '#000000',
	club: '#fc1111',
	team_president: '#ffea2c',
};

class FootballConnectionsContainer extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			newValue: '',
			selectedOptions: [],
			selectedSeason: null,
			selectedTournament: null,
			seasons: [],
		};
	}

	private onInputChange = (input: string) => {
		this.setState({ newValue: input });

		return input;
	};

	private onSelect = (selections: any) => {
		// This is done because of https://github.com/JedWatson/react-select/issues/3632
		if (this.state.selectedSeason) {
			selections = selections === null ? [] : selections;
			selections.push(this.state.selectedSeason);
		} else {
			selections = selections === null ? [] : selections;
		}

		let relatedData: Related[] = [];
		this.remmapFootballSelections(selections).then((response: Related[]) => {
			relatedData = selectionToRelated(response);
			this.props.onSelect(relatedData);
		});
	};

	private onSeasonSelect = (season: any) => {
		let selections = footballRelatedToOptions(this.props.related);

		if (season) {
			selections.push(season);
		}

		let relatedData: Related[] = [];
		this.remmapFootballSelections(selections).then((response: Related[]) => {
			relatedData = selectionToRelated(response);
			this.props.onSelect(relatedData);
		});
	};

	private onTournamentSelect = (tournament: any) => {
		this.setState(
			{
				selectedTournament: tournament ? tournament.data : null,
				seasons: [],
			},
			() => {
				if (tournament && tournament.data) {
					this.loadSeasonsData(tournament.data.id);
				} else {
					this.onSeasonSelect(null);
				}
			},
		);
	};

	remmapFootballSelections = (relatedData: any): Promise<Related[]> => {
		return new Promise((resolve, reject) => {
			const requests = relatedData
				.filter((data: any) => data.type === relatedConstants.types.player && !data.data.club)
				.map((data: any) =>
					HttpService.getPlayerById(data.data.id)
						.then()
						.catch((e: any) => reject(e)),
				);

			if (requests.length < 1) {
				resolve(relatedData);
			}

			Promise.all(requests).then((response: any[]) => {
				const otherRelatedData = addClubInPlayerFootballConnection(relatedData, response);
				resolve(otherRelatedData);
			});
		});
	};

	private loadFootballData = (input: string, callback: any) => {
		const { allowMoreFootballConnections, inWikiPagesComponent } = this.props;
		let lang = this.props.language.substring(0, 2);

		if (input.length > 2) {
			if (inWikiPagesComponent) {
				HttpService.getFootballData(input, lang, 'player,team,venue,coach').then((response: any) => {
					let options = response.data.map((result: any) => {
						return convertResultToOption(result);
					});
					callback([...options]);
				});
			} else {
				allowMoreFootballConnections
					? HttpService.getFootballData(input, lang, 'tournament,player,team,president,venue,coach').then((response: any) => {
							let options = response.data.map((result: any) => {
								return convertResultToOption(result);
							});
							callback([...options]);
					  })
					: HttpService.getFootballData(input, lang, 'player,team').then((response: any) => {
							let options = response.data.map((result: any) => {
								return convertResultToOption(result);
							});
							callback([...options]);
					  });
			}
		}
	};

	private loadTournamentsData = (input: string, callback: any) => {
		let lang = this.props.language.substring(0, 2);
		if (input.length > 3) {
			HttpService.getFootballData(input, lang, 'tournament').then((response: any) => {
				let options = response.data.map((result: any) => {
					return convertResultToOption(result);
				});
				callback([...options]);
			});
		}
	};

	private loadSeasonsData = (tournamentId: string) => {
		HttpService.getTournamentById(tournamentId).then((response: any) => {
			this.setState({
				seasons: importTournamentInSeasons(response.data),
			});
		});
	};

	componentDidMount(): void {
		this.updateStateWithOptions();
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>): void {
		if (prevProps.related !== this.props.related) {
			this.updateStateWithOptions(prevState);
		}
	}

	updateStateWithOptions = (prevState: Readonly<State> | null = null) => {
		const selectedOptions = footballRelatedToOptions(this.props.related);
		const selectedSeason = extractSeasonFromRelated(this.props.related);
		const selectedTournament = extractSeasonTournamentFromRelated(this.props.related);

		this.setState(
			{
				selectedOptions: selectedOptions,
				selectedSeason: selectedSeason,
				selectedTournament: selectedTournament ? selectedTournament : this.state.selectedTournament,
			},
			() => {
				if (selectedTournament && prevState != null && prevState.selectedTournament !== selectedTournament) {
					this.loadSeasonsData(selectedTournament.id);
				}
			},
		);
	};

	render() {
		return (
			<div>
				<AsyncSelect
					inputId={this.props.inputId + Math.random()}
					noOptionsMessage={(inputValue) => inputValue && this.props.t('no_options')}
					isMulti={true}
					value={this.state.selectedOptions}
					loadOptions={_.debounce(this.loadFootballData, 500)}
					onInputChange={_.debounce(this.onInputChange, 500)}
					onChange={this.onSelect}
					formatOptionLabel={customOption}
					placeholder={this.props.t('select')}
					styles={colorStyles}
					isClearable={true}
				/>
				<div style={{ marginTop: '10px' }}>
					{this.props.withSeason ? (
						<>
							<Label>{this.props.t('season')}</Label>
							<AsyncSelect
								inputId={this.props.inputId + Math.random()}
								noOptionsMessage={(inputValue) => inputValue && this.props.t('no_options')}
								isMulti={false}
								value={tournamentToOption(this.state.selectedTournament)}
								loadOptions={_.debounce(this.loadTournamentsData, 500)}
								onInputChange={_.debounce(this.onInputChange, 500)}
								onChange={this.onTournamentSelect}
								formatOptionLabel={customOption}
								isClearable={true}
								placeholder={this.props.t('select')}
								styles={colorStyles}
							/>
						</>
					) : null}
					{this.state.seasons.length > 0 ? (
						<div style={{ marginTop: '10px' }}>
							<Select
								inputId={this.props.inputId + Math.random()}
								noOptionsMessage={(inputValue) => inputValue && this.props.t('no_options')}
								isMulti={false}
								options={seasonsToOptions(this.state.seasons)}
								onChange={this.onSeasonSelect}
								isClearable={true}
								value={seasonToOption(this.state.selectedSeason)}
							/>
						</div>
					) : null}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		language: state.project.currentProject.language,
	};
}

export default connect(mapStateToProps)(FootballConnectionsContainer);
