import * as React from 'react';
import { featuresService } from '../../../../../../App';
import { FeatureTypes } from '../../../../../../services/feature-service/features.enum';
import './ListingHeaders.scss';

import { isExternalArticleSection } from '../../../../../Resources/Articles/Helpers/ArticleHelper';

type ListingHeadersProps = {
	t: any;
};

export const HeadersArticle: React.FunctionComponent<ListingHeadersProps> = ({ t }) => {
	const contentStatisticsConfig = featuresService.getFeatureConfig(FeatureTypes.CONTENT_STATISTICS);

	return (
		<thead>
			<tr>
				<th className='w-title'>{t('title')}</th>
				{isExternalArticleSection() && <th className=' w-origins d-none d-sm-table-cell'>{t('origin')}</th>}
				<th className='w-active d-none d-sm-table-cell'>{t('active')}</th>
				<th className='w-createdBy d-none d-sm-table-cell'>{t('created_by')}</th>
				<th className='w-date d-none d-sm-table-cell'>{t('published_at')}</th>
				<th className='w-category d-none d-sm-table-cell'>{t('category')}</th>
				{featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_STATISTICS) &&
					contentStatisticsConfig.mappings &&
					contentStatisticsConfig.mappings.length > 0 &&
					contentStatisticsConfig.mappings
						.filter((statistic: any) => statistic.is_main_statistic)
						.map((statistic: any) => {
							return (
								<th key={statistic.display_key} className='display-none d-sm-table-cell align-middle text-center'>
									{t(statistic.display_key)}
								</th>
							);
						})}
				<th className='w-buttons text-right'>{t('actions')}</th>
			</tr>
		</thead>
	);
};

export const HeadersVideo: React.FunctionComponent<ListingHeadersProps> = ({ t }) => {
	return (
		<thead>
			<tr>
				<th className='w-title'>{t('title')}</th>
				<th className=''>{t('active')}</th>
				<th className='w-createdBy d-none d-sm-table-cell'>{t('created_by')}</th>
				<th className='w-date d-none d-sm-table-cell'>{t('published_at')}</th>
				<th className='w-category d-none d-sm-table-cell'>{t('category')}</th>
				<th className='w-buttons text-right'>{t('actions')}</th>
			</tr>
		</thead>
	);
};

export const HeadersGallery: React.FunctionComponent<ListingHeadersProps> = ({ t }) => {
	return (
		<thead>
			<tr>
				<th className='w-title'>{t('title')}</th>
				<th className=''>{t('active')}</th>
				<th className='w-createdBy d-none d-sm-table-cell'>{t('created_by')}</th>
				<th className='w-date d-none d-sm-table-cell'>{t('published_at')}</th>
				<th className='w-category d-none d-sm-table-cell'>{t('category')}</th>
				<th className='w-buttons text-right'>{t('actions')}</th>
			</tr>
		</thead>
	);
};

export const HeadersBanner: React.FunctionComponent<ListingHeadersProps> = ({ t }) => {
	return (
		<thead>
			<tr>
				<th className='w-80'>{t('title')}</th>
				<th className='w-20 text-right'>{t('actions')}</th>
			</tr>
		</thead>
	);
};

export const HeadersList: React.FunctionComponent<ListingHeadersProps> = ({ t }) => {
	return (
		<thead className='w-100'>
			<tr>
				<th className='w-80'>{t('title')}</th>
				<th className='w-20' style={{ position: 'relative', right: '13%' }}>
					{t('active')}
				</th>
				<th className='w-20 text-right'>{t('actions')}</th>
			</tr>
		</thead>
	);
};

export const HeadersAuthor: React.FunctionComponent<ListingHeadersProps> = ({ t }) => {
	return (
		<thead>
			<tr>
				<th className='w-80'>{t('name')}</th>
				<th className='w-20 text-right'>{t('actions')}</th>
			</tr>
		</thead>
	);
};

export const HeadersTag: React.FunctionComponent<ListingHeadersProps> = ({ t }) => {
	return (
		<thead>
			<tr>
				<th className='w-80'>{t('title')}</th>
				<th className='w-20 text-right'>{t('actions')}</th>
			</tr>
		</thead>
	);
};

export const HeadersCategory: React.FunctionComponent<ListingHeadersProps> = ({ t }) => {
	return (
		<thead>
			<tr>
				<th className='w-80'>{t('title')}</th>
				<th className='w-20 text-right'>{t('actions')}</th>
			</tr>
		</thead>
	);
};

export const HeadersPoll: React.FunctionComponent<ListingHeadersProps> = ({ t }) => {
	return (
		<thead>
			<tr>
				<th className='w-80'>{t('title')}</th>
				<th className='text-right'>{t('actions')}</th>
			</tr>
		</thead>
	);
};

export const HeadersFeed: React.FunctionComponent<ListingHeadersProps> = ({ t }) => {
	return (
		<thead>
			<tr>
				<th className='w-80'>{t('title')}</th>
				<th className='text-right'>{t('actions')}</th>
			</tr>
		</thead>
	);
};

export const HeadersTeam: React.FunctionComponent<ListingHeadersProps> = ({ t }) => {
	return (
		<thead>
			<tr>
				<th className='w-title'>{t('team_name')}</th>
				<th className=''>{t('country')}</th>
				<th className='w-date d-none d-sm-table-cell'>{t('status')}</th>
				<th className='w-buttons text-right'>{t('actions')}</th>
			</tr>
		</thead>
	);
};

export const HeadersPlayer: React.FunctionComponent<ListingHeadersProps> = ({ t }) => {
	return (
		<thead>
			<tr>
				<th className='w-title'>{t('player_name')}</th>
				<th className=''>{t('nationality')}</th>
				<th className='w-date d-none d-sm-table-cell'>{t('status')}</th>
				<th className='w-buttons text-right'>{t('actions')}</th>
			</tr>
		</thead>
	);
};

export const HeadersCoach: React.FunctionComponent<ListingHeadersProps> = ({ t }) => {
	return (
		<thead>
			<tr>
				<th className='w-title'>{t('coach_name')}</th>
				<th className=''>{t('nationality')}</th>
				<th className='w-date d-none d-sm-table-cell'>{t('status')}</th>
				<th className='w-buttons text-right'>{t('actions')}</th>
			</tr>
		</thead>
	);
};
