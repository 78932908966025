import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MultisportHttpService from '../../../../services/rest/multisport-http-service';
import { AppState } from '../../../../store/store';
import { CompetitionResponse, CountryCompetitions, IEventsByDate, IEventsResponse } from '../../../Partials/Blocky/v2/types/livescore';
import {
	sortCompetitionsByCountry,
	sortEventsByCompetitionAndDate,
} from '../../../Partials/Blocky/v2/widgets-v2/helpers/sortCompetitionsByCountry';

export const useCompetitionsListByDateRange = (sport: string, dateRange: string[]) => {
	const [t] = useTranslation();
	const [competitions, setCompetitions] = useState<CountryCompetitions[]>([]);
	const [events, setEvents] = useState<IEventsByDate[]>([]);

	const [isLoading, setIsLoading] = useState(true);
	const language = useSelector((state: AppState) => state.project.currentProject.language);

	const getCompetitions = (sport: string, lang: string) => {
		MultisportHttpService.getCompetitionListingAndEventsByDateRange(dateRange, sport, lang)
			.then((response) => {
				// Extract competitions
				const remappedCompetitions = response.data.competitions.map(({ competition }: { competition: CompetitionResponse }) => competition);

				// Extract events from each competition entry
				const remappedEvents = response.data.competitions
					.flatMap(({ events }: { events: IEventsResponse }) => events || [])
					.map((event: IEventsResponse) => event);

				const competitionData = sortCompetitionsByCountry(remappedCompetitions, t);
				const eventData = sortEventsByCompetitionAndDate(remappedEvents);

				competitionData && setCompetitions(competitionData);
				setEvents(eventData);
				setIsLoading(false);
			})
			.catch((e: any) => e);
	};

	useEffect(() => {
		if (dateRange && dateRange[1] && dateRange[1].length > 0) {
			getCompetitions(sport, language);
		}
	}, [dateRange]);

	return { competitions, events, isLoading };
};
