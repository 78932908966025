import axios from 'axios';
import { featuresService } from '../../App';
import { store } from '../../store/store';
import REACT_APP_URLS from '../../global-helpers/global-url.helpers';

const STANDING_SEARCH_API_URL = REACT_APP_URLS.REACT_APP_STANDING_API_URL;

export default class StandingHttpService {
	static instance = (sport: string, acceptLang?: any) => {
		const config = featuresService.getFeatureConfig(sport);

		return axios.create({
			baseURL: STANDING_SEARCH_API_URL,
			timeout: 30000,
			headers: {
				'X-Project': `${store.getState().project.currentProject.domain}`,
				Authorization: `Basic ${Buffer.from(
					`${config.authentication ? `${config.authentication.username}:${config.authentication.password}` : ''}`,
				).toString('base64')}`,
				'Accept-Language': acceptLang
					? acceptLang
					: `${store.getState().project.currentProject.language ? store.getState().project.currentProject.language.split('-')[0] : 'en'}`,
			},
		});
	};

	static getStandingTypes = (sport: string, scope: string, lang: string, standingSubCategory?: string) => {
		return StandingHttpService.instance(sport).get(
			`/${sport}/v2/rankings/available?scope=${scope}${lang ? `&translation_language=${lang}` : ''}${
				standingSubCategory ? `&standing_type_subcategory=${standingSubCategory}` : ''
			}`,
		);
	};

	static getRankingPlayers = (sport: string, rankingId: string, lang: string, limit?: string, offset?: string) => {
		return StandingHttpService.instance(sport).get(
			`/${sport}/v2/rankings/${rankingId}${lang ? `?translation_language=${lang}` : ''}&limit=${limit ? limit : '200'}&offset=${
				offset ? offset : '0'
			}`,
		);
	};

	static getAvailableSeasons = (sport: string, competitionId?: string, lang?: string) => {
		const competitionQuery = competitionId && competitionId.length > 0 ? `&competition_id=${competitionId}` : '';
		return StandingHttpService.instance(sport).get(
			`/${sport}/v2/seasons/available?limit=200&offset=0${competitionQuery}&${lang ? `translation_language=${lang}` : ''}`,
		);
	};
}
