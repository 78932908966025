import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import i18n from 'i18next';
import HttpService from '../../../../../services/rest/HttpService';
import ModelMapper from '../../../../../models/ModelMapper';
import { featuresService } from '../../../../../App';
import { FeatureTypes } from '../../../../../services/feature-service/features.enum';
import {
	articleOrigins,
	appendCorrectArticleFilter,
	isAiArticleSection,
	shouldRenderArticlesRows,
} from '../../../../Resources/Articles/Helpers/ArticleHelper';
import { extractSearchQueryFromFilters } from '../../../../Partials/Shared/advanced-content-filter/helpers/advanced-content-filter.helper';
import ConditionalRenderWrapper from '../../../../Partials/BaseComponents/ConditionalRenderWraper/ConditionalRenderWrapper';
import { ContentTypes } from '../../../../../constants/content-types';
import ListingComponent from '../../../../Partials/BaseComponents/ListingComponent/ListingComponent';
import { CopyToProjectsModal } from '../../../../Partials/Modals/CopyToProjects/CopyToProjectsModal';
import Project from '../../../../../models/project/Project';
import PaginationMeta from '../../../../../models/pagination/PaginationMeta';
import { ARTICLE_ENTITY_DELETE_SUCCESS, returnObjectForArticleDelete } from '../../../../../store/action-creators/ArticleActionCreator';
import { AppState } from '../../../../../store/store';
import { toggleLoadingState } from '../../../../../store/action-creators/UiActionCreator';
import { AI_ARTICLES_LISTING_RECEIVED } from '../../../../../store/action-creators/ai-articles-action-creator';
import Article from '../../../../../models/article/Article';

const AiArticlesListing: React.FC<RouteComponentProps> = ({ history, location }) => {
	const dispatch = useDispatch();
	const currentProject: Project = useSelector((state: AppState) => state.project.currentProject);
	const articlesPagination: PaginationMeta = useSelector((state: AppState) => state.aiArticles.aiArticlesPagination);
	const filters = useSelector((state: AppState) => state.aiArticles.aiArticlesListingFilters);
	const [articles, setArticles] = useState<Article[]>([]);
	const [currentPage, setCurrentPage] = useState<string>(
		articlesPagination && articlesPagination.currentPage ? articlesPagination.currentPage.toString() : '1',
	);

	const getArticlesListener = useCallback(() => {
		applyFilters(filters, parseInt(currentPage));
	}, [filters, currentPage]);

	useEffect(() => {
		document.title = i18n.t('ai_articles');

		if (!featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.ARTICLE_AI_GENERATION)) {
			history.push('/dashboard');
		}

		applyFilters(filters, parseInt(currentPage));
		window.addEventListener(ARTICLE_ENTITY_DELETE_SUCCESS, getArticlesListener);

		return () => {
			window.removeEventListener(ARTICLE_ENTITY_DELETE_SUCCESS, getArticlesListener);
		};
	}, []);

	const applyFilters = (filters: any, page: number) => {
		if (!currentProject) return;
		const resultFilters = appendCorrectArticleFilter(filters || {}, articleOrigins.AI_ARTICLE);
		const query = extractSearchQueryFromFilters(resultFilters);

		fetchArticles(page, query);
	};

	const fetchArticles = async (page: number, searchQuery: string = '') => {
		if (!currentProject) return;
		toggleLoadingState(true);

		let headers = { Project: currentProject.domain };
		const constructURL = searchQuery.length > 0 ? `/search?query=${searchQuery}&` : '?';

		await HttpService.get(`/v2/articles${constructURL}page=${page}`, null, headers)
			.then((response: any) => {
				if (response && response.data) {
					const mappedArticles = ModelMapper.remapArticlesFromResponse(response.data.data);
					setArticles(mappedArticles);

					dispatch({
						type: AI_ARTICLES_LISTING_RECEIVED,
						payload: {
							contentPagination: ModelMapper.remapMetaPagination(response.data.meta.pagination),
						},
					});

					setCurrentPage(page.toString());
					toggleLoadingState(false);
				}
			})
			.catch((error: Error) => {
				console.error('Error fetching articles:', error);
			});
	};

	const searchArticles = async (searchQuery: string) => {
		if (!currentProject) return;

		toggleLoadingState(true);

		let headers = { Project: currentProject.domain };

		await HttpService.get(`/v2/articles/search?query=${searchQuery}`, null, headers)
			.then((response: any) => {
				if (response && response.data) {
					const mappedArticles = ModelMapper.remapArticlesFromResponse(response.data.data);
					setArticles(mappedArticles);

					dispatch({
						type: AI_ARTICLES_LISTING_RECEIVED,
						payload: {
							contentPagination: ModelMapper.remapMetaPagination(response.data.meta.pagination),
						},
					});

					setCurrentPage(response.data.meta.pagination.currentPage);
					toggleLoadingState(false);
				}
			})
			.catch((error: Error) => {
				console.error('Error searching articles:', error);
			});
	};

	const onDeleteContent = (id: string) => {
		dispatch(returnObjectForArticleDelete(id, currentProject));
	};

	const handlePageChange = (page: string) => {
		setCurrentPage(page);
	};

	return (
		<ConditionalRenderWrapper expectedPermissions={['read_articles', 'write_articles', 'delete_articles']}>
			<ListingComponent
				contentType={ContentTypes.ARTICLE}
				getContent={(page: number) => applyFilters(filters, page)}
				searchContent={searchArticles}
				onPageChange={handlePageChange}
				history={history}
				location={location}
				currentProject={currentProject}
				onDeleteContent={onDeleteContent}
				content={shouldRenderArticlesRows(ContentTypes.ARTICLE, articleOrigins.AI_ARTICLE, isAiArticleSection) ? articles : []}
				contentPagination={articlesPagination}
				showSearchBar={true}
			/>
			<CopyToProjectsModal />
		</ConditionalRenderWrapper>
	);
};

export default withRouter(AiArticlesListing);
