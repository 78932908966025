import React, { FunctionComponent } from 'react';
import { emptyFunction } from '../../../../Partials/design-components/buttons/rounded';

export type ButtonProps = {
	text: string;
	title?: string;
	maxWidth?: string;
	onClickAction?: (event: any) => void;
	disabled?: boolean;
};

export const ScheduleGenerationButton: FunctionComponent<ButtonProps> = ({ text, title, maxWidth, onClickAction, disabled }) => {
	return (
		<div className='schedule-button-container'>
			<div
				className='schedule-button'
				title={title}
				style={{ maxWidth: maxWidth || 'auto' }}
				onClick={disabled ? emptyFunction : onClickAction}
				aria-disabled={!!disabled}
			>
				<span className='schedule-button-label'>{text}</span>
			</div>
		</div>
	);
};

export default ScheduleGenerationButton;
