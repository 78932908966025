import * as React from 'react';

type Properties = {
	t: any;
	display: boolean;
	analytics: any;
	onDelete: (id: string, title: string) => any;
	contentId: string;
	contentTitle: string;
	dropdownItem?: boolean;
	contentType?: string;
	content?: any;
};

export const RowDeleteButton: React.FunctionComponent<Properties> = ({
	contentType,
	display,
	analytics,
	onDelete,
	contentId,
	contentTitle,
	dropdownItem,
	content,
	t,
}) => {
	const buttonStyle = 'm-1 btn btn-sm btn-danger d-flex flex-fill justify-content-center cursor-pointer';

	const onDeleteContent = () => {
		analytics && analytics.sendEvent('Click', 'List Page', 'Delete - Open Modal');
		onDelete(contentId, contentTitle);
	};

	if (display) {
		return (
			<div id={`content-delete-btn-${contentId}`} className={buttonStyle} onClick={onDeleteContent}>
				<i className={`${dropdownItem ? 'mr-1' : ''} fa fa-trash p-1 text-white`} />
				{dropdownItem && (
					<span>
						{t('delete')} {t(`${contentType}`)}
					</span>
				)}
			</div>
		);
	}

	return null;
};
