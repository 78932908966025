import * as React from 'react';
import AsyncSelect from 'react-select/async';
import HttpService from '../../../../../../services/rest/HttpService';
import _ from 'lodash';
import { connect } from 'react-redux';
import { customOption } from '../../../../Sidebar/tags/subcomponents/football-connections/option-component';
import {
	convertResultToOption,
	optionToRelatedContent,
} from '../../../../Sidebar/tags/subcomponents/football-connections/helpers/football-conections-container.helper';
import { Properties, State } from './properties/tournament-select.properties';
import { tournamentRelatedToOption } from './helper/tournament-select.helper';

class TournamentSelect extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			newValue: '',
			selectedOption: {},
		};
	}

	private onInputChange = (input: string) => {
		this.setState({ newValue: input });

		return input;
	};

	private onSelect = (selections: any) => {
		// This is done because of https://github.com/JedWatson/react-select/issues/3632
		selections = selections === null ? [] : selections;
		const relatedData = optionToRelatedContent(selections);
		this.props.onSelect(relatedData);
	};

	private loadFootballData = (input: string, callback: any) => {
		let langCode = this.props.project.language.substring(0, 2);

		if (input.length > 3) {
			HttpService.getFootballData(input, langCode, 'tournament').then((response: any) => {
				let options = response.data.map((result: any) => {
					return convertResultToOption(result);
				});
				callback([...options]);
			});
		}
	};

	componentDidMount(): void {
		this.setState({
			...this.state,
			selectedOption: tournamentRelatedToOption(this.props.related),
		});
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>): void {
		if (prevProps.related !== this.props.related) {
			this.setState({
				...this.state,
				selectedOption: tournamentRelatedToOption(this.props.related),
			});
		}
	}

	render() {
		const { selectedOption } = this.state;

		return (
			<AsyncSelect
				noOptionsMessage={(inputValue) => inputValue && this.props.t('no_options')}
				value={selectedOption && Object.entries(selectedOption).length > 0 ? selectedOption : null}
				loadOptions={_.debounce(this.loadFootballData, 500)}
				onInputChange={_.debounce(this.onInputChange, 500)}
				onChange={this.onSelect}
				formatOptionLabel={customOption}
				placeholder={this.props.t('select')}
				isClearable={true}
			/>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		language: state.project.currentProject.language,
		project: state.project.currentProject,
	};
}

export default connect(mapStateToProps)(TournamentSelect);
