import { relatedConstants } from '../../../../../constants/related.constants';
import Related from '../../../../../models/related/Related';
import SportsEntityMapper from '../../../../../models/v2/sports-types/sports-entity.mapper';
import SportsEntityModel from '../../../../../models/v2/sports-types/sports-entity.model';
import { extractProviderBySportType } from './utils';
import SportsTypesModel from '../../../../../models/v2/sports-types/sports-types.model';
import EventModel from '../../../../../models/v2/sports-types/eventModel';

const sportsEntityMapper = new SportsEntityMapper();

export const remapSportsConnectionsResponseToConnectionsModel = (connectionsResponse: any[] | null): SportsEntityModel[] | null => {
	if (!connectionsResponse) {
		return null;
	} else if (connectionsResponse.length === 0) {
		return [];
	} else {
		return connectionsResponse.map((entity) => sportsEntityMapper.responseToEntityModel(entity));
	}
};

export function sportConnectionsToOptions(sportConnections: SportsEntityModel[] | null) {
	if (sportConnections && sportConnections.length > 0) {
		return sportConnections.map((sportConnection: SportsEntityModel) => {
			return { value: sportConnection.id, label: sportConnection.name, data: sportConnection };
		});
	}

	return null;
}

export function sportEventToOptions(events: EventModel[] | null) {
	if (events && events.length > 0) {
		return events.map((event: EventModel) => {
			return { value: event.id, label: event.name, data: event };
		});
	}

	return null;
}

// Redux store the data in the following format:
// { type: 'tag', provider: 'smp', data: SportsEntityModel }
export const remapReduxSportsConnectionPropertyToModels = (reduxData: Related[] | null): SportsEntityModel[] | null => {
	if (reduxData && reduxData.length > 0) {
		const reduxInfoData = reduxData.map((entity) => entity.data);
		return remapSportsConnectionsResponseToConnectionsModel(reduxInfoData);
	}
	return null;
};

export const remapSportConnectionAsRelatedModel = (
	sportsConnection: SportsEntityModel | null,
	selectedSport: SportsTypesModel,
): Related | null => {
	if (sportsConnection) {
		return {
			provider: extractProviderBySportType(selectedSport),
			type: sportsConnection.entity_type,
			data: sportsConnection,
		} as Related;
	}

	return null;
};

export const connectionsTypesForRequest = [
	relatedConstants.types.player,
	relatedConstants.types.team,
	relatedConstants.types.coach,
	relatedConstants.types.competition,
	relatedConstants.types.arena,
];
