import React, { Component } from 'react';
import DangerouslySetInner from '../../../social-feed-selection-sidebar/subcomponents/dangerously-set-inner.component';
import { store } from '../../../../../store/store';
import REACT_APP_URLS from '../../../../../global-helpers/global-url.helpers';

export default class WidgetLoader extends Component {
	getLoaderConfig = (currentProject) => {
		return (
			'<script>\n' +
			'    new SMPWidgetsLoader({\n' +
			`        CDN_URL: \'${REACT_APP_URLS.REACT_APP_WIDGETS_CDN_URL}\',\n` +
			"        APP_LINK: 'desktop',\n" +
			'        LINK_OPTIONS: {\n' +
			"            player: 'https://temp-player.com/player-#id#',\n" +
			"            team: 'https://temp-team.com/team-#id#',\n" +
			"            placeholder: '#id#',\n" +
			'            openInNewTab: true\n' +
			'        },\n' +
			`        ODD_CLIENT: \'${currentProject.defaultOddClient}\',\n` +
			`        API_URL: \'${currentProject.footballApiUrl}\',\n` +
			"        API_KEY: '2f852b4de2f3a5cf3188e74b33087bb4:2c18422f8e826a7d11aeed52edb2449e',\n" +
			`        LANG: \'${this.props.lang || (currentProject && currentProject.language && currentProject.language.split('-')[0])}\',\n` +
			'        REQUEST_INTERVAL: 30000,\n' +
			'        ENABLE_BET_SLIP: true\n' +
			'    });\n' +
			'</script>'
		);
	};

	constructor(props) {
		super(props);
	}

	render() {
		const { currentProject } = store.getState().project;
		return <DangerouslySetInner html={this.getLoaderConfig(currentProject)} />;
	}
}
