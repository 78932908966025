import moment from 'moment';
import { TypedJSON } from 'typedjson';
import Project from '../../../../../../models/project/Project';
import GameModel from '../../../../../../models/v2/game/game.model';

export function convertTeamIdsToGameSearchUrls(teamIds: string[], currentProject: Project, sport: string) {
	const langCode = currentProject.language.split('-')[0];

	let urls = [];

	if (teamIds && teamIds.length > 0) {
		urls.push(constructSportsRequestUrl(teamIds, 'gamesNotStarted', sport, langCode));
		urls.push(constructSportsRequestUrl(teamIds, 'gamesFinished', sport, langCode));
		urls.push(constructSportsRequestUrl(teamIds, 'gamesInterrupted', sport, langCode));
	}

	return urls;
}

export function markGameModelAsSelected(games: GameModel[], gameId: string, gameIds: string[], isMulti: boolean) {
	if (games && !isMulti) {
		games = games.map((game: GameModel) => {
			game.isSelected = game.id === gameId;

			return game;
		});
	}

	if (games && isMulti) {
		games = games.map((game: GameModel) => {
			const index = gameIds.findIndex((id) => game.id === id);
			game.isSelected = index > -1;

			return game;
		});
	}

	return games;
}

export const responseToGameModel = (response: any) => {
	const serializer = new TypedJSON(GameModel);
	const remmapedGame = serializer.parse(response) as GameModel;
	remmapedGame['isSelected'] = false;

	return remmapedGame;
};

export const filterUpcomingGamesWithWrongDates = (games: any) => {
	return games.filter((game: any) => Object.entries(game).length > 0 && moment(game.startTime).format() >= moment().format());
};

// In some cases, the game has had postponed and, its date had passed, but it results in upcoming games response.
// We filter games with past date from upcoming and in-progress games response.
export const removePastGames = (games: GameModel[]) => {
	return games.filter((game: any) => moment().diff(game.startTime, 'days') < 1 && game.code !== 'postponed');
};

export const requestStatuses = {
	gamesNotStarted: 'status_type=NOT_STARTED,LIVE&sort_direction=ASC',
	gamesFinished: 'status_type=FINISHED',
	gamesInterrupted: 'status_type=INTERRUPTED',
};

export const constructSportsRequestUrl = (teamIds: string[], activeTab: string, sport: string, translationLanguage: string) => {
	if (teamIds && teamIds.length > 0) {
		return `/events?participants_filter=${teamIds.join(',')}&sport=${sport}&${
			requestStatuses[activeTab]
		}&translation_language=${translationLanguage}`;
	}

	return '';
};
