import StylesConfig from 'react-select';
import { SingleValueProps } from 'react-select/src/components/SingleValue';
import SportsEntityModel from '../../../../models/v2/sports-types/sports-entity.model';
import { ISuggestedEntity } from '../../../Partials/Sidebar/tags-refactored/helpers/suggested-entities-v2.helper';
import { formatImageAsDisplayAsset, getImageAsset } from '../../../../global-helpers/sidebar.helpers';
import { DisplayAsset } from '../../../../models/v2/competition/entity/competition.model';
import React from 'react';
import i18next from 'i18next';
import moment from 'moment';
import EventModel from '../../../../models/v2/sports-types/eventModel';
import { getSportIcon } from '../../../../global-helpers/global-icons.helpers';
import { SportTypes } from '../../../../constants/sport.types';

export type SelectMenuOptionType = {
	value: string;
	label: string;
	logo?: string;
	data: any;
};

export const getToggleIcon = (selectAll: boolean): string => {
	switch (selectAll) {
		case true:
			return require('../../../../assets/icons/general/toggle-on.svg');
		case false:
			return require('../../../../assets/icons/general/toggle-off.svg');
		default:
			return '';
	}
};

export const GENERATE_TYPES_OPTIONS = (t: any) => [
	{ value: true, label: t('yes') },
	{ value: false, label: t('no') },
];

export const GENERATE_BASED_ON_OPTIONS = (t: any) => [
	{ value: GenerateArticleBasedOn.EVENT, label: t('event_type') },
	{ value: GenerateArticleBasedOn.DATE, label: t('date') },
];

export enum ArticleAiGenerationTabs {
	GENERATE_ARTICLE = 'generate_article',
	SCHEDULED = 'scheduled',
	CREATED = 'created_articles',
}

export enum GenerateArticleBasedOn {
	EVENT = 'Event',
	DATE = 'Date',
}

export enum GenerateArticleRequestStatuses {
	WARN = 'warn',
	SUCCESS = 'success',
	ERROR = 'error',
}

export type GenerationErrorState = {
	category: boolean;
	matches: boolean;
	generate_based_on: boolean;
	competition_events: boolean;
};

interface IOptionType {
	value: string;
	label: string;
}

export const constructEventRequestUrl = (teamId: string, sport: string, limit: number, lang: string) => {
	if (teamId && teamId.length > 0) {
		return `/events?participants_filter=${teamId}&sport=${sport}&translation_language=${lang}&status_type=NOT_STARTED,LIVE&sort_direction=ASC&limit=${
			limit > 200 ? 200 : limit
		}`;
	}

	return '';
};

export const formatDataForPostRequest = (data: Record<string, any>) => {
	// Clone data to avoid mutations
	// @ts-ignore
	const formattedData = structuredClone(data);
	const fieldWithMatches = ['matches'];

	// Set individual properties
	formattedData.category_id = data.category && data.category.id ? data.category.id : null;
	formattedData.category_name = data.category && data.category.title ? data.category.title : null;
	formattedData.generate_strapline = !!data.generate_strapline;
	formattedData.generate_summary = !!data.generate_summary;
	formattedData.time_zone = data.time_zone;
	formattedData.template_name = data.template_name;
	formattedData.template_type = data.template_type;
	formattedData.sport = data.sport;

	formattedData.matches = [];

	// Process matches from the original matches array
	fieldWithMatches.forEach((fieldName) => {
		if (data[fieldName] && Array.isArray(data[fieldName]) && data[fieldName].length > 0) {
			const transformedMatches = data[fieldName].map((match: any) => ({
				match_id: match.id,
				competition_id: match.competition && match.competition.id ? match.competition.id : null,
				competition_name: match.competition && match.competition.name ? match.competition.name : null,
				match_name: match.name,
				match_date: match.startTime,
			}));

			formattedData.matches.push(...transformedMatches);
		}
	});

	// Process matches from competition_events and merge into matches array
	if (data.competition_events && Array.isArray(data.competition_events)) {
		const competitionMatches = data.competition_events.flatMap((dateGroup) =>
			dateGroup.competitions.flatMap((competition: Record<string, any>) =>
				competition.events.map((event: any) => ({
					match_id: event.id,
					competition_id: competition.competition_id || null,
					competition_name: competition.competition_name || null,
					match_name: event.name,
					match_date: event.start_time,
				})),
			),
		);

		formattedData.matches.push(...competitionMatches);
	}

	// Remove unnecessary fields
	delete formattedData.team;
	delete formattedData.generate_based_on;
	delete formattedData.generation_date;
	delete formattedData.competition_ids;
	delete formattedData.competition_events;

	// Remove null/undefined/empty values
	Object.keys(formattedData).forEach((key) => {
		if (formattedData[key] === null || formattedData[key] === undefined || formattedData[key] === '') {
			delete formattedData[key];
		}
	});

	return formattedData;
};

export const getArticleGenerationInfoLabel = (matches: Array<{ startTime: string }>, timezone: string): string => {
	if (!matches || matches.length === 0) return '';

	const now = moment().tz(timezone);
	const isFutureMatch = matches.some((match) => moment(match.startTime).tz(timezone).isAfter(now, 'day'));

	if (isFutureMatch) {
		return 'article_generation_future';
	}

	return 'article_generation_today';
};

export const getArticleGenerationInfoLabelBasedOnDate = (
	schedule: Array<{ date: string; competitions: Array<{ events: Array<{ start_time: string }> }> }>,
	timezone: string,
): string[] => {
	if (!schedule || schedule.length === 0) return [];

	const now = moment().tz(timezone);
	let hasTodayEvents = false;
	let hasFutureEvents = false;

	schedule &&
		schedule.forEach(({ date, competitions }) => {
			competitions.forEach(({ events }) => {
				events.forEach(({ start_time }) => {
					const eventDate = moment(start_time).tz(timezone);
					if (eventDate.isSame(now, 'day')) {
						hasTodayEvents = true;
					} else if (eventDate.isAfter(now, 'day')) {
						hasFutureEvents = true;
					}
				});
			});
		});

	const messages: string[] = [];
	if (hasTodayEvents) messages.push('article_generation_today');
	if (hasFutureEvents) messages.push('articles_generation_future');

	return messages;
};

export const getLocalEquivalentOfMidnightInProjectTimezone = (timezone: string): Date => {
	const m = moment().tz(timezone).startOf('day');

	const year = m.year();
	const month = m.month();
	const day = m.date();

	return new Date(year, month, day, 0, 0, 0, 0);
};

// Styles and formatting for the custom select options
const generateSportOptionInfoDataByEntityType = (entity: Record<string, any>): string[] => {
	if (!entity) return [];
	return [
		i18next.t(`${entity.sport}`),
		(entity.country && entity.country.name) || '',
		i18next.t(`${entity.gender ? entity.gender.toLowerCase() : ''}`),
	];
};

const generateSportEventOptionInfoData = (entity: Record<string, any>): string[] => {
	if (!entity) return [];
	return [i18next.t(`${entity.competition && entity.competition.name}`), `${moment(entity.startTime).format('DD.MM.YYYY, HH:mm')}` || ''];
};

const generateSelectedSportEventOptionInfoData = (entity: Record<string, any>): string[] => {
	if (!entity) return [];
	return [i18next.t(`${entity.competition && entity.competition.name}`), `${moment(entity.start_time).format('DD.MM.YYYY, HH:mm')}` || ''];
};

export const SportTeamCustomOption = ({
	data,
	innerProps,
}: {
	data: {
		value: string;
		label: string;
		data: SportsEntityModel;
	} | null;
	innerProps: Record<string, unknown>;
}) => {
	if (!data) return null;

	const entityData = data.data;
	const { name, entity_type, id } = entityData;
	const display_asset = formatImageAsDisplayAsset((entityData as Record<string, any>) as ISuggestedEntity);
	const additionalData = generateSportOptionInfoDataByEntityType(entityData);
	return generateCustomHtmlOption(innerProps, entity_type, id, display_asset, name, additionalData);
};

export const SportEventCustomOption = ({
	data,
	innerProps,
}: {
	data: {
		value: string;
		label: string;
		data: EventModel;
	} | null;
	innerProps: Record<string, unknown>;
}) => {
	if (!data) return null;

	const entityData = data.data;
	const { name, id } = entityData;
	const additionalData = generateSportEventOptionInfoData(entityData);
	return generateEventCustomHtmlOption(innerProps, id, name, additionalData);
};

export const SportSelectedEventCustomOption = ({
	data,
}: {
	data: {
		value: string;
		label: string;
		data: EventModel;
	} | null;
}) => {
	if (!data) return null;

	const entityData = data.data;
	const { name, id } = entityData;

	const additionalData = generateSelectedSportEventOptionInfoData(entityData);
	return generateSelectedEventCustomHtmlOption(id, name, additionalData);
};

export const generateCustomHtmlOption = (
	innerProps: Record<string, unknown>,
	entity_type: string,
	value: string,
	display_asset: DisplayAsset,
	name: string,
	additionalData: string[],
) => {
	const image = getImageAsset(display_asset, entity_type);

	const [sport, ...restAdditionalData] = additionalData;

	return (
		<div {...innerProps} key={value}>
			<div id={`connections-drop-down-option-${value}`} className='option-container-dropdown option-container option-border overflow-hidden'>
				<img
					className={`option-logo-${entity_type}`}
					width={23}
					height={23}
					src={image}
					alt={`option-logo-${entity_type}`}
					onError={(i: React.SyntheticEvent<HTMLImageElement, Event>) => ((i.target as HTMLImageElement).src = image)}
				/>
				<div>
					<div className='option-label'>{name}</div>
					<div className='option-label-additional'>
						<span
							style={{
								backgroundImage: `url("${getSportIcon(sport.toLowerCase() as SportTypes)}")`,
							}}
							className='sport-icon'
						/>
						{sport && <span>{sport}</span>}
						{restAdditionalData.length > 0 && <span> / {restAdditionalData.join(' / ')}</span>}
					</div>
				</div>
			</div>
		</div>
	);
};

export const generateEventCustomHtmlOption = (
	innerProps: Record<string, unknown>,
	value: string,
	name: string,
	additionalData: string[],
) => {
	return (
		<div {...innerProps} key={value}>
			<div id={`connections-drop-down-option-${value}`} className='option-container-dropdown option-container option-border'>
				<div className='event-custom-option-label'>
					<div className='option-label'>{name}</div>
					{additionalData && (
						<span className='option-label-additional'>
							{additionalData && <span className='option-label-additional'> {additionalData.filter((el) => el).join(' - ')} </span>}
						</span>
					)}
				</div>
			</div>
		</div>
	);
};

export const generateSelectedEventCustomHtmlOption = (value: string, name: string, additionalData: string[]) => {
	return (
		<div id={`connections-drop-down-option-${value}`} className='option-container option-border'>
			<div className='event-custom-option-label'>
				<div className='option-label'>{name}</div>
				{additionalData && (
					<span className='option-label-additional'>
						{additionalData && <span className='option-label-additional'> {additionalData.filter((el) => el).join(' - ')} </span>}
					</span>
				)}
			</div>
		</div>
	);
};

export const articleAiGenerationSelectStylesVariant = (errorMessage: boolean, widthSize: string, isDisabled?: boolean) => ({
	container: (provided: StylesConfig) => ({
		...provided,
		display: 'inline-flex',
		width: '100%',
		minWidth: widthSize,
		maxWidth: '100%',
	}),
	control: (provided: StylesConfig, state: SingleValueProps<IOptionType>) => ({
		...provided,
		backgroundColor: isDisabled ? '#F5F5F5' : '#FFFFFF',
		border: errorMessage ? '1px solid #E25160' : state.selectProps.menuIsOpen ? '1px solid #1058E1' : '1px solid #D9D9D9',
		borderRadius: '6px',
		width: '100%',
		minWidth: widthSize,
		maxWidth: '100%',
		justifyContent: 'space-between',
		display: 'inline-flex',
		boxShadow: errorMessage ? '0 0 0 1px #E25160' : state.selectProps.menuIsOpen ? '0 0 0 1px #1058E1' : 'none',
	}),
	indicatorSeparator: (provided: StylesConfig) => ({
		...provided,
		display: 'none',
		color: '#666666',
	}),
	dropdownIndicator: (provided: StylesConfig, state: SingleValueProps<IOptionType>) => ({
		...provided,
		color: errorMessage ? '#E25160' : state.selectProps.menuIsOpen ? '#1058E1' : '#666666',
		transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
		transition: 'transform 0.2s ease-out',
	}),
	clearIndicator: (provided: StylesConfig) => ({
		...provided,
		color: '#666666',
	}),
	multiValue: (provided: StylesConfig) => ({
		...provided,
		borderRadius: '6px',
		padding: '2px 4px 2px 4px',
	}),
	multiValueLabel: (provided: StylesConfig) => ({
		...provided,
		fontFamily: 'Open Sans',
		fontWeight: '400',
		fontSize: '14px',
		lineHeight: '20px',
	}),
	multiValueRemove: (provided: StylesConfig) => ({
		...provided,
	}),
	singleValue: (provided: StylesConfig) => ({
		...provided,
		fontFamily: 'Open Sans',
		fontWeight: '400',
		fontSize: '14px',
		lineHeight: '20px',
	}),
	menuList: (provided: StylesConfig) => ({
		...provided,
		fontFamily: 'Open Sans',
		fontWeight: '400',
		fontSize: '14px',
		lineHeight: '20px',
	}),
	placeholder: (provided: StylesConfig) => ({
		...provided,
		color: '#BFBFBF',
		fontFamily: 'Open Sans',
		fontWeight: '400',
		fontSize: '14px',
		lineHeight: '20px',
	}),
});

export const articleAiGenerationCompetitionFilterSelectStyles = (errorMessage: boolean, isDisabled?: boolean) => ({
	container: (provided: StylesConfig) => ({
		...provided,
		display: 'inline-flex',
		width: '100%',
	}),
	control: (provided: StylesConfig, state: SingleValueProps<IOptionType>) => ({
		...provided,
		backgroundColor: isDisabled ? '#F5F5F5' : '#FFFFFF',
		border: errorMessage ? '1px solid #E25160' : state.selectProps.menuIsOpen ? '1px solid #1058E1' : '1px solid #D9D9D9',
		borderRadius: '6px',
		width: '100%',
		justifyContent: 'space-between',
		display: 'inline-flex',
		boxShadow: errorMessage ? '0 0 0 1px #E25160' : state.selectProps.menuIsOpen ? '0 0 0 1px #1058E1' : 'none',
	}),
	indicatorSeparator: (provided: StylesConfig) => ({
		...provided,
		display: 'none',
		color: '#666666',
	}),
	dropdownIndicator: (provided: StylesConfig, state: SingleValueProps<IOptionType>) => ({
		...provided,
		color: errorMessage ? '#E25160' : state.selectProps.menuIsOpen ? '#1058E1' : '#666666',
		transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
		transition: 'transform 0.2s ease-out',
	}),
	placeholder: (provided: StylesConfig) => ({
		...provided,
		color: '#BFBFBF',
		fontFamily: 'Open Sans',
		fontWeight: '400',
		fontSize: '14px',
		lineHeight: '20px',
	}),
});
