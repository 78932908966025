import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Collapse, Label } from 'reactstrap';
import { CompetitionSection } from './competition-section';
import { Competition } from '../../../types/livescore';

interface Props {
	label: string;
	flag: string;
	competitions: Competition[];
	selectAllCompetitions: boolean;
	setSelectAllCompetitions: (value: boolean) => void;
	selectedCompetitionIds: string[];
	setSelectedCompetitionIds: React.Dispatch<React.SetStateAction<string[]>>;
}

export const CompetitionListSection: FC<Props> = ({
	label,
	flag,
	competitions,
	selectAllCompetitions,
	setSelectAllCompetitions,
	selectedCompetitionIds,
	setSelectedCompetitionIds,
}) => {
	const isCompetitionSectionsSelected = competitions.every(({ value }) => selectedCompetitionIds.includes(value));
	const allCompetitionSectionIds = competitions.map(({ value }) => value);

	const [isOpen, setIsOpen] = useState(true);
	const [selectAllSections, setSelectAllSections] = useState(selectAllCompetitions);

	const toggleCollapseSection = () => setIsOpen(!isOpen);

	useEffect(() => {
		if (!selectAllCompetitions && isCompetitionSectionsSelected) {
			return;
		}
		setSelectAllSections(selectAllCompetitions);
	}, [selectAllCompetitions]);

	useEffect(() => {
		if (isCompetitionSectionsSelected) {
			setSelectAllSections(true);
		}
	}, [isCompetitionSectionsSelected]);

	return (
		<>
			<div id={`${label}-competitions-section-wrapper`} className='competition-list-section'>
				<div className='competition-list-section-wrapper'>
					<div id={`${label}-competitions-section-container`} className='competition-section-container'>
						<div className='competition-section-checkbox-wrapper'>
							<div
								id={`${label}-competitions-section-input`}
								className='competition-section-checkbox-container'
								style={{
									background: selectAllSections ? '#71A1FC' : '#FFF',
									border: selectAllSections ? '1px solid #71A1FC' : '1px solid #D9D9D9',
								}}
								onClick={() => {
									const value = !selectAllSections;

									if (value) {
										setSelectAllSections(true);
										setSelectedCompetitionIds((prevIds) => [...prevIds, ...allCompetitionSectionIds.filter((id) => !prevIds.includes(id))]);
									} else {
										setSelectAllSections(false);
										setSelectAllCompetitions(false);
										setSelectedCompetitionIds((prevIds) => prevIds.filter((id) => !allCompetitionSectionIds.includes(id)));
									}
								}}
							>
								{selectAllSections && <span className='check-icon' />}
							</div>
						</div>
						<Label check htmlFor={`${label}-competitions-section-input`} className='competition-list-section-label'>
							<div className='competition-list-section-label'>
								{flag ? (
									<img id={`${label}-competitions-section-flag`} src={flag} width={22} height={16} />
								) : (
									<i id={`${label}-competitions-section-flag`} className='fa-regular fa-flag' />
								)}
								<span id={`${label}-competitions-section-name`}>{label}</span>
							</div>
						</Label>
					</div>
				</div>
				{isOpen ? (
					<i id={`${label}-competitions-section-wrapper-collapseOn-icon`} className='fa-solid fa-chevron-up' onClick={toggleCollapseSection} />
				) : (
					<i
						id={`${label}-competitions-section-wrapper-collapseOff-icon`}
						className='fa-solid fa-chevron-down'
						onClick={toggleCollapseSection}
					/>
				)}
			</div>
			<Collapse isOpen={isOpen}>
				{competitions.map((comp) => {
					return (
						<CompetitionSection
							{...comp}
							selectAllSections={selectAllSections}
							setSelectAllSections={setSelectAllSections}
							selectedCompetitionIds={selectedCompetitionIds}
							setSelectedCompetitionIds={setSelectedCompetitionIds}
							setAllCompetitions={setSelectAllCompetitions}
						/>
					);
				})}
			</Collapse>
		</>
	);
};
