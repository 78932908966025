import { IScheduledContentGeneration, ISchedulesAdvancedSearchFiltersModel } from '../../views/Pages/ArticleAiGeneration/models/models';
import AdvancedFilterModel from '../../views/Partials/Shared/advanced-content-filter/models/advanced-content-filter.model';
import PaginationMeta from '../../models/pagination/PaginationMeta';

export const AI_SCHEDULES_ADVANCED_FILTERS_UPDATE = '[ACTION] AI_SCHEDULES_ADVANCED_FILTERS_UPDATE';
export const AI_ARTICLES_ADVANCED_FILTERS_UPDATE = '[ACTION] AI_ARTICLES_ADVANCED_FILTERS_UPDATE';
export const AI_ARTICLES_PAGINATION_UPDATE = '[ACTION] AI_ARTICLES_PAGINATION_UPDATE';
export const AI_ARTICLES_LISTING_RECEIVED = '[ACTION] AI_ARTICLES_LISTING_RECEIVED';
export const AI_SCHEDULES_COUNT_UPDATE = '[ACTION] AI_SCHEDULES_COUNT_UPDATE';
export const AI_SCHEDULED_GENERATION_CONTENT_UPDATE = '[ACTION] AI_SCHEDULED_GENERATION_CONTENT_UPDATE';

export function updateAiSchedulesAdvancedSearchFilters(scheduleListingFilters: ISchedulesAdvancedSearchFiltersModel) {
	return {
		type: AI_SCHEDULES_ADVANCED_FILTERS_UPDATE,
		payload: { scheduleListingFilters },
	};
}

export function updateAiArticlesAdvancedSearchFilters(aiArticlesListingFilters: AdvancedFilterModel) {
	return {
		type: AI_ARTICLES_ADVANCED_FILTERS_UPDATE,
		payload: { aiArticlesListingFilters },
	};
}

export function updateAiArticlesPagination(pagination: PaginationMeta) {
	return {
		type: AI_ARTICLES_PAGINATION_UPDATE,
		payload: { aiArticlesPagination: pagination },
	};
}

export function updateAiSchedulesCount(count: number) {
	return {
		type: AI_SCHEDULES_COUNT_UPDATE,
		payload: { count },
	};
}

export function updateScheduledGenerationContent(scheduledGenerationContent: IScheduledContentGeneration) {
	return {
		type: AI_SCHEDULED_GENERATION_CONTENT_UPDATE,
		payload: { scheduledGenerationContent: scheduledGenerationContent },
	};
}
