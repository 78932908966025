import { QueryFunction, useMutation, useQuery } from 'react-query';
import { store } from '../../store/store';
import CustomizationApiHttpService from '../../models/v2/custom-blocks/services/custom-blocks-http.service';
import HttpService from '../rest/HttpService';
import { queryClient } from '../../App';
import ArticleSchedulerHttpService from '../../views/Pages/ArticleAiGeneration/helpers/article-scheduler-http-service';

export enum ReactQueryStatuses {
	LOADING = 'loading',
	SUCCESS = 'success',
	IDLE = 'idle',
	ERROR = 'error',
}

const cacheTimeMs = 900000; // set default cache time to 15min

export const ReactQuerySettings = {
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			cacheTime: cacheTimeMs,
		},
	},
};

// Custom hook wrapper for react-query
export const useReactQuery = (uniqueKeys: string[], requestPromise: QueryFunction<any>, additional?: Record<string, unknown>) => {
	const appendUniqueUserData = store.getState().profile.profile.id || ''; // always append user id as unique react-query identificator
	const { data, status, refetch } = useQuery([...uniqueKeys, appendUniqueUserData], requestPromise, additional);
	return { data, status, refetch };
};

export const isReactQueryFetchedSuccessfully = (status: string) => {
	return status === ReactQueryStatuses.SUCCESS;
};

export const isReactQueryLoading = (status: string) => {
	return status === ReactQueryStatuses.LOADING;
};

export const isReactQueryError = (status: string) => {
	return status === ReactQueryStatuses.ERROR;
};

export const fetchAdminActivity = async () => {
	const response = await CustomizationApiHttpService.getAdminActivity();
	return response.data;
};

export const getAdminActivityData = async () => {
	try {
		return await queryClient.fetchQuery('adminActivity', fetchAdminActivity);
	} catch (error) {
		console.error('Error fetching admin activity:', error);
		throw error;
	}
};

export const fetchContentCount = async (origin: string, timeStamp: string) => {
	const project = store.getState().project.currentProject.domain;
	const headers = { Project: project };
	const response = await HttpService.instance(headers).get(`editorial-content/count?origin=${origin}&from=${timeStamp}`);
	return response.data;
};

export const fetchScheduledArticlesCount = async () => {
	const response = await ArticleSchedulerHttpService.getSchedulesCount();
	return response.data && response.data.count;
};

export const getScheduledArticlesCountData = async () => {
	try {
		return await queryClient.fetchQuery('aiArticles', fetchScheduledArticlesCount);
	} catch (error) {
		console.error('Error fetching scheduled articles count:', error);
		throw error;
	}
};

export const useReactQueryMutationWrapper = <T, V>(
	mutationFn: (variables: V) => Promise<T>,
	{
		onSuccess,
		onError,
		refetchQueries = [],
	}: {
		onSuccess?: (data: T) => void;
		onError?: (error: any) => void;
		refetchQueries?: string[];
	} = {},
) => {
	return useMutation(mutationFn, {
		onSuccess: (data) => {
			if (onSuccess) onSuccess(data);

			refetchQueries.forEach((query) => {
				queryClient.invalidateQueries(query);
			});
		},
		onError: (error) => {
			if (onError) {
				onError(error);
			}
		},
	});
};

export const useReactQueryHookWrapper = <T, V>(
	queryKey: (string | number)[],
	queryFn: (variables: V) => Promise<T>,
	variables: V,
	{
		onSuccess,
		onError,
		refetchQueries = [],
	}: {
		onSuccess?: (data: T) => void;
		onError?: (error: any) => void;
		refetchQueries?: string[];
	} = {},
) => {
	return useQuery(queryKey, () => queryFn(variables), {
		keepPreviousData: true,
		refetchOnWindowFocus: false,
		retry: 1,
		onSuccess: (data) => {
			if (onSuccess) onSuccess(data);
			refetchQueries.forEach((query) => {
				queryClient.invalidateQueries(query);
			});
		},
		onError: (error) => {
			if (onError) {
				onError(error);
			}
		},
	});
};
