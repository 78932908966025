import * as React from 'react';
import { connect } from 'react-redux';
import { updateTempArticle } from '../../../../../../../store/action-creators/ArticleTempActionCreator';
import { updateTempVideo } from '../../../../../../../store/action-creators/VideoTempActionCreator';
import { updateTempGallery } from '../../../../../../../store/action-creators/GalleryTempActionCreator';
import Article from '../../../../../../../models/article/Article';
import Gallery from '../../../../../../../models/gallery/Gallery';
import Video from '../../../../../../../models/video/Video';
import { remapContentToTempCreate, relatedPath, contentRemappers } from '../helpers/RowsContainerHelper';
import HttpService from '../../../../../../../services/rest/HttpService';
import Related from '../../../../../../../models/related/Related';
import Project from '../../../../../../../models/project/Project';
import { videoCopyRelatedReceived } from '../../../../../../../store/action-creators/VideoActionCreator';
import { galleryCopyRelatedReceived } from '../../../../../../../store/action-creators/GalleryActionCreator';
import { articleCopyRelatedReceived } from '../../../../../../../store/action-creators/ArticleActionCreator';
import moment from 'moment';
import SportsTypesModel from '../../../../../../../models/v2/sports-types/sports-types.model';
import { extractCustomEntitiesFromRelatedApi } from '../../../../../../../global-helpers/sidebar.helpers';
import { updateSidebarCustomEntitiesBulk } from '../../../../../../../store/action-creators/content-sidebar-tags';

type Properties = {
	display: boolean;
	t: any;
	analytics: any;
	content: any;
	articleContent: (content: Article) => void;
	videoContent: (content: Video) => void;
	galleryContent: (content: Gallery) => void;
	history: any;
	createContentPath: string;
	contentType: string;
	project: Project;
	sports?: SportsTypesModel[];
};

class RowCopyButton extends React.Component<Properties, any> {
	fetchRelated = async () => {
		const headers = { Project: this.props.project.domain };

		return HttpService.get(`/v2/${relatedPath[this.props.contentType]}/${this.props.content.id}/related`, null, headers);
	};

	fetchContent = async (id: string, type: string) => {
		if (type === 'article') {
			return HttpService.getArticle(id);
		}

		if (type === 'video') {
			return HttpService.getVideo(id);
		}

		if (type === 'gallery') {
			return HttpService.getGallery(id);
		}
	};

	onCopy = async () => {
		let { t, analytics, content, createContentPath, history, contentType, sports } = this.props;

		analytics && analytics.sendEvent('Click', 'List Page', 'new "Copy" Button');

		const contentResponse = await this.fetchContent(content.id, contentType);
		const dateNow = moment().utc().format('YYYY-MM-DDTHH:mm:ssZ');
		content = contentRemappers(contentResponse.data.data, contentType, dateNow);
		this.props[`${contentType}Content`](remapContentToTempCreate(content, contentType, t));

		const related = await this.fetchRelated();
		this.props[`${contentType}RelatedReceived`](related, sports);
		if (related && related.data && related.data.data && related.data.data.length > 0) {
			this.props.updateSidebarCustomEntitiesBulk(related.data.data);
		}

		history.push(createContentPath);
	};

	render() {
		const { display, t, content, contentType } = this.props;
		const buttonStyle = 'm-1 d-flex flex-fill justify-content-center btn btn-primary btn-sm';

		if (display) {
			return (
				<div id={`content-copy-btn-${content.id}`} className={buttonStyle} onClick={this.onCopy}>
					<i className='fa fa-copy p-1 mr-1 text-white' /> {t('copy')} {t(`${contentType}`)}
				</div>
			);
		}

		return null;
	}
}

function mapStateToProps(state: any) {
	return {
		project: state.project.currentProject,
		sports: state.sports.sportsTypes,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		articleRelatedReceived: (content: Related[], sports: any) => dispatch(articleCopyRelatedReceived(content, sports)),
		videoRelatedReceived: (content: Related[], sports: any) => dispatch(videoCopyRelatedReceived(content, sports)),
		galleryRelatedReceived: (content: Related[], sports: any) => dispatch(galleryCopyRelatedReceived(content, sports)),
		articleContent: (content: Article) => dispatch(updateTempArticle(content)),
		videoContent: (content: Video) => dispatch(updateTempVideo(content)),
		galleryContent: (content: Gallery) => dispatch(updateTempGallery(content)),
		updateSidebarCustomEntitiesBulk: (bulkEntities: Related[]) => dispatch(updateSidebarCustomEntitiesBulk(bulkEntities)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(RowCopyButton) as React.ComponentType<any>;
