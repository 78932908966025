import { useEffect, useState } from 'react';
import MultisportHttpService from '../../../../../../../services/rest/multisport-http-service';
import { SportTypes } from '../../../../../../../constants/sport.types';
import { CompetitionResponse, CountryCompetitions } from '../../../types/livescore';
import { AppState } from '../../../../../../../store/store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { sortCompetitionsByCountry } from '../sortCompetitionsByCountry';

export const useCompetitions = (sport: string) => {
	const [t] = useTranslation();
	const [competitions, setCompetitions] = useState<CountryCompetitions[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const language = useSelector((state: AppState) => state.project.currentProject.language);

	const getCompetitions = (sport: string, lang: string) => {
		MultisportHttpService.getCompetitionListing(sport, lang)
			.then((response) => {
				const filteredCompetitionsBySport = response.data.competitions
					.filter(({ competition }: { competition: CompetitionResponse }) => competition.sport === sport)
					.map(({ competition }: { competition: CompetitionResponse }) => competition);

				const data = sortCompetitionsByCountry(filteredCompetitionsBySport, t);
				data && setCompetitions(data);
				setIsLoading(false);
			})
			.catch((e: any) => e);
	};

	useEffect(() => {
		getCompetitions(sport, language);
	}, []);

	return { competitions, isLoading };
};
