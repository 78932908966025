import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GenderEnum } from '../../../../../../Pages/Tournaments/constants/Contstants';

interface Props {
	label: string;
	value: string;
	flag: string;
	gender: string;
	selectAllSections: boolean;
	setSelectAllSections: (value: boolean) => void;
	selectedCompetitionIds: string[];
	setSelectedCompetitionIds: React.Dispatch<React.SetStateAction<string[]>>;
	setAllCompetitions: (value: boolean) => void;
}

export const CompetitionSection: FC<Props> = ({
	label,
	value,
	gender,
	selectAllSections,
	setSelectAllSections,
	selectedCompetitionIds,
	setSelectedCompetitionIds,
	setAllCompetitions,
}) => {
	const [t] = useTranslation();
	const [selectSection, setSelectSection] = useState(selectAllSections);

	useEffect(() => {
		const isSelected = selectedCompetitionIds.find((id) => id === value) ? true : false;
		if (!selectAllSections && !isSelected) {
			setSelectSection(false);
			return;
		}
		setSelectSection(isSelected);
	}, [selectAllSections, selectedCompetitionIds]);

	const name = gender ? `${label} - (${gender === GenderEnum.MALE ? t('male') : t('female')})` : label;
	const genderLocatorValue = gender ? `-${gender}-` : '-';

	return (
		<div id={`${label}${genderLocatorValue}competition-section-wrapper`} className='competition-section'>
			<div id={`${label}${genderLocatorValue}competition-section-container`} className='competition-section-container'>
				<div className='competition-section-checkbox-wrapper'>
					<div
						id={`${label}${genderLocatorValue}competition-section-input`}
						className='competition-section-checkbox-container'
						style={{ background: selectSection ? '#71A1FC' : '#FFF', border: selectSection ? '1px solid #71A1FC' : '1px solid #D9D9D9' }}
						onClick={() => {
							const competitionValue = !selectSection;

							if (competitionValue) {
								setSelectedCompetitionIds((prevIds) => (prevIds.includes(value) ? prevIds : [...prevIds, value]));
							} else {
								setSelectedCompetitionIds((prevIds) => prevIds.filter((id) => id !== value));
								setSelectAllSections(false);
								setAllCompetitions(false);
							}
							setSelectSection(competitionValue);
						}}
					>
						{selectSection && <span className='check-icon' />}
					</div>
				</div>
				<div className='competition-section-label-container'>
					<span id={`${label}${genderLocatorValue}competition-section-input`} className='competition-section-label'>
						{label}
					</span>
					<span id={`${label}${genderLocatorValue}competition-section-input`} className='competition-gender-label'>
						{name}
					</span>
				</div>
			</div>
		</div>
	);
};
