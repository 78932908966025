import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../../styles/article-ai-generation-panel.scss';
import '../../../../styles/date-picker.styles.scss';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../../store/store';
import { DATA_QA } from '../../../../helpers/data-qa.properties';
import { ISchedulesAdvancedSearchFiltersModel } from '../../../../models/models';
import { SelectMenuOptionType } from '../../../../../../../models/v2/general/select.model';
import { articleAiGenerationSelectStylesVariant } from '../../../../helpers/article-ai-generation.helper';
import { sportsConnectionsService } from '../../../../../../../App';
import _ from 'lodash';
import AsyncSelect from 'react-select/async';
import SportsEntityModel from '../../../../../../../models/v2/sports-types/sports-entity.model';
import { convertResultToOption } from '../../../../helpers/listing.helper';
import { customOption } from '../../../../../../Partials/Sidebar/tags/subcomponents/football-connections/option-component';

type Properties = {
	sport: string;
	schedulesFilters: ISchedulesAdvancedSearchFiltersModel;
	updateSchedulesAdvancedSearchText: (filters: ISchedulesAdvancedSearchFiltersModel) => void;
};

const CompetitionSelect: FunctionComponent<Properties> = ({ sport, schedulesFilters, updateSchedulesAdvancedSearchText }) => {
	const [t] = useTranslation();
	const project = useSelector((state: AppState) => state.project.currentProject);
	const [competitions, setCompetitions] = useState<SelectMenuOptionType[]>([]);

	const loadCompetitionData = (input: string, callback: any) => {
		if (input.length >= 3) {
			sportsConnectionsService.getCompetition(input, sport, project.language).then((res) => {
				const options = convertResultToOption(res as SportsEntityModel[]);
				setCompetitions(options);

				callback([...options]);
			});
		}
	};

	const onCompetitionSelect = (selectedCompetition: SelectMenuOptionType) => {
		let advancedContentModel = schedulesFilters;
		advancedContentModel.competition_id = selectedCompetition && selectedCompetition.data ? selectedCompetition.data.id : '';
		updateSchedulesAdvancedSearchText(advancedContentModel);
	};

	const selectedCompetition = competitions.find((option) => option.value === schedulesFilters.competition_id) || null;

	return (
		<div className='input-fields-group' data-qa={DATA_QA.ARTICLE_AI_GENERATION_GENERATE_ARTICLE_COMPETITION_SELECT}>
			<span className='input-fields-label'>{t('competition')}</span>
			{
				// @ts-ignore because of styles
				<AsyncSelect
					inputId={DATA_QA.ARTICLE_AI_GENERATION_GENERATE_ARTICLE_COMPETITION_SELECT}
					value={selectedCompetition}
					loadOptions={_.debounce(loadCompetitionData, 500)}
					onChange={onCompetitionSelect}
					formatOptionLabel={customOption}
					placeholder={t('select')}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					styles={articleAiGenerationSelectStylesVariant(false, '374px')}
				/>
			}
		</div>
	);
};

export default CompetitionSelect;
