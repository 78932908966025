import { call, put, select, takeEvery } from 'redux-saga/effects';
import HttpService from '../../services/rest/HttpService';
import { onError } from '../action-creators/GeneralActions';
import { actionService, featuresService } from '../../App';
import { toggleLoadingState, toggleModal, searchIsApplied, toggleContentTypeLoadingState } from '../action-creators/UiActionCreator';
import {
	GALLERIES_REQUEST,
	galleriesReceived,
	GALLERY_CREATE,
	GALLERY_DELETE,
	GALLERY_REQUEST,
	GALLERY_SEARCH,
	GALLERY_UPDATE,
	galleryCreateFailed,
	galleryCreateSuccess,
	galleryDeleteSuccess,
	galleryReceived,
	galleryRelatedCreateSuccess,
	galleryRelatedReceived,
	galleryRelatedUpdateSuccess,
	galleryUpdateFailed,
	galleryUpdateSuccess,
	returnObjectForGalleriesContentStatisticsReceived,
	returnObjectForContentStatisticsGalleryEntityReceived,
	returnObjectForGalleryAlreadyExists,
	triggerGalleryCreateResourcesSuccess,
} from '../action-creators/GalleryActionCreator';
import { returnObjectForListEntityUpdate } from '../action-creators/ListActionCreators';
import List from '../../models/list/List';
import ListItem from '../../models/list/list-item/ListItem';
import { FeatureTypes } from '../../services/feature-service/features.enum';
import { extractIds } from '../../views/Resources/Articles/Helpers/ArticleHelper';
import { createStartingBlocky } from '../../services/content-models-service/ContentModelService';
import { getSports, modifyContentResponseAsRelated, transferRefactoredWidgetsAsV2 } from './helpers/saga.helper';
import { ContentTypes } from '../../constants/content-types';
import { updateSidebarCustomEntitiesBulk } from '../action-creators/content-sidebar-tags';

function* fetchGalleries(action: any) {
	yield put(toggleLoadingState(true));
	let galleriesResponse: any = {};

	try {
		let headers = { Project: action.payload.project.domain };
		const constructURL = action.payload.text && action.payload.text.length > 0 ? `/search?query=${action.payload.text}&` : '?';

		galleriesResponse = yield call(HttpService.get, `/v2/galleries${constructURL}page=${action.payload.page}`, null, headers);

		yield put(galleriesReceived(galleriesResponse));
		yield put(toggleLoadingState(false));
	} catch (error) {
		yield put(onError(error));
	}

	try {
		if (
			featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_STATISTICS) &&
			galleriesResponse &&
			galleriesResponse.data &&
			galleriesResponse.data.data &&
			galleriesResponse.data.data.length > 0
		) {
			const contentStatisticsConfig = featuresService.getFeatureConfig(FeatureTypes.CONTENT_STATISTICS);
			const galleriesContentStatistics = yield call(
				HttpService.getContentStatistics,
				contentStatisticsConfig.request_headers[0],
				`${contentStatisticsConfig.url}?galleries=${extractIds(galleriesResponse.data.data)}`,
			);
			yield put(returnObjectForGalleriesContentStatisticsReceived(galleriesResponse.galleries, galleriesContentStatistics.data));
		}
	} catch (error) {
		yield put(onError(error));
	}
}

function* searchGalleries(action: any) {
	yield put(toggleLoadingState(true));
	let galleriesResponse: any = {};

	try {
		let headers = { Project: action.payload.project.domain };

		galleriesResponse = yield call(HttpService.get, `/v2/galleries/search?query=${action.payload.text}`, null, headers);

		yield put(galleriesReceived(galleriesResponse));
		yield put(toggleLoadingState(false));
		yield put(searchIsApplied());
	} catch (error) {
		yield put(onError(error));
	}

	try {
		if (
			featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_STATISTICS) &&
			galleriesResponse &&
			galleriesResponse.data &&
			galleriesResponse.data.data &&
			galleriesResponse.data.data.length > 0
		) {
			const contentStatisticsConfig = featuresService.getFeatureConfig(FeatureTypes.CONTENT_STATISTICS);
			const galleriesContentStatistics = yield call(
				HttpService.getContentStatistics,
				contentStatisticsConfig.request_headers[0],
				`${contentStatisticsConfig.url}?galleries=${extractIds(galleriesResponse.data.data)}`,
			);
			yield put(returnObjectForGalleriesContentStatisticsReceived(galleriesResponse.galleries, galleriesContentStatistics.data));
		}
	} catch (error) {
		yield put(onError(error));
	}
}

function* postGallery(action: any) {
	let headers = { Project: action.payload.project.domain };
	let galleryResponse: any = {};
	let galleryId = null;

	try {
		let galleryPayload = action.payload.gallery;
		try {
			if (galleryPayload.body.length < 1) {
				galleryPayload.body = createStartingBlocky();
			}
		} catch (e) {
			console.log(e);
		}
		galleryResponse = yield call(HttpService.post, '/galleries', galleryPayload, headers);
		galleryId = galleryResponse.data.data.id;
		if (action.payload.list) {
			const listItems = action.payload.list.items.map((item: ListItem) => {
				if (!item.data.id) {
					return ListItem.builder(item)
						.withData({ ...item.data, id: galleryId })
						.build();
				}
				return item;
			});
			const list = List.builder(action.payload.list).withItems(listItems).build();
			yield put(returnObjectForListEntityUpdate(list, action.payload.project));
		}
		yield put(galleryCreateSuccess(galleryId));
	} catch (error) {
		if (error.response.data.message.startsWith('A resource with the language code')) {
			yield put(returnObjectForGalleryAlreadyExists());
		} else {
			yield put(galleryCreateFailed());
		}
		actionService.emitError(error);
	}

	if (galleryId) {
		try {
			let relatedPayload = action.payload.related;
			yield call(HttpService.post, `galleries/${galleryId}/related`, relatedPayload, headers);
			yield put(galleryRelatedCreateSuccess(galleryId));
			yield put(triggerGalleryCreateResourcesSuccess(galleryId));
		} catch (error) {
			yield put(onError(error));
			actionService.emitError(error);
		}
	}
}

function* deleteGallery(action: any) {
	try {
		let headers = { Project: action.payload.project.domain };
		let id = action.payload.id;
		yield call(HttpService.delete, `/galleries/${id}`, null, headers);
		yield put(galleryDeleteSuccess());
		yield put(toggleModal(false));
	} catch (error) {
		yield put(onError(error));
	}
}

function* fetchGallery(action: any) {
	yield put(toggleLoadingState(true));
	yield put(toggleContentTypeLoadingState(ContentTypes.GALLERY, true));

	let galleryResponse: any = {};

	try {
		let headers = { Project: action.payload.project.domain };
		galleryResponse = yield call(HttpService.get, `/galleries/${action.payload.id}?optional_data=related_content`, null, headers);
		// format old gallery's refactored widgets as V2
		if (galleryResponse && galleryResponse.data && galleryResponse.data.data && galleryResponse.data.data.body) {
			galleryResponse.data.data.body = transferRefactoredWidgetsAsV2(galleryResponse.data.data.body);
		}

		// set gallery without related
		yield put(galleryReceived(galleryResponse));

		// set related data
		const galleryRelated = modifyContentResponseAsRelated(galleryResponse);
		const sports = yield select(getSports);
		yield put(updateSidebarCustomEntitiesBulk(galleryRelated.data.data));
		yield put(galleryRelatedReceived(galleryRelated, sports));

		yield put(toggleLoadingState(false));
	} catch (error) {
		yield put(onError(error));
	}

	try {
		if (featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_STATISTICS)) {
			const galleryId = galleryResponse.data.data.id;
			const contentStatisticsConfig = featuresService.getFeatureConfig(FeatureTypes.CONTENT_STATISTICS);

			const galleryContentStatistics = yield call(
				HttpService.getContentStatistics,
				contentStatisticsConfig.request_headers[0],
				`${contentStatisticsConfig.url}?galleries=${galleryId}`,
			);
			yield put(returnObjectForContentStatisticsGalleryEntityReceived(galleryResponse.galleryEdit, galleryContentStatistics.data));
		}
	} catch (error) {
		yield put(onError(error));
	}

	yield put(toggleContentTypeLoadingState(ContentTypes.GALLERY, false));
}

function* patchGallery(action: any) {
	let headers = { Project: action.payload.project.domain };
	const galleryId = action.payload.gallery.id;
	let galleryResponse: any = {};

	try {
		let galleryPayload = action.payload.gallery;
		try {
			if (galleryPayload.body.length < 1) {
				galleryPayload.body = createStartingBlocky();
			}
		} catch (e) {
			console.log(e);
		}
		galleryResponse = yield call(HttpService.patch, `/galleries/${galleryId}`, galleryPayload, headers);
		yield put(galleryReceived(galleryResponse));
		yield put(galleryUpdateSuccess());
	} catch (error) {
		yield put(galleryUpdateFailed());
		actionService.emitError(error);
	}

	try {
		let relatedPayload = action.payload.related;
		yield call(HttpService.post, `galleries/${galleryId}/related`, relatedPayload, headers);
		yield put(galleryRelatedUpdateSuccess());
	} catch (error) {
		yield put(onError(error));
		actionService.emitError(error);
	}

	try {
		if (featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_STATISTICS)) {
			const galleryId = galleryResponse.data.data.id;
			const contentStatisticsConfig = featuresService.getFeatureConfig(FeatureTypes.CONTENT_STATISTICS);

			const galleryContentStatistics = yield call(
				HttpService.getContentStatistics,
				contentStatisticsConfig.request_headers[0],
				`${contentStatisticsConfig.url}?galleries=${galleryId}`,
			);
			yield put(returnObjectForContentStatisticsGalleryEntityReceived(galleryResponse.galleryEdit, galleryContentStatistics.data));
		}
	} catch (error) {
		yield put(onError(error));
	}
}

function* gallerySaga() {
	yield takeEvery(GALLERIES_REQUEST, fetchGalleries);
	yield takeEvery(GALLERY_SEARCH, searchGalleries);
	yield takeEvery(GALLERY_CREATE, postGallery);
	yield takeEvery(GALLERY_UPDATE, patchGallery);
	yield takeEvery(GALLERY_DELETE, deleteGallery);
	yield takeEvery(GALLERY_REQUEST, fetchGallery);
}

export default gallerySaga;
