import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../../styles/article-ai-generation-panel.scss';
import '../../../../styles/date-picker.styles.scss';
import { connect, useSelector } from 'react-redux';
import { AppState } from '../../../../../../../store/store';
import { DATA_QA } from '../../../../helpers/data-qa.properties';
import { ISchedulesAdvancedSearchFiltersModel } from '../../../../models/models';
import Project from '../../../../../../../models/project/Project';
import { returnObjectForAllCategoryListingRequest } from '../../../../../../../store/action-creators/CategoriesActionCreators';
import Select from 'react-select';
import { categoriesToOptions } from '../../../../../../Partials/Sidebar/general-content/helpers/category-select.helper';
import { SelectMenuOptionType } from '../../../../../../../models/v2/general/select.model';
import { articleAiGenerationSelectStylesVariant } from '../../../../helpers/article-ai-generation.helper';

type Properties = {
	schedulesFilters: ISchedulesAdvancedSearchFiltersModel;
	updateSchedulesAdvancedSearchText: (filters: ISchedulesAdvancedSearchFiltersModel) => void;
	getCategories: (page: string, project: Project, adminId: string) => void;
};

const ScheduledListingCategorySelect: FunctionComponent<Properties> = ({
	schedulesFilters,
	updateSchedulesAdvancedSearchText,
	getCategories,
}) => {
	const [t] = useTranslation();
	const project = useSelector((state: AppState) => state.project.currentProject);
	const user = useSelector((state: AppState) => state.profile);
	const categoriesRedux = useSelector((state: AppState) => state.category.allCategories);
	const adminCategoriesRedux = useSelector((state: AppState) => state.category.adminCategories);

	useEffect(() => getCategories('1', project, user.profile.id), []);

	const onCategorySelect = (selectedCategory: SelectMenuOptionType) => {
		let advancedContentModel = schedulesFilters;
		advancedContentModel.category_id = selectedCategory && selectedCategory.data ? selectedCategory.data.id : '';
		updateSchedulesAdvancedSearchText(advancedContentModel);
	};

	const categoryOptions = categoriesToOptions(categoriesRedux, adminCategoriesRedux);
	const selectedCategory = categoryOptions.find((option) => option.value === schedulesFilters.category_id) || null;

	return (
		<div className='input-fields-group' data-qa={DATA_QA.ARTICLE_AI_GENERATION_GENERATE_ARTICLE_CATEGORY_SELECT}>
			<span className='input-fields-label'>{t('category')}</span>
			{
				<Select
					id={DATA_QA.ARTICLE_AI_GENERATION_GENERATE_ARTICLE_CATEGORY_SELECT}
					value={selectedCategory}
					options={categoryOptions}
					onChange={(selection) => onCategorySelect(selection as SelectMenuOptionType)}
					placeholder={t('select')}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					// @ts-ignore because of styles
					styles={articleAiGenerationSelectStylesVariant(false, '374px')}
				/>
			}
		</div>
	);
};

function mapDispatchToProps(dispatch: Function) {
	return {
		getCategories: (page: string, project: Project, adminId: string) => {
			dispatch(returnObjectForAllCategoryListingRequest(page, project, adminId));
		},
	};
}

export default connect(null, mapDispatchToProps)(ScheduledListingCategorySelect);
