import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { CompetitionListSection } from './competition-list-section.component';
import { CountryCompetitions } from '../../../types/livescore';
import { getToggleIcon } from '../../../../../../Pages/ArticleAiGeneration/helpers/article-ai-generation.helper';

interface Props {
	competitions: CountryCompetitions[];
	isModalOpen: boolean;
	toggleModal: () => void;
	withAllCompetitions: boolean;
	competitionIds: string[];
	setCompetitionIds: (values: string[]) => void;
}

export const CompetitionFilterModal: FC<Props> = ({
	competitions,
	isModalOpen,
	toggleModal,
	withAllCompetitions,
	competitionIds,
	setCompetitionIds,
}) => {
	const [t] = useTranslation();
	const allCompetitionIds = competitions.flatMap(({ competitions }) => competitions.map(({ value }) => value));
	const [selectAllCompetitions, setSelectAllCompetitions] = useState(withAllCompetitions);
	const [selectedCompetitionIds, setSelectedCompetitionIds] = useState<string[]>(competitionIds);

	useEffect(() => {
		setSelectAllCompetitions(withAllCompetitions);
	}, [withAllCompetitions]);

	useEffect(() => {
		setSelectedCompetitionIds(competitionIds);
	}, [competitionIds]);

	const toggleAllCompetitions = () => {
		const value = !selectAllCompetitions;

		if (!value) {
			setSelectedCompetitionIds([]);
			setSelectAllCompetitions(value);
			return;
		}
		setSelectedCompetitionIds(allCompetitionIds);
		setSelectAllCompetitions(value);
	};

	const saveModalChanges = () => {
		setCompetitionIds(selectedCompetitionIds);
		toggleModal();
	};

	useEffect(() => {
		if (selectedCompetitionIds.length === allCompetitionIds.length) {
			setSelectAllCompetitions(true);
		}
	}, [selectedCompetitionIds]);

	return (
		<Modal id='competition-filter-modal' isOpen={isModalOpen} toggle={toggleModal} size='lg'>
			<ModalHeader id='competition-filter-modal-header' toggle={toggleModal} className='modal-header-container'>
				<span id='competition-filter-modal-header-title'>{t('tournament_filter_modal.modal_title')}</span>
				<p id='competition-filter-modal-header-subTitle' className='modal-header-description'>
					{t('tournament_filter_modal.modal_description')}
				</p>
				<FormGroup id={'all-competitions-toggle-container'} className='override-form-group'>
					<div id={`all-competitions-toggle-input`} className='competition-section-label-container' onClick={toggleAllCompetitions}>
						<span
							className='cursor-pointer  select-all-competitions-toggle'
							style={{ backgroundImage: `url("${getToggleIcon(selectAllCompetitions)}")` }}
						/>
						<Label check htmlFor={'all-competitions-toggle-input'} className='select-all-competitions-label'>
							{t('tournament_filter_modal.select_all_competitions_label')}
						</Label>
					</div>
				</FormGroup>
			</ModalHeader>
			<ModalBody id='competition-filter-modal-body' className='modal-body-container'>
				<div id='competition-sections-container'>
					{competitions &&
						competitions.length > 0 &&
						competitions.map(({ country, flagUrl, competitions }) => (
							<CompetitionListSection
								label={country}
								flag={flagUrl}
								competitions={competitions}
								selectAllCompetitions={selectAllCompetitions}
								setSelectAllCompetitions={setSelectAllCompetitions}
								selectedCompetitionIds={selectedCompetitionIds}
								setSelectedCompetitionIds={setSelectedCompetitionIds}
							/>
						))}
				</div>
			</ModalBody>
			<ModalFooter id='competition-filter-modal-footer' className='modal-footer-container'>
				<Button id='competition-filter-modal-footer-save-btn' color='primary' onClick={saveModalChanges}>
					{t('tournament_filter_modal.modal_save_btn')}
				</Button>
				<Button id='competition-filter-modal-footer-close-btn' color='secondary' onClick={toggleModal}>
					{t('tournament_filter_modal.modal_cancel_btn')}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
