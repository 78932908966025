import Related from '../../../../../../../models/related/Related';
import { relatedConstants } from '../../../../../../../constants/related.constants';
import { assetsPlaceholder } from '../../../../../../../constants/assetsPlaceholder';
import EventModel from '../../../../../../../models/v2/sports-types/eventModel';
import EntityTypesModel from '../../../../../../../models/v2/sports-types/entity-types.model';
import moment from 'moment';
import { State } from './events-container';
import './css/games.scss';
import { SportTypes } from '../../../../../../../constants/sport.types';

export const BORDER_PULSATE_INTERRUPTED_CLASS = 'border-pulsate-interrupted-right';

export const EVENTS_STATUS = {
	notStarted: 'eventsNotStarted',
	finished: 'eventsFinished',
	interrupted: 'eventsInterrupted',
};

export const requestStatuses = {
	eventsNotStarted: 'status_type=NOT_STARTED,LIVE&sort_direction=ASC',
	eventsFinished: 'status_type=FINISHED',
	eventsInterrupted: 'status_type=INTERRUPTED',
};

// Show the score of a game if it is LIVE or FINISHED
export const toggleScore = (event: EventModel) => {
	return event.status === 'LIVE' || event.status === 'FINISHED' ? ` (${event.homeScore}:${event.awayScore}) ` : ` - `;
};

export const defineLimit = (events: EventModel[]) => (events && events.length > 0 ? events.length + 10 : 10);

export const disableLoadEvents = (stateEvents: EventModel[], responseEvents: EventModel[]) => {
	return defineLimit(stateEvents) > responseEvents.length;
};

// This method constructs the search url to /events?participants_filter=${entityIds}
export const constructEventRequestUrl = (entityIds: string[], state: State, sport: string, translationLanguage: string) => {
	const { date, activeTab } = state;

	const limit = defineLimit(state[activeTab]);
	const dateFrom = `&from_start_time=${date.from ? `${moment(date.from).format('YYYY-MM-DD')}T00:00:00-00:00` : date.from}`;
	const dateTo = `&to_start_time=${date.to ? `${moment(date.to).format('YYYY-MM-DD')}T00:00:00-00:00` : date.to}`;

	if (entityIds && entityIds.length > 0) {
		return `/events?participants_filter=${entityIds.join(',')}&sport=${sport}&${requestStatuses[activeTab]}${dateFrom}${dateTo}&limit=${
			limit > 200 ? 200 : limit
		}&translation_language=${translationLanguage}`;
	}

	return '';
};

export const sportToOption = (data: any, t?: any) => {
	if (data && data.title) {
		return { label: data.title, value: data.sport, data };
	}

	return {};
};

// Exclude all entity types except player and team, this is used in Advance search filters
export const excludeEntityTypes = (entityTypes: EntityTypesModel[], allowMoreEntities: boolean) => {
	return allowMoreEntities
		? entityTypes.filter((entity: EntityTypesModel) => entity.type !== relatedConstants.types.event)
		: entityTypes.filter(
				(entity: EntityTypesModel) =>
					entity.type !== relatedConstants.types.event &&
					entity.name !== relatedConstants.types.match &&
					entity.name !== relatedConstants.types.competition &&
					entity.name !== relatedConstants.types.arena &&
					entity.name !== relatedConstants.types.coach,
		  );
};

// This method is used for the check if a sports type is enabled as feature
export const isEmptyArray = (arr: any[]) => {
	return arr == undefined || arr === null || arr.length < 1;
};

export const sportsToOptions = (items: any[], t?: any) => {
	if (items && items.length > 0) {
		return items.map((item: any) => sportToOption(item));
	}
	return [];
};

// Build a selected option from the Sports Connections container as related content
export const optionToRelatedContent = (option: any) => {
	if (option) {
		return Related.builder().withData(option.data).withProvider(relatedConstants.providers.sports).withType(option.type).build();
	}

	return {} as Related;
};

export const selectionToRelated = (selections: any) => {
	if (selections) {
		return selections
			.filter((selection: any) => Object.entries(selection).length > 0)
			.map((selection: any) => optionToRelatedContent(selection));
	}

	return [];
};

export const convertResultToOption = (response: any) => {
	if (response && Object.entries(response).length > 0) {
		return {
			value: response.id + relatedConstants.types[response.entity_type],
			label: response.name,
			logo:
				response.display_asset && response.display_asset.url !== '' && response.display_asset.url !== null
					? response.display_asset.url
					: assetsPlaceholder[response.entity_type],
			type: relatedConstants.types[response.entity_type.toLowerCase()],
			data: response,
		};
	}

	return {};
};

export const competitionRelatedToOption = (related: Related) => {
	let option = {};
	if (related && related.data) {
		return {
			value: related.data.id,
			label: related.data.name,
			logo:
				related.data.display_asset && related.data.display_asset.url !== '' && related.data.display_asset.url !== null
					? related.data.display_asset.url
					: assetsPlaceholder[related.data.entity_type],
			type: related.type,
			data: related.data,
		};
	}

	return option;
};

export function convertRelatedDataToQueryIds(related: Related[]): string[] {
	if (related && related.length > 0) {
		return related
			.filter(
				(related: Related) => (related && related.type === relatedConstants.types.team) || related.type === relatedConstants.types.player,
			)
			.map((related: Related) => related.data.id);
	}

	return [];
}

export const removeDuplicateItems = (relatedData: Related[]) => {
	const uniqueItems: any = [];
	return relatedData.filter((element: any) => {
		if (element && element.data) {
			const isDuplicate = uniqueItems.find(
				(item: any) => `${item.id}` === `${element.data.id}` && item.entityType === element.data.entity_type,
			);

			if (!isDuplicate) {
				uniqueItems.push({ id: element.data.id, entityType: element.data.entity_type });
				return true;
			}
		}

		return false;
	});
};

export const filterSelectedSportsRelated = (related: Related[], sportType: string) => {
	return related && related.filter((item: Related) => item && item.data.sport === sportType);
};

// Extract entities which are only from sports-search-api
export const filterSportsRelated = (related: Related[]) => {
	return related && related.filter((item: Related) => item && item.provider === relatedConstants.providers.sports);
};

// Extract entities which are only from football-api
export const filterFootballRelated = (related: Related[]) => {
	return related && related.filter((item: Related) => item && item.provider === relatedConstants.providers.football);
};

// Extract entities which are games and related to other sports
export function extractEventsFromRelated(related: Related[]) {
	return related && related.filter((item: Related) => item && item.provider !== relatedConstants.providers.football);
}

// Extract entities which are matches and related to football
export function extractMatchesFromRelated(related: Related[]) {
	return (
		related &&
		related.filter(
			(item: Related) => item && item.type === relatedConstants.types.match && item.provider === relatedConstants.providers.football,
		)
	);
}

export function extractEventIdsFromRelated(eventRelated: Related[]) {
	if (eventRelated) {
		return eventRelated.filter((related: Related) => related && related.data.status_type).map((related: Related) => related.data.id);
	}

	return [];
}

export const removePastEvents = (events: EventModel[]) => {
	return events.filter((event: any) => moment().diff(event.gameTime, 'days') < 1 && event.coverage !== 'CANCELLED');
};

export function eventModelToRelated(event: EventModel) {
	return Related.builder().withData(event.data).withType(event.type).withProvider(relatedConstants.providers.sports).build();
}

const extractParticipantName = (participantPosition: number, event: any): string => {
	try {
		const participantResult = event.results.find((result: any) => result.position == participantPosition);
		return participantResult.name || 'No name';
	} catch (error) {
		return '';
	}
};

const extractParticipantResult = (participantName: string, event: any): string => {
	try {
		const participantResult = event.results.find((result: any) => result.name === participantName);
		return participantResult.value.toString() || '-';
	} catch (error) {
		return '-';
	}
};

export const extractParticipantNameAndResult = (participantPosition: number, event: any): Record<string, string> => {
	const participantName = extractParticipantName(participantPosition, event);
	const participantResult = extractParticipantResult(participantName, event);

	return {
		name: participantName,
		result: participantResult,
	};
};

// Extract the first team from the name for translation purposes
export function extractFirstTeamsFromGameName(name: string, sport: string) {
	// multisport api returns names in different format which creates ui bugs
	if (sport === SportTypes.ICE_HOCKEY) {
		return name.substring(0, name.indexOf('-'));
	}
	return name.substring(0, name.indexOf(' - '));
}

// Extract the second team from the name for translation purposes
export function extractSecondTeamsFromGameName(name: string, sport: string) {
	// multisport api returns names in different format which creates ui bugs
	if (sport === SportTypes.ICE_HOCKEY) {
		return name.substring(name.indexOf('-') + 1);
	}
	return name.substring(name.indexOf(' - ') + 2);
}

// Remapper for retrieved games to the Game Model
export function responseToEventModel(event: any): EventModel {
	const homeTeamNameAndResult = extractParticipantNameAndResult(1, event);
	const awayTeamNameAndResult = extractParticipantNameAndResult(2, event);

	return {
		id: event.id,
		name: event.name,
		status: event.status_type,
		participants: event.participants,
		startTime: event.start_time ? event.start_time : '',
		homeTeam: homeTeamNameAndResult.name,
		awayTeam: awayTeamNameAndResult.name,
		homeScore: homeTeamNameAndResult.result,
		awayScore: awayTeamNameAndResult.result,
		results: event.results,
		translation: event.translations,
		sport: event.sport,
		type: event.entity_type,
		isSelected: false,
		competition: event.competition,
		data: event,
	};
}

export const remapEventsResponse = (response: Record<string, any>) => {
	if (response && response.length > 0) {
		return response
			.filter((event: any) => {
				return event.status_type === 'NOT_STARTED' || event.status_type === 'LIVE'
					? Object.entries(event).length > 0 && moment(event.start_time).format() >= moment().format()
					: Object.entries(event).length > 0;
			})
			.map((event: any) => responseToEventModel(event));
	}

	return [];
};

export const sportsRelatedToOptions = (related: Related[]) => {
	let options: any[] = [];
	if (related && related.length > 0) {
		options = related.map((related: Related) => {
			return {
				value: related.data.id + related.type,
				label: related.data.name,
				logo:
					related.data.display_asset && related.data.display_asset.url !== '' && related.data.display_asset.url !== null
						? related.data.display_asset.url
						: assetsPlaceholder[related.data.entity_type],
				type: related.type,
				data: related.data,
			};
		});
	}

	return options;
};

export function extractRelatedContent(related: Related[]) {
	let tempRelatedContent: Related[] = [];

	if (related && related.length > 0) {
		tempRelatedContent = related.filter(
			(related: Related) =>
				related.type === relatedConstants.types.article ||
				related.type === relatedConstants.types.video ||
				related.type === relatedConstants.types.gallery ||
				related.type === relatedConstants.types.game,
		);
	}

	return tempRelatedContent;
}

export const extractSportsRelatedFromResponse = (response: any, sports: any): Related[] => {
	return response
		.filter((item: any) => item.data.sport && item.data.sport !== SportTypes.FOOTBALL && extractEntities(sports).flat().includes(item.type))
		.map((item: any) => {
			return Related.builder().withData(item.data).withProvider(item.provider).withType(item.type).build();
		});
};

export const extractEntitiesTypesFromSports = (entities: any) => {
	return (
		entities &&
		entities
			.filter((item: any) => item.type === relatedConstants.types.event)
			.map((item: any) => {
				return item.name;
			})
	);
};
export const extractEntitiesTypesExceptEventsFromSports = (entities: any) => {
	return (
		entities &&
		entities
			.filter((item: any) => item.type !== relatedConstants.types.event)
			.map((item: any) => {
				return item.name;
			})
	);
};
export const extractEntities = (sports: any) => {
	return (
		sports &&
		sports
			.filter((item: any) => item.entity_types && item.sport !== SportTypes.FOOTBALL)
			.map((item: any) => {
				return extractEntitiesTypesExceptEventsFromSports(item.entity_types);
			})
	);
};
export const extractEventEntitiesFromSports = (sports: any) => {
	return (
		sports &&
		sports
			.filter((item: any) => item.entity_types && item.sport !== SportTypes.FOOTBALL)
			.map((item: any) => {
				return extractEntitiesTypesFromSports(item.entity_types);
			})
	);
};
