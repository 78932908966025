import * as axios from 'axios';
import moment from 'moment';
import { REFRESH_TOKEN_KEY, TOKEN_KEY } from '../../constants/constants';
import { ContentTypes } from '../../constants/content-types';
import { store } from '../../store/store';
import { getUniqueOnly } from '../../views/Partials/Blocky/shared/global-functions/global-functions';
import REACT_APP_URLS from '../../global-helpers/global-url.helpers';

const BASE_URL = REACT_APP_URLS.REACT_APP_CONTENT_API_BASE_URL;
const CLIENT_API_BASE_URL = REACT_APP_URLS.REACT_APP_CLIENT_API_BASE_URL;
const OAUTH_CLIENT_ID = REACT_APP_URLS.REACT_APP_OAUTH_CLIENT_ID;
const OAUTH_CLIENT_SECRET = REACT_APP_URLS.REACT_APP_OAUTH_CLIENT_SECRET;
const OAUTH_GRANT_TYPE = REACT_APP_URLS.REACT_APP_OAUTH_GRANT_TYPE;
const IMAGE_API_URL = REACT_APP_URLS.REACT_APP_IMAGE_API_BASE_URL;
const FEED_API_URL = REACT_APP_URLS.REACT_APP_NEWS_FEED_URL;
const STATISTICS_API_URL = REACT_APP_URLS.REACT_APP_STATISTICS_API_BASE_URL;
const GRANT_TYPE_REFRESH_TOKEN = 'refresh_token';
const FOOTBALL_API_AUTH = REACT_APP_URLS.REACT_APP_GET_FOOTBALL_API_AUTH();
const CLIENT_API_AUTH = REACT_APP_URLS.REACT_APP_GET_CLIENT_AUTH();
const STATISTICS_API_AUTH = REACT_APP_URLS.REACT_APP_GET_STATISTICS_API_AUTH();
const CUSTOMIZATION_API_AUTH = REACT_APP_URLS.REACT_APP_GET_CUSTOMIZATION_API_AUTH();

export const PLAYER_INFO_TYPE = {
	TEAMS: 'teams',
	SEASONS: 'seasons',
	TOURNAMENTS: 'tournaments',
};

export default class HttpService {
	static setInterceptorRequestData = (axiosInstance, withAuth) => {
		const projectHeader = store.getState().project.currentProject.domain;
		axiosInstance.interceptors.request.use(
			async (config) => {
				config.headers = {
					...config.headers,
					Accept: 'application/json',
				};

				if (!config.headers['X-Project']) {
					config.headers['X-Project'] = projectHeader;
				}

				if (withAuth) {
					const token = localStorage.getItem(TOKEN_KEY);
					config.headers.Authorization = token;
				}
				return config;
			},
			(error) => {
				Promise.reject(error);
			},
		);
	};

	// Refresh token
	static getRefreshAccessToken = () => {
		const refreshTokenBody = {
			client_id: OAUTH_CLIENT_ID,
			client_secret: OAUTH_CLIENT_SECRET,
			grant_type: GRANT_TYPE_REFRESH_TOKEN,
			scope: '',
			refresh_token: localStorage.getItem(REFRESH_TOKEN_KEY),
		};

		return axios.create({ baseURL: BASE_URL }, { headers: { Accpet: 'application/json' } }).post('/oauth/token', refreshTokenBody);
	};

	//Refresh Token logic
	static initAxiosInterceptors = (axiosInstance) => {
		this.setInterceptorRequestData(axiosInstance, true);

		axiosInstance.interceptors.response.use(
			(response) => {
				return response;
			},
			async function (error) {
				const originalRequest = error.config;
				if (error && error.response && error.response.status === 401 && !originalRequest._retry) {
					originalRequest._retry = true;

					const response = await HttpService.getRefreshAccessToken();

					const requestToken = `Bearer ${response.data.access_token}`;
					localStorage.setItem(TOKEN_KEY, requestToken);
					localStorage.setItem(REFRESH_TOKEN_KEY, response.data.refresh_token);

					axios.defaults.headers.common['Authorization'] = requestToken;
					return axiosInstance(originalRequest);
				}
				return Promise.reject(error);
			},
		);

		return axiosInstance;
	};

	// Basic interceptors logic (without authorization)
	static initCoreAxiosInterceptors = (axiosInstance) => {
		this.setInterceptorRequestData(axiosInstance, false);
		return axiosInstance;
	};

	static instance = (headers, baseUrl) => {
		return HttpService.initAxiosInterceptors(
			axios.create({
				baseURL: baseUrl ? baseUrl : BASE_URL,
				timeout: 30000,
				headers: {
					...headers,
					Authorization: localStorage.getItem(TOKEN_KEY),
					'X-SMP-Cache-Disable': true,
				},
			}),
		);
	};

	static instanceExternalApi = (headers, baseUrl) =>
		axios.create({
			baseURL: baseUrl,
			timeout: 30000,
			headers: { ...headers },
		});

	static instanceFootball = (acceptLang) => {
		return HttpService.initCoreAxiosInterceptors(
			axios.create({
				baseURL: `${store.getState().project.currentProject.footballApiUrl}`,
				timeout: 30000,
				headers: {
					Authorization: FOOTBALL_API_AUTH,
					'X-Odd-Client': `${store.getState().project.currentProject.defaultOddClient}`,
					'X-SMP-Cache-Disable': true,
					'Accept-Language': acceptLang
						? acceptLang
						: `${store.getState().project.currentProject.language ? store.getState().project.currentProject.language.split('-')[0] : 'en'}`,
				},
			}),
		);
	};

	static instanceImageApi = (headers, baseUrl) =>
		HttpService.initCoreAxiosInterceptors(
			axios.create({
				baseURL: baseUrl ? baseUrl : IMAGE_API_URL,
				headers,
			}),
		);

	static instanceStatisticsApi = (headers, baseUrl) =>
		HttpService.initCoreAxiosInterceptors(
			axios.create({
				baseURL: baseUrl ? baseUrl : STATISTICS_API_URL,
				headers: { ...headers, Authorization: STATISTICS_API_AUTH },
			}),
		);

	static postImg(url, params, headers, baseUrl) {
		return HttpService.instanceImageApi(headers, baseUrl).post(url, params);
	}

	static get(url, params, headers, statisticUrl) {
		return HttpService.instance(headers, statisticUrl).get(url, params);
	}

	static getArticle(id) {
		const project = store.getState().project.currentProject.domain;
		const headers = { Project: project };

		return HttpService.instance(headers).get(`/articles/${id}`);
	}

	static getVideo(id) {
		const project = store.getState().project.currentProject.domain;
		const headers = { Project: project };

		return HttpService.instance(headers).get(`/videos/${id}`);
	}

	static getGallery(id) {
		const project = store.getState().project.currentProject.domain;
		const headers = { Project: project };

		return HttpService.instance(headers).get(`/galleries/${id}`);
	}

	static getDugoutVideos(baseUrl, url, params, headers) {
		return HttpService.instanceExternalApi(headers, baseUrl).get(url, params);
	}

	static getOnnetworkVideos(baseUrl, url, params, headers) {
		return HttpService.instanceExternalApi(headers, baseUrl).post(url, params);
	}

	static getImagoImages(baseUrl, url, params, headers) {
		return HttpService.instanceExternalApi(headers, baseUrl).post(url, params);
	}

	static getSpotlightAudios(baseUrl, headers) {
		return HttpService.instanceExternalApi(headers, baseUrl).get();
	}

	static getStatistics(url, params, headers) {
		return HttpService.instanceStatisticsApi(headers).get(url, params);
	}

	static post(url, params, headers) {
		return HttpService.instance(headers).post(url, params);
	}

	static patch(url, params, headers) {
		return HttpService.instance(headers).patch(url, params);
	}

	static delete(url, params, headers) {
		return HttpService.instance(headers).delete(url, { params: params });
	}

	static getPlayerSeasonStatistics(playerId, lang) {
		return HttpService.instanceFootball().get(`/statistics/players?player_ids=${playerId}&language_code=${lang}`);
	}

	static getPlayerSeasonStatisticsByTeamId(teamId) {
		return HttpService.instanceFootball().get(`/teams/${teamId}/players/statistics/seasons`);
	}

	static getPlayer(name) {
		return HttpService.instanceFootball().get(`/players?name=${name}`);
	}

	static getPlayerById(id) {
		return HttpService.instanceFootball().get(`/players/${id}`);
	}

	static getPlayerByIdV2(playerId, lang) {
		return HttpService.instanceFootball().get(`/v2/players/${playerId}?language_code=${lang}`);
	}

	static getTeam(name) {
		return HttpService.instanceFootball().get(`/teams?name=${name}`);
	}

	static getTeamById(id) {
		return HttpService.instanceFootball().get(`/teams/${id}`);
	}

	static getTeamSeasons(teamId, lang) {
		return HttpService.instanceFootball().get(`/v2/seasons?team_id=${teamId}&language_code=${lang}`);
	}

	static getTeamByIdV2(teamId, lang) {
		return HttpService.instanceFootball().get(`/v2/teams/${teamId}?language_code=${lang}`);
	}

	static getTournamentStageTeams = (stageId, lang) => {
		return HttpService.instanceFootball(lang).get(`/tournaments/seasons/stages/${stageId}/teams`);
	};

	static updateTournamentStageTeams = (stageId, teams) => {
		return HttpService.instanceFootball().put(`/v2/stages/${stageId}/teams`, teams);
	};

	static getTeamPlayers(teamId) {
		return HttpService.instanceFootball().get(`/teams/${teamId}/players`);
	}

	static getAllBookmakers() {
		return HttpService.instanceFootball().get(`/odd_providers`);
	}

	static getEventOdds(eventId) {
		return HttpService.instanceFootball().get(`/events/${eventId}/odds`);
	}

	static getRoundEvents(stageId, round) {
		return HttpService.instanceFootball().get(`/matches?stage_ids=${stageId}&rounds=${round}`);
	}

	static getMatchesFromCurrentDateByTeamId(teamId) {
		const startTime = moment().subtract(2, 'hours').utc().format();

		return HttpService.instanceFootball().get(`/matches?team_ids=${teamId}&from_start_time=${startTime}&sort_direction=asc`);
	}

	static getMatchesByPlayerId(playerTeams, lang) {
		return HttpService.instanceFootball().get(
			`/v2/matches?team_ids=${playerTeams}&team_ids_operator=OR&limit=200&offset=0&sort_direction=asc&status_types=LIVE,NOT_STARTED&language_code=${lang}`,
		);
	}

	static getMatchesByTeamId(teamId, lang) {
		return HttpService.instanceFootball().get(
			`/v2/matches?team_ids=${teamId}&limit=200&offset=0&sort_direction=asc&status_types=LIVE,NOT_STARTED&language_code=${lang}`,
		);
	}

	static getStageById(stageId) {
		return HttpService.instanceFootball().get(`/tournaments/seasons/stages/${stageId}?expand=standing`);
	}

	static getTournaments(tournamentOrder) {
		if (tournamentOrder && tournamentOrder.length > 0) {
			return HttpService.instanceFootball().get(`/tournaments?client_order=${tournamentOrder}`);
		}

		return HttpService.instanceFootball().get(`/tournaments`);
	}

	static getSeasonStages(seasonId) {
		return HttpService.instanceFootball().get(`/v2/seasons/details?season_id=${seasonId}`);
	}

	static getAllTournaments() {
		return HttpService.instanceFootball().get(`/tournaments`);
	}

	static getTopScorersBySeasonId(seasonId) {
		return HttpService.instanceFootball().get(`/tournaments/seasons/${seasonId}/topscorer`);
	}

	static getTopScorersTeamBySeasonId = (seasonId) => {
		return new Promise((resolve, reject) => {
			HttpService.instanceFootball()
				.get(`/tournaments/seasons/${seasonId}/topscorer`)
				.then((response) => {
					const teams = response.data.map((result) => {
						return result.team;
					});
					resolve(getUniqueOnly(teams, 'id'));

					reject('No data"');
				})
				.catch((e) => {
					reject(e);
				});
		});
	};

	static getTournamentById(id, lang) {
		return HttpService.instanceFootball(lang).get(`/tournaments/${id}`);
	}

	static requestMultipleFootballRequests(urls) {
		let requests = [];

		urls.forEach((url) => {
			const request = HttpService.instanceFootball().get(url);
			requests.push(request);
		});

		return axios.all(requests);
	}

	static all(requests) {
		return axios.all(requests);
	}

	static getProjectClientAPI = (projectId) => {
		const request = axios.create({
			baseURL: CLIENT_API_BASE_URL,
			headers: { Authorization: CLIENT_API_AUTH },
		});

		return request.get(`/projects/${projectId}/features`);
	};

	static getAdminProjects(id) {
		return HttpService.instance().get(`admins/${id}/projects?limit=200`);
	}

	static getAllPages(url, params, headers, perPageItems = 20) {
		return HttpService.instance(headers)
			.get(url + `?limit=${perPageItems}`)
			.then((response) => {
				let requestUrls = [];

				for (let i = 1; i <= response.data.meta.pagination.total_pages; i++) {
					requestUrls.push(HttpService.get(`${url}?page=${i}&limit=${perPageItems}`, null, headers));
				}

				return axios.all(requestUrls);
			});
	}

	static requestMultiple(urls, baseUrl, headers) {
		let requests = [];
		let axiosRequest = axios.create({
			timeout: 30000,
			headers: {
				...headers,
			},
		});

		urls.forEach((url) => {
			const request = axiosRequest.get(`${baseUrl}${url}`);
			requests.push(request);
		});

		return axios.all(requests);
	}

	static requestFootballData(resourceType, name, footballApiUrl) {
		let axiosRequest = axios.create({
			timeout: 3000,
			headers: {
				Authorization: FOOTBALL_API_AUTH,
				'Accept-Language': `${
					store.getState().project.currentProject.language ? store.getState().project.currentProject.language.split('-')[0] : 'en'
				}`,
			},
		});

		return axiosRequest.get(`${footballApiUrl}/${resourceType}s?name=${name}`);
	}

	static getToken(username, password) {
		let body = {
			client_id: OAUTH_CLIENT_ID,
			client_secret: OAUTH_CLIENT_SECRET,
			grant_type: OAUTH_GRANT_TYPE,
			password,
			scope: '',
			username,
		};

		return axios({
			url: `${BASE_URL}oauth/token`,
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
			},
			data: JSON.stringify(body),
		});
	}

	static instanceFeedApi = (headers) =>
		axios.create({
			baseURL: FEED_API_URL,
			headers,
		});

	static getFeed = (url, params, headers) => {
		return HttpService.instanceFeedApi(headers).get(url, params);
	};

	static postFeed = (url, params, headers) => {
		return HttpService.instanceFeedApi(headers).post(url, params);
	};

	static putFeed = (url, params, headers) => {
		return HttpService.instanceFeedApi(headers).put(url, params);
	};

	static postSource = (url, params, headers) => {
		return HttpService.instanceFeedApi(headers).post(url, params);
	};

	static deleteFeed = (url, params, headers) => {
		return HttpService.instanceFeedApi(headers).delete(url, params);
	};

	static getPlayerMatchesInfo = (playerId, infoType, lang) => {
		return new Promise((resolve, reject) => {
			HttpService.instanceFootball()
				.get(`/statistics/players?player_ids=${playerId}&language_code=${lang}`)
				.then((response) => {
					if (infoType === PLAYER_INFO_TYPE.TEAMS) {
						const teams = response.data.map((statistic) => {
							return statistic.team;
						});
						resolve(getUniqueOnly(teams, 'id'));
					}

					if (infoType === PLAYER_INFO_TYPE.SEASONS) {
						const seasons = response.data.map((statistic) => {
							return statistic.season;
						});
						resolve(getUniqueOnly(seasons, 'id'));
					}

					if (infoType === PLAYER_INFO_TYPE.TOURNAMENTS) {
						const tournaments = response.data.map((statistic) => {
							return statistic.tournament;
						});
						resolve(getUniqueOnly(tournaments, 'id'));
					}
					reject('Player info type filter did not match any of the existing: "teams", "seasons", "tournaments"');
				})
				.catch((e) => {
					reject(e);
				});
		});
	};

	static getSeasonById = (id) => {
		return HttpService.instanceFootball().get(`/tournaments/seasons/${id}`);
	};

	static getRoundsByStageId = (stageId) => {
		return HttpService.instanceFootball().get(`/tournaments/seasons/stages/${stageId}/rounds`);
	};

	static getRoundsByStageIdV2 = (stageId) => {
		return HttpService.instanceFootball().get(`v2/stages/${stageId}/rounds`);
	};

	static getAllUsers = (url, params, headers) => {
		return HttpService.instance(headers).get(url, params);
	};

	static checkIsValidImageUrl = (url) => {
		return HttpService.get(url);
	};

	static getFootballData = (query, lang, entity_type) => {
		return HttpService.instanceFootball(lang).get(`/search?query=${query}&entity_type=${entity_type}`);
	};

	static getTeams = (url) => {
		return HttpService.instanceFootball().get(url);
	};

	static getTeamsBatch = (teamIds) => {
		const teamsRequests = teamIds.map((teamId) => HttpService.instanceFootball().get(`/v2/teams/${teamId}`));
		return Promise.all(teamsRequests).then((responses) => {
			return responses.map((response) => response.data);
		});
	};

	static getBlacklist = (url, params) => {
		return HttpService.instanceFootball().post(url, params);
	};

	static setTeamColors = (url, params) => {
		return HttpService.instanceFootball().post(url, params);
	};

	static saveTeamColors = (url, params) => {
		return HttpService.instanceFootball().post(url, params);
	};

	static getTeamColors = (teamID) => {
		return HttpService.instanceFootball().get(`/v2/teams/${teamID}`);
	};
	static getMatchById = (matchID) => {
		return HttpService.instanceFootball().get(`/v2/matches/${matchID}`);
	};

	static removeFromBlacklist = (id) => {
		return HttpService.instanceFootball().delete(`/v2/blacklist/${id}`);
	};

	static getAssetsTypes = () => {
		return HttpService.instanceFootball().get(`/v2/assets/types`);
	};

	static deleteAssets = (data) => {
		const params = { data: data };
		return HttpService.instanceFootball().delete('/v2/assets', params);
	};

	static postAsset = (id, type, entity, path) => {
		const params = { type: type, entity: entity, entity_id: `${id}`, path: path };
		return HttpService.instanceFootball().post('/v2/assets', [params]);
	};

	static previewImagoImages(baseUrl, url, headers) {
		return HttpService.instanceExternalApi(headers, baseUrl).get(url);
	}

	static getContentStatistics = (headers, url) => {
		return HttpService.instanceExternalApi(headers).get(url);
	};

	static getYoutubeSportsVideos(baseUrl, url, params, headers) {
		return HttpService.instanceExternalApi(headers, baseUrl).get(url, params);
	}

	static getTeamsBySeasonId = (seasonId, lang) => {
		return HttpService.instanceFootball(lang).get(`/tournaments/seasons/${seasonId}/teams`);
	};

	static getAllTournamentsGroups = () => {
		return HttpService.instanceFootball().get(`/v2/tournaments/groups`);
	};

	static getTournamentsGroupByCode = (code = '') => {
		return HttpService.instanceFootball().get(`/v2/tournaments/groups/${code}`);
	};

	static updateTournamentsGroupByCode = (code = '', data) => {
		const baseUrl = store.getState().project.currentProject.footballApiUrl;
		const project = store.getState().project.currentProject.domain;
		const defaultHeaders = {
			'Content-Type': 'application/json',
			Project: project,
			'X-Project': project,
			Authorization: FOOTBALL_API_AUTH,
			'X-Odd-Client': `${store.getState().project.currentProject.defaultOddClient}`,
			'X-SMP-Cache-Disable': true,
		};

		return fetch(baseUrl + `/v2/tournaments/groups/${code}`, {
			method: 'PUT',
			headers: defaultHeaders,
			body: JSON.stringify(data),
		});
	};

	static createTournamentsGroup = (body) => {
		const baseUrl = store.getState().project.currentProject.footballApiUrl;
		const project = store.getState().project.currentProject.domain;

		const defaultHeaders = {
			'Content-Type': 'application/json',
			Project: project,
			'X-Project': project,
			Authorization: FOOTBALL_API_AUTH,
			'X-Odd-Client': `${store.getState().project.currentProject.defaultOddClient}`,
			'X-SMP-Cache-Disable': true,
		};

		return fetch(baseUrl + '/v2/tournaments/groups', {
			method: 'POST',
			headers: defaultHeaders,
			body: JSON.stringify(body),
		});
	};

	static getStagesBySeasonId = (seasonId, lang) => {
		return HttpService.instanceFootball(lang).get(`/v2/seasons/${seasonId}/stages`);
	};

	static getSeasonsStatisticsByTeamId = (teamId, lang) => {
		return HttpService.instanceFootball(lang).get(`/teams/${teamId}/players/statistics/seasons`);
	};

	static patchImage(url, params) {
		const project = store.getState().project.currentProject.domain;
		const headers = { Project: project };
		return HttpService.instance(headers).patch(url, params);
	}

	static getOrderedTags() {
		const project = store.getState().project.currentProject.domain;
		const headers = { Project: project };

		return HttpService.instance(headers).get(`/tags?order_type=ordered`);
	}

	static testImageUrl = (url) => {
		return axios
			.create({
				baseURL: url,
			})
			.get();
	};

	static getMatchesByStageId = (stageId, lang) => {
		return HttpService.instanceFootball(lang).get(`/tournaments/seasons/stages/${stageId}/events`);
	};

	static saveSidebarSettings = (sidebarSettings, contentType) => {
		const project = store.getState().project.currentProject.domain;
		const userId = store.getState().profile.profile.id;
		const headers = { 'X-Project': project, Project: project, Authorization: CUSTOMIZATION_API_AUTH };
		const generatedUrl = `/sidebar/configurations/admin`;

		if (sidebarSettings && sidebarSettings.value) {
			const data = {
				key: {
					resource: contentType,
					project,
					admin_id: userId,
				},
				sections: sidebarSettings.value,
			};

			return HttpService.instanceExternalApi(headers, REACT_APP_URLS.REACT_APP_CUSTOMIZATION_API_BASE_URL).put(generatedUrl, data);
		}

		return null;
	};

	static getSidebarSettingsByContentType = (contentType) => {
		const project = store.getState().project.currentProject.domain;
		const userId = store.getState().profile.profile.id;
		const headers = { 'X-Project': project, Project: project, Authorization: CUSTOMIZATION_API_AUTH };
		const generatedUrl = `/sidebar/configurations/admin?project=${project}&resource=${contentType}&admin_id=${userId}`;

		return HttpService.instanceExternalApi(headers, REACT_APP_URLS.REACT_APP_CUSTOMIZATION_API_BASE_URL).get(generatedUrl);
	};

	static getMatchesBySeasonId = (seasonId, lang) => {
		return HttpService.instanceFootball(lang).get(`/tournaments/seasons/${seasonId}/events`);
	};

	static getDecoratedPlayersBySeasonId = (seasonId, lang) => {
		return HttpService.instanceFootball(lang).get(`/tournaments/seasons/${seasonId}/cardlist`);
	};

	static getGBVisionAudios = (baseUrl, headers) => {
		return HttpService.instanceExternalApi(headers, baseUrl).get();
	};

	static createLiveBlog = (body) => {
		const project = store.getState().project.currentProject.domain;

		const defaultHeaders = {
			'Content-Type': 'application/json',
			'X-Project': project,
			Project: project,
			Authorization: localStorage.getItem(TOKEN_KEY),
		};

		return fetch(BASE_URL + 'liveblogs', {
			method: 'POST',
			headers: defaultHeaders,
			body: JSON.stringify(body),
			timeout: 30000,
		});
	};

	static updateLiveBlogById = (id, data, method = 'put') => {
		const project = store.getState().project.currentProject.domain;

		const defaultHeaders = {
			'Content-Type': 'application/json',
			'X-Project': project,
			Project: project,
			Authorization: localStorage.getItem(TOKEN_KEY),
		};

		return HttpService.instance(defaultHeaders)[method](`liveblogs/${id}`, data);
	};

	static getAllLiveBlogs = (page, searchQueryFromFilters) => {
		const project = store.getState().project.currentProject.domain;
		const headers = { Project: project };
		const constructURL =
			searchQueryFromFilters && searchQueryFromFilters.length > 0 ? `/search?query=${searchQueryFromFilters}&page=${page}` : `?page=${page}`;

		return HttpService.instance(headers).get(`liveblogs${constructURL}`);
	};

	static searchLiveBlogs = (input) => {
		const project = store.getState().project.currentProject.domain;
		const headers = { Project: project };

		return HttpService.instance(headers).get(`liveblogs/search?query=${input}`);
	};

	static getLiveBlogById = (id) => {
		const project = store.getState().project.currentProject.domain;
		const headers = { Project: project };

		return HttpService.instance(headers).get(`liveblogs/${id}`);
	};

	static deleteLiveBlogById = (id) => {
		const project = store.getState().project.currentProject.domain;
		const headers = { Project: project };

		return HttpService.instance(headers).delete(`liveblogs/${id}`);
	};

	static createNewLiveBlogPost = (liveBlogId, data) => {
		const project = store.getState().project.currentProject.domain;
		const headers = { Project: project };

		return HttpService.instance(headers).post(`liveblogs/${liveBlogId}/posts`, data);
	};

	static editLiveBlogPost = (liveBlogId, postId, data) => {
		const project = store.getState().project.currentProject.domain;
		const headers = { Project: project };

		return HttpService.instance(headers).put(`liveblogs/${liveBlogId}/posts/${postId}`, data);
	};

	static deleteLiveBlogPost = (liveBlogId, postId) => {
		const project = store.getState().project.currentProject.domain;
		const headers = { Project: project };

		return HttpService.instance(headers).delete(`liveblogs/${liveBlogId}/posts/${postId}`);
	};

	static getLiveBlogPosts = (liveBlogId, params) => {
		const project = store.getState().project.currentProject.domain;
		const headers = { Project: project };
		const urlSearchParams = new URLSearchParams({ ...params, optional_data: 'body' }).toString();

		return HttpService.instance(headers).get(`liveblogs/${liveBlogId}/posts${urlSearchParams ? '?' + urlSearchParams : ''}`);
	};

	static getLiveBlogPostTags = (lang, query, tournamentIds) => {
		const paramsObj = {
			query,
			entity_type: [ContentTypes.PLAYER, ContentTypes.TEAM, ContentTypes.COACH],
		};

		if (tournamentIds && tournamentIds.length > 0) {
			paramsObj.scope = `{"tournament":[${tournamentIds.join(',')}]}`;
		}

		const searchParams = new URLSearchParams(paramsObj);

		return HttpService.instanceFootball(lang).get(`/search?${searchParams.toString()}`);
	};

	static getMatchesFromCurrentKickOffDateByTeamId(teamId, marketValueType, oddClient, limit) {
		// We add offset by 2h to the time so if we have a match which is starting or has just started to be able to apply the odds for it
		const timeOffset = 2;
		const startTime = moment().subtract(timeOffset, 'hours').utc().format();

		return HttpService.instanceFootball().get(
			`v2/matches?team_ids=${teamId}&from_kickoff_time=${startTime}&odd_format=${marketValueType}&odd_client=${oddClient}&odd_type=PRE_EVENT&offset=0&limit=${limit}&sort_direction=asc`,
		);
	}

	static getAuthTokens = () => {
		return HttpService.instance().get(`/auth/access-tokens`);
	};

	static getSources = (contentType) => {
		const project = store.getState().project.currentProject.domain;
		const headers = { Project: project };

		return HttpService.instance(headers).get(`${contentType}/sources`);
	};
}
