import axios from 'axios';
import { featuresService } from '../../App';
import { store } from '../../store/store';
import REACT_APP_URLS from '../../global-helpers/global-url.helpers';
import { formatGeneralDateAsString, getTimezoneOffset } from '../../global-helpers/global-dates.helper';
import { PreviewDataForSave } from '../../views/Pages/Multisport-widget/components/modals/constants';
import { SportTypes } from '../../constants/sport.types';

const baseURL = REACT_APP_URLS.REACT_APP_MULTISPORT_API_URL;
const MULTISPORT_API_AUTH = REACT_APP_URLS.REACT_APP_GET_MULTISPORT_AUTH();
const MULTISPORT_API_ADMIN_AUTH = REACT_APP_URLS.REACT_APP_GET_ADMIN_MULTISPORT_AUTH();

export default class MultisportHttpService {
	static generalUrl = '/multi-sport/events';
	static getProjectLanguage = (): string => {
		const reduxState = store.getState();
		return reduxState.project && reduxState.project.currentProject && reduxState.project.currentProject.language
			? reduxState.project.currentProject.language
			: 'en';
	};

	static getOffset = (date: Date | string) => getTimezoneOffset(store.getState().project.currentProject.timezone, date);

	static getOffsetQuery = (date: Date | string) => `&utc_offset=${MultisportHttpService.getOffset(date)}`;
	static getTranslationLanguageQuery = (isFirstQuery: boolean = false) =>
		`${!isFirstQuery ? '&' : ''}translation_language=${MultisportHttpService.getProjectLanguage()}`;

	static getGeneralInstanceHeaders = () => {
		return {
			'X-Project': `${store.getState().project.currentProject.domain}`,
			'Accept-Language': `${
				store.getState().project.currentProject.language ? store.getState().project.currentProject.language.split('-')[0] : 'en'
			}`,
		};
	};

	static instance = () => {
		return axios.create({
			baseURL,
			timeout: 30000,
			headers: {
				...MultisportHttpService.getGeneralInstanceHeaders(),
				Authorization: MULTISPORT_API_AUTH,
			},
		});
	};

	static adminInstance = () => {
		return axios.create({
			baseURL,
			timeout: 30000,
			headers: {
				...MultisportHttpService.getGeneralInstanceHeaders(),
				Authorization: MULTISPORT_API_ADMIN_AUTH,
			},
		});
	};

	static getSportEventsByDate = (filterDate: Date, configurationDate: Date, configurationCompetitionList: string) => {
		const filterDateAsString = formatGeneralDateAsString(filterDate);
		const configurationDateAsString = formatGeneralDateAsString(configurationDate);

		return MultisportHttpService.instance().get(
			`${
				MultisportHttpService.generalUrl
			}/list?filter_date=${filterDateAsString}&selection_date=${configurationDateAsString}&competition_list=${configurationCompetitionList}${MultisportHttpService.getTranslationLanguageQuery()}${MultisportHttpService.getOffsetQuery(
				configurationDate,
			)}`,
		);
	};

	static getPreviewEventsByDate = (configurationDate: Date, configurationCompetitionList: string) => {
		const configurationDateAsString = formatGeneralDateAsString(configurationDate);

		return MultisportHttpService.instance().get(
			`${
				MultisportHttpService.generalUrl
			}/${configurationCompetitionList}/${configurationDateAsString}?${MultisportHttpService.getTranslationLanguageQuery(
				true,
			)}${MultisportHttpService.getOffsetQuery(configurationDate)}`,
		);
	};

	static savePreviewEvents = (configurationDate: Date, dataForSave: PreviewDataForSave[], configurationCompetitionList: string) => {
		const configurationDateAsString = formatGeneralDateAsString(configurationDate);

		return MultisportHttpService.adminInstance().put(
			`${MultisportHttpService.generalUrl}/${configurationCompetitionList}/${configurationDateAsString}`,
			dataForSave,
		);
	};

	static getCompetitionListing = (sport: string, lang: string) => {
		const language = `${lang ? `translation_language=${lang}` : ''}`;
		let resultUrl = '/multi-sport/competitions/lists';
		try {
			const featureData = featuresService.checkFeatureDataField(sport) as Record<string, any>;
			const featureCompetitionList = featureData.competitionList || null;
			const competitionListQuery = featureCompetitionList ? `/${featureCompetitionList}?${language}` : `?${language}`;
			resultUrl += competitionListQuery;
		} catch (error) {}
		return MultisportHttpService.instance().get(resultUrl);
	};

	static getCompetitionListingAndEventsByDateRange = (dateRange: string[], sport: string, lang: string) => {
		const featureSport = sport === SportTypes.FOOTBALL ? `${SportTypes.FOOTBALL}_v2` : sport;
		const featureData = featuresService.checkFeatureDataField(featureSport) as Record<string, any>;
		const featureCompetitionList = featureData.competitionList || null;
		const competitionList = featureCompetitionList ? `competition_list=${featureCompetitionList}` : '';
		const language = lang ? `translation_language=${lang}` : '';
		const fromDate = dateRange[0] ? `from_start_time=${encodeURIComponent(dateRange[0])}` : '';
		const toDate = dateRange[1] ? `to_start_time=${encodeURIComponent(dateRange[1])}` : '';
		const sportQuery = sport ? `sport=${sport}` : '';
		const limitQuery = `limit=1000`;

		let resultUrl = `/multi-sport/events/search`;

		try {
			const queryParams = [sportQuery, competitionList, language, fromDate, toDate, limitQuery].filter(Boolean).join('&');
			resultUrl += queryParams ? `?${queryParams}` : '';
		} catch (error) {}
		return MultisportHttpService.instance().get(resultUrl);
	};
}
