import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import Flatpickr from 'react-flatpickr';
import { DATA_QA } from '../../../helpers/data-qa.properties';
import { useTranslation } from 'react-i18next';
import { dateFormat, langs } from '../../../../../Partials/Sidebar/general-content/constants/date-time.attributes';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../store/store';
import '../../../styles/article-ai-generation-panel.scss';
import '../../../styles/date-picker.styles.scss';

type Props = {
	generationDateFrom: string;
	generationDateTo: string;
	onGenerationDateToChange: (date: string) => void;
};

export const GenerationDateToPicker: FunctionComponent<Props> = ({ generationDateFrom, generationDateTo, onGenerationDateToChange }) => {
	const { t } = useTranslation();
	const language = useSelector((state: AppState) => state.profile.profile.language);
	const [storedTime, setStoredTime] = useState<{ hour: number; minute: number } | null>(null);
	const [toggleInfoPanel, setToggleInfoPanel] = useState<boolean>(false);

	const flatpickrRef = useRef<any>(null);

	const onInfoToggle = () => setToggleInfoPanel(!toggleInfoPanel);

	// Format date manually to ignore timezones
	const formatDateWithoutTimezone = (date: Date) => {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		const hour = String(date.getHours()).padStart(2, '0');
		const minute = String(date.getMinutes()).padStart(2, '0');
		const second = String(date.getSeconds()).padStart(2, '0');
		const ms = String(date.getMilliseconds()).padStart(3, '0');
		return `${year}-${month}-${day}T${hour}:${minute}:${second}.${ms}`;
	};

	const areDatesDifferent = (newDate: string) => {
		if (!generationDateTo) return true;
		return newDate !== generationDateTo;
	};

	const getMinDateForToPicker = () => {
		if (generationDateFrom) {
			const [datePart, timePart] = generationDateFrom.split('T');
			const [year, month, day] = datePart.split('-').map(Number);
			const [hour, minute, secondPart] = timePart.split(':');
			const [second, ms = '0'] = secondPart.split('.');
			return new Date(year, month - 1, day, Number(hour), Number(minute), Number(second), Number(ms));
		}
		return 'today';
	};

	const getDefaultHour = () => {
		if (storedTime) return storedTime.hour;
		if (generationDateTo) return Number(generationDateTo.split('T')[1].split(':')[0]);
		return 23;
	};

	const getDefaultMinute = () => {
		if (storedTime) return storedTime.minute;
		if (generationDateTo) return Number(generationDateTo.split('T')[1].split(':')[1]);
		return 59;
	};

	useEffect(() => {
		if (generationDateFrom && flatpickrRef.current) {
			const [fromDatePart] = generationDateFrom.split('T');
			const [toDatePart, toTimePart] = (generationDateTo && generationDateTo.split('T')) || [];
			const [year, month, day] = fromDatePart.split('-').map(Number);

			if (!generationDateTo || generationDateFrom > generationDateTo) {
				const defaultToDate = new Date(year, month - 1, day, 23, 59);
				flatpickrRef.current.flatpickr.setDate(defaultToDate, false);
				onGenerationDateToChange(formatDateWithoutTimezone(defaultToDate));
			} else if (toDatePart && toTimePart) {
				const [hour, minute, secondPart] = toTimePart.split(':');
				const [second, ms = '0'] = secondPart.split('.');
				const loadedDate = new Date(
					Number(toDatePart.split('-')[0]),
					Number(toDatePart.split('-')[1]) - 1,
					Number(toDatePart.split('-')[2]),
					Number(hour),
					Number(minute),
					Number(second),
					Number(ms),
				);
				flatpickrRef.current.flatpickr.setDate(loadedDate, false);
			}
		}
	}, [generationDateFrom, generationDateTo]);

	return (
		<div className='input-fields-group' data-qa={DATA_QA.ARTICLE_AI_GENERATION_DATE_AND_TIME_TO_SELECT}>
			<div className='datepicker-label-container'>
				<span className='input-fields-label'>{t('generation_date_to')}</span>
				<div className='info-icon' onClick={onInfoToggle} />
				{toggleInfoPanel && (
					<div className='info-message'>
						<div className='info-message-title'>
							{t('generation_date_to_title_info')}
							<div className='info-close-icon' onClick={onInfoToggle} />
						</div>
						<div className='info-message-description'>{t('generation_date_title_to_info_description')}</div>
					</div>
				)}
			</div>
			<div className='datepicker-container'>
				<div className='datepicker-wrapper'>
					<Flatpickr
						id='generation-date-to'
						className='article-generation-date-picker'
						disabled={!generationDateFrom}
						options={{
							defaultDate: generationDateTo || '',
							dateFormat: dateFormat,
							mode: 'single',
							time_24hr: true,
							enableTime: true,
							locale: langs[language],
							minDate: getMinDateForToPicker(),
							defaultHour: getDefaultHour(),
							defaultMinute: getDefaultMinute(),
							onClose: (selectedDates) => {
								if (selectedDates.length > 0 && generationDateFrom) {
									const selected = selectedDates[0];
									const hour = selected.getHours();
									const minute = selected.getMinutes();

									setStoredTime({ hour, minute });

									const formatted = formatDateWithoutTimezone(selected);

									if (areDatesDifferent(formatted) && formatted > generationDateFrom) {
										onGenerationDateToChange(formatted);
									}
								}
							},
						}}
						placeholder={t('select_date')}
						ref={flatpickrRef}
					/>
					<span className='calendar-icon' />
				</div>
			</div>
		</div>
	);
};

export default GenerationDateToPicker;
