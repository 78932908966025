import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { CountryCompetitions, IEventsByDate } from '../../../../../Partials/Blocky/v2/types/livescore';
import '../../../styles/competitions-filter.scss';
import { articleAiGenerationCompetitionFilterSelectStyles, GenerationErrorState } from '../../../helpers/article-ai-generation.helper';
import { DATA_QA } from '../../../helpers/data-qa.properties';
import { CompetitionFilterModal } from '../../../../../Partials/Blocky/v2/widgets-v2/ice-hockey-livescore/subcomponents/competition-filter-modal.component';
import ArticleGenerationInfoPanel from '../configurationFields/generate-article-info-panel';
import { CompetitionSelectInputEvents } from './competition-select-input-events';

interface Props {
	competitions: CountryCompetitions[];
	events: IEventsByDate[];
	withAllSelectedCompetitions: boolean;
	allCompetitionIds: string[];
	competitionIds: string[] | null;
	setCompetitionIds: React.Dispatch<React.SetStateAction<string[]>>;
	selectedDateRange: string[];
	onCompetitionEventsChange: (events: IEventsByDate[]) => void;
	competitionEvents: IEventsByDate[];
	errorState: GenerationErrorState;
	errorMessage: string | undefined;
	setErrorState: (errorState: GenerationErrorState) => void;
}

export const CompetitionSelectInput: FC<Props> = ({
	competitions,
	events,
	withAllSelectedCompetitions,
	allCompetitionIds,
	competitionIds,
	setCompetitionIds,
	selectedDateRange,
	onCompetitionEventsChange,
	competitionEvents,
	errorState,
	errorMessage,
	setErrorState,
}) => {
	const [t] = useTranslation();
	const [isModalOpen, setModalOpen] = useState(false);
	const isDisabled = !selectedDateRange || selectedDateRange.length === 0;
	const [selectedEventsGroups, setSelectedEventsGroups] = useState<IEventsByDate[]>(competitionEvents);

	const toggleModal = () => setModalOpen(!isModalOpen);
	const handleOpenModal = () => setModalOpen(true);

	useEffect(() => {
		setSelectedEventsGroups(competitionEvents);
	}, [competitionEvents]);

	useEffect(() => {
		if (selectedEventsGroups.length > 0) {
			localStorage.setItem('competitionEvents', JSON.stringify(selectedEventsGroups));
		}
	}, [selectedEventsGroups]);

	useEffect(() => {
		const storedEvents = localStorage.getItem('competitionEvents');
		const parsedStoredEvents = storedEvents ? JSON.parse(storedEvents) : null;

		if (!competitionIds || competitionIds.length === 0) {
			setSelectedEventsGroups([]);
			onCompetitionEventsChange([]);
			localStorage.setItem('competitionEvents', JSON.stringify([]));
			localStorage.setItem('storedCompetitionIds', JSON.stringify([]));
			return;
		}

		const storedCompetitionIds = localStorage.getItem('storedCompetitionIds');
		const parsedStoredCompetitionIds = storedCompetitionIds ? JSON.parse(storedCompetitionIds) : [];

		if (parsedStoredEvents && JSON.stringify(parsedStoredCompetitionIds) === JSON.stringify(competitionIds)) {
			setSelectedEventsGroups(parsedStoredEvents);
			onCompetitionEventsChange(parsedStoredEvents);
			return;
		}

		const filteredEvents = events
			.map((eventGroup) => ({
				date: eventGroup.date,
				competitions: eventGroup.competitions
					.filter((competition) => competitionIds.includes(competition.competition_id))
					.map(({ competition_name, competition_id, competition_logo, events }) => ({
						competition_name,
						competition_id,
						competition_logo,
						events,
					})),
			}))
			.filter((eventGroup) => eventGroup.competitions.length > 0);

		setSelectedEventsGroups(filteredEvents);
		onCompetitionEventsChange(filteredEvents);

		localStorage.setItem('competitionEvents', JSON.stringify(filteredEvents));
		localStorage.setItem('storedCompetitionIds', JSON.stringify(competitionIds));

		setErrorState({
			...errorState,
			competition_events: false,
		});
	}, [competitionIds, events]);

	return (
		<>
			<div className='competition-filter-container'>
				<span data-qa={DATA_QA.ARTICLE_AI_GENERATION_COMPETITION_FILTER_TOGGLE} className='competition-filter-title'>
					{t('competition_input.label')}
				</span>
				{
					<Select
						isMulti={true}
						placeholder={t('competition_input.placeholder')}
						isSearchable={false}
						menuIsOpen={false}
						onMenuOpen={handleOpenModal}
						className={`${competitionIds && competitionIds.length === 0 ? 'invalid-field' : ''}`}
						id={DATA_QA.ARTICLE_AI_GENERATION_COMPETITION_FILTER_TOGGLE}
						// @ts-ignore because of styles
						styles={articleAiGenerationCompetitionFilterSelectStyles(false, isDisabled)}
						isDisabled={isDisabled}
					/>
				}
				{isDisabled && (
					<div className='competition-filer-info-container' data-qa={DATA_QA.ARTICLE_AI_GENERATION_INFO_PANEL}>
						<div className='competition-filter-info-icon' />
						<span className='competition-filter-info-label'>{t('competition_input.select_date_disclaimer_label')}</span>
					</div>
				)}
			</div>
			{!isDisabled && selectedEventsGroups && selectedEventsGroups.length > 0 && (
				<ArticleGenerationInfoPanel infoText={t('event_tag_disclaimer')} />
			)}
			{errorMessage && (
				<div className='error-container'>
					<span className='error-icon' />
					<div className='error-message'> {errorMessage} </div>
				</div>
			)}
			<div
				data-qa={DATA_QA.ARTICLE_AI_GENERATION_COMPETITION_FILTER_EVENTS}
				id='competition-filter-events-container'
				className='competition-filter-events-container'
			>
				{selectedEventsGroups.map((dateGroup) =>
					dateGroup.competitions.map((competition) => (
						<CompetitionSelectInputEvents
							key={`${dateGroup.date}-${competition.competition_id}`}
							date={dateGroup.date}
							competitionName={competition.competition_name}
							competitions={competition.events}
							competitionLogo={competition.competition_logo}
							competitionIds={competitionIds}
							setCompetitionIds={setCompetitionIds}
							onCompetitionEventsChange={onCompetitionEventsChange}
						/>
					)),
				)}
			</div>
			{!isDisabled && (
				<CompetitionFilterModal
					competitions={competitions}
					isModalOpen={isModalOpen}
					toggleModal={toggleModal}
					withAllCompetitions={withAllSelectedCompetitions}
					competitionIds={competitionIds ? competitionIds : allCompetitionIds}
					setCompetitionIds={setCompetitionIds}
				/>
			)}
		</>
	);
};
