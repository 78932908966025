import React, { useState } from 'react';
import _ from 'lodash';
import debounce from 'lodash.debounce';
import * as helpers from './helpers/sports-entity-select.helper';
import { customOption } from '../shared/custom-select-option';
import AsyncSelect from 'react-select/async';
import { Label } from 'reactstrap';
import SportsConnectionsHttpService from '../../../Shared/advanced-content-filter/subcomponents/v2/services/sports-connections-http.service';
import { SportTypes } from '../../../../../constants/sport.types';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../store/store';
import ErrorHandler from '../../v2/partials/error-handler/error-handler';
import { SPORT_SELECT_ATTRIBUTES } from '../../v2/widgets-v2/helpers/component-attributes.constants';
import { responseToOption } from './helpers/sports-entity-select.helper';
import { SelectMenuOptionType } from '../../../../../models/v2/general/select.model';

type RequiredProperties = {
	t: any;
	onSelect: any;
	value: any;
	isMulti: boolean;
	isRequired: boolean;
	labelText?: string;
	withLabel?: boolean;
	isValid: boolean;
	inputId: string;
	sport: string;
	entityType: any;
	isClearable?: boolean;
};

type Properties = {
	onSelect: any;
	labelText: string;
	withLabel: boolean;
	value: any;
	t: any;
	isMulti: boolean;
	isRequired: boolean;
	isValid: boolean;
	inputId: string;
	searchThroughAllTeams: boolean;
	sport: string;
	entityType: any;
	isClearable?: boolean;
};

const SportsEntitySelect: React.FunctionComponent<Properties> = (props) => {
	const [teamNameInput, updateTeamName] = useState<string>('');

	const { onSelect, labelText, withLabel, isMulti, value, t, inputId, isValid, isClearable } = props;
	const currentProject = useSelector((state: AppState) => state.project.currentProject);

	const searchMultipleTeams = (search: any, callback: any) => {
		const langCode = currentProject.language.split('-')[0];

		SportsConnectionsHttpService.getSportsData(search, props.sport, [props.entityType], langCode)
			.then((response: any) => {
				const options = response.data.results.map((item: any) => helpers.responseToOption(item, item.entity_type));
				callback(options);
			})
			.catch((e: any) => e);
	};

	const onTeamNameInputChangeState = (input: string) => {
		updateTeamName(input);
		return input;
	};

	const displayLabel = (display: boolean, text: string) => {
		if (display !== undefined && !display) {
			return null;
		}

		if (display && text) {
			return <Label htmlFor={`${props.sport}-${SPORT_SELECT_ATTRIBUTES.SPORTS_ENTITY}`}>{t(text)}</Label>;
		}

		return props.sport === SportTypes.TENNIS ? (
			<Label htmlFor={`${props.sport}-${SPORT_SELECT_ATTRIBUTES.SPORTS_ENTITY}`}>{t('player')}:</Label>
		) : (
			<Label>{t('team')}:</Label>
		);
	};

	const onSelectMultipleEntities = (entities: SelectMenuOptionType[]) => {
		entities && entities.length > 0 ? onSelect(entities.map((entity: any) => entity.data)) : onSelect([]);
	};

	const onSelectSingleEntity = (entities: SelectMenuOptionType) => {
		entities && entities.data && entities.data.id ? onSelect(entities.data) : onSelect(null);
	};

	const displayValue = () => {
		if (value && Object.keys(value).length === 0 && value.constructor === Object) {
			return null;
		}

		return isMulti ? helpers.entityToOption(value) : value && responseToOption(value, value.entity_type);
	};

	return (
		<div data-qa={inputId}>
			{displayLabel(withLabel, labelText)}
			{props.isRequired && <span className='text-danger'>*</span>}
			<AsyncSelect
				className='mb-2'
				id={inputId}
				menuPortalTarget={document.body}
				menuPosition='absolute'
				noOptionsMessage={(inputValue) => inputValue && t('no_options')}
				isMulti={isMulti}
				loadOptions={_.debounce(searchMultipleTeams, 500)}
				onInputChange={debounce(onTeamNameInputChangeState, 500)}
				value={displayValue()}
				formatOptionLabel={customOption}
				onChange={props.isMulti ? onSelectMultipleEntities : onSelectSingleEntity}
				placeholder={t('search')}
			/>
			{
				<ErrorHandler
					isValueSelected={!isMulti ? !value || (value && !value.id) : value && value.length === 0}
					arePropsValid={isValid}
					t={t}
					errorMessage='field_is_required'
				/>
			}
		</div>
	);
};

export default SportsEntitySelect as React.ComponentType<RequiredProperties>;
