import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../../styles/article-ai-generation-panel.scss';
import '../../../../styles/date-picker.styles.scss';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../../store/store';
import { DATA_QA } from '../../../../helpers/data-qa.properties';
import { ISchedulesAdvancedSearchFiltersModel } from '../../../../models/models';
import Select from 'react-select';
import { SelectMenuOptionType } from '../../../../../../../models/v2/general/select.model';
import { articleAiGenerationSelectStylesVariant } from '../../../../helpers/article-ai-generation.helper';
import HttpService from '../../../../../../../services/rest/HttpService';
import CreatedByModel from '../../../../../../../models/v2/created-by/created-by.model';
import { usersToOptions } from '../../../../helpers/listing.helper';

type Properties = {
	schedulesFilters: ISchedulesAdvancedSearchFiltersModel;
	updateSchedulesAdvancedSearchText: (filters: ISchedulesAdvancedSearchFiltersModel) => void;
};

const CreatedBySelect: FunctionComponent<Properties> = ({ schedulesFilters, updateSchedulesAdvancedSearchText }) => {
	const [t] = useTranslation();
	const project = useSelector((state: AppState) => state.project.currentProject);
	const [users, setUsers] = useState<CreatedByModel[]>([]);

	useEffect(() => fetchAllUsers(), []);

	const fetchAllUsers = () => {
		const headers = { Project: project.domain };

		HttpService.getAllUsers(`projects/${project.id}/admins?page=1&limit=200`, null, headers).then((response: any) => {
			setUsers(response.data.data);
		});
	};

	const onUserSelect = (selectedAdmin: SelectMenuOptionType) => {
		let advancedContentModel = schedulesFilters;
		advancedContentModel.created_by = selectedAdmin && selectedAdmin.data ? selectedAdmin.data.id : '';
		updateSchedulesAdvancedSearchText(advancedContentModel);
	};

	const adminsToOptions = usersToOptions(users);
	const selectedUser = adminsToOptions.find((option) => option.value === schedulesFilters.created_by) || null;

	return (
		<div className='input-fields-group' data-qa={DATA_QA.ARTICLE_AI_GENERATION_GENERATE_ARTICLE_CREATED_BY_SELECT}>
			<span className='input-fields-label'>{t('created_by')}</span>
			{
				<Select
					id={DATA_QA.ARTICLE_AI_GENERATION_GENERATE_ARTICLE_CREATED_BY_SELECT}
					value={selectedUser}
					options={adminsToOptions}
					onChange={(selection) => onUserSelect(selection as SelectMenuOptionType)}
					placeholder={t('select')}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					// @ts-ignore because of styles
					styles={articleAiGenerationSelectStylesVariant(false, '374px')}
				/>
			}
		</div>
	);
};

export default CreatedBySelect;
