import React from 'react';
import { ContentToDelete, Properties, State } from './properties/ComponentProps';
import { withTranslation } from 'react-i18next';
import ContentTitleContainer from './subcomponents/ContentTitle/ContentTitleContainer';
import ModalContainer from './subcomponents/Modal/ModalContainer';
import AuthorizedListing from './subcomponents/AuthorizedListing/AuthorizedListing';
import * as uiActions from '../../../../store/action-creators/UiActionCreator';
import { AppState } from '../../../../store/store';
import { connect } from '../../../../store/store.connect';
import { isAiArticleSection } from '../../../Resources/Articles/Helpers/ArticleHelper';

@connect<Properties, AppState>(
	(state) => ({
		isToggledModal: state.ui.isToggleModal,
		toggleLoadingState: state.ui.toggleLoadingState,
		currentProject: state.project.currentProject,
	}),
	(dispatch) => ({
		dispatch: (action: any) => dispatch(action),
	}),
)
class ListingComponent extends React.Component<Properties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			contentToDelete: {} as ContentToDelete,
		};
	}

	onContentDelete = (id: string, title: string) => {
		this.toggleModal({ id, title });
	};

	toggleModal = (content?: ContentToDelete) => {
		this.props.dispatch(uiActions.toggleModal(true));
		this.setState({
			contentToDelete: content ? content : this.state.contentToDelete,
		});
	};

	render() {
		const { t, analytics, contentType, isToggledModal, onDeleteContent, content, toggleLoadingState, location } = this.props;
		return (
			<div className='animated fadeIn'>
				<div className='card'>
					{!isAiArticleSection() && <ContentTitleContainer location={location} contentType={contentType} t={t} />}
					<div className='card-body'>
						<AuthorizedListing
							t={t}
							onContentDelete={this.onContentDelete}
							content={content}
							contentType={contentType}
							analytics={analytics}
							toggleLoadingState={toggleLoadingState}
							history={this.props.history}
							getContent={this.props.getContent}
							onPageChange={this.props.onPageChange}
							currentProject={this.props.currentProject}
							contentPagination={this.props.contentPagination}
							searchContent={this.props.searchContent}
							showSearchBar={this.props.showSearchBar}
							hidePagination={this.props.hidePagination}
							location={location}
						/>
					</div>
				</div>
				<ModalContainer
					t={t}
					analytics={analytics}
					contentToDelete={this.state.contentToDelete}
					onDeleteContent={onDeleteContent}
					isToggledModal={isToggledModal}
				/>
			</div>
		);
	}
}

export default withTranslation()(ListingComponent) as React.ComponentType<any>;
