import React, { FC } from 'react';
import '../../../styles/competitions-filter.scss';
import { useTranslation } from 'react-i18next';
import { IEventsByDate } from '../../../../../Partials/Blocky/v2/types/livescore';
import { DATA_QA } from '../../../helpers/data-qa.properties';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../store/store';

interface Props {
	date: string;
	competitionName: string;
	competitionLogo: string;
	competitions: { id: string; name: string; start_time: string }[];
	competitionIds: string[] | null;
	setCompetitionIds: React.Dispatch<React.SetStateAction<string[]>>;
	onCompetitionEventsChange: (events: IEventsByDate[]) => void;
}

export const CompetitionSelectInputEvents: FC<Props> = ({
	date,
	competitionName,
	competitionLogo,
	competitions,
	competitionIds,
	setCompetitionIds,
	onCompetitionEventsChange,
}) => {
	const [t] = useTranslation();
	const project = useSelector((state: AppState) => state.project.currentProject);

	const handleRemoveEvent = (eventId: string) => {
		const updatedEvents = competitions.filter((event) => event.id !== eventId);

		onCompetitionEventsChange((prevEvents: IEventsByDate[]) => {
			const newEvents = prevEvents
				.map((group) =>
					group.date === date
						? {
								...group,
								competitions: group.competitions
									.map((comp) => (comp.competition_name === competitionName ? { ...comp, events: updatedEvents } : comp))
									.filter((comp) => comp.events.length > 0),
						  }
						: group,
				)
				.filter((group) => group.competitions.length > 0);

			localStorage.setItem('competitionEvents', JSON.stringify(newEvents));

			return newEvents;
		});
	};

	const handleRemoveGroup = () => {
		const updatedCompetitionIds = competitionIds ? competitionIds.filter((id) => !competitions.some((event) => event.id === id)) : [];
		setCompetitionIds(updatedCompetitionIds);

		onCompetitionEventsChange((prevEvents: IEventsByDate[]) =>
			prevEvents
				.map((group) =>
					group.date === date
						? {
								...group,
								competitions: group.competitions ? group.competitions.filter((comp) => comp.competition_name !== competitionName) : [],
						  }
						: group,
				)
				.filter((group) => group.competitions && group.competitions.length > 0),
		);
	};

	return (
		<>
			<div data-qa={DATA_QA.ARTICLE_AI_GENERATION_COMPETITION_FILTER_EVENTS_COMPETITION_GROUP} className='events-container-header'>
				<span className='events-group-date-label'>{`${t('date')}: ${date.split('-').reverse().join('.')}`}</span>
				<img
					className='competition-logo'
					src={competitionLogo}
					onError={(i: React.SyntheticEvent<HTMLImageElement, Event>) => ((i.target as HTMLImageElement).src = competitionLogo)}
					alt={`option-logo-${competitionName}`}
				/>
				<span className='events-group-competition-label'>{competitionName}</span>
			</div>
			<div data-qa={DATA_QA.ARTICLE_AI_GENERATION_COMPETITION_FILTER_EVENTS_EVENT} className='events-group-elements-container'>
				<div className='events-group-elements-wrapper'>
					{competitions.map((event) => (
						<div key={event.id} className='events-group-element-pill'>
							<span className='events-group-element-pill-label'>
								{event.name} (
								{new Date(event.start_time).toLocaleTimeString([project.language], {
									hour: '2-digit',
									minute: '2-digit',
									timeZone: project.timezone,
									hour12: false,
								})}
								)
							</span>
							<span className='events-group-element-pill-remove-icon' onClick={() => handleRemoveEvent(event.id)} />
						</div>
					))}
				</div>
				<div className='remove-group-icon-container'>
					<span className='events-group-element-section-remove-icon' onClick={handleRemoveGroup} />
				</div>
			</div>
		</>
	);
};
