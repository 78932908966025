import axios from 'axios';
import { store } from '../../../../../../../store/store';
import REACT_APP_URLS from '../../../../../../../global-helpers/global-url.helpers';
import { formatGeneralDateAsString } from '../../../../../../../global-helpers/global-dates.helper';

const SPORTS_SEARCH_API_URL = REACT_APP_URLS.REACT_APP_SEARCH_API_BASE_URL;

const calculateLang = () => {
	const lang =
	 store.getState().project.currentProject.language
			? store.getState().project.currentProject.language.split('-')[0]
			: 'en';

	return `${lang}`;
};
export default class SportsConnectionsHttpService {
	static instance = (acceptLang?: any) => {
		return axios.create({
			baseURL: SPORTS_SEARCH_API_URL,
			timeout: 30000,
			headers: {
				'X-Project': store.getState().project.currentProject.domain,
				'Accept-Language': acceptLang ? acceptLang : calculateLang(),
			},
		});
	};

	static getSports = () => {
		return SportsConnectionsHttpService.instance().get('sports');
	};

	static getSportsData = (input: string, sportsType: string, entityTypes: string[], acceptLanguage: string) => {
		return SportsConnectionsHttpService.instance(acceptLanguage).get(
			`suggest?name=${input}&sport=${sportsType}&entity_type=${entityTypes.join(',')}&translation_language=${acceptLanguage}`,
		);
	};

	static getLegacySportsData = (entityIds: string[]) => {
		const lang = calculateLang() || 'en';
		return SportsConnectionsHttpService.instance().get(`legacy/suggest?translation_language=${lang}&ids=${entityIds.join(',')}`);
	};

	static getSportsDataForMultisport = (input: string, sportsType: string, dateInput: Date | string) => {
		const date = typeof dateInput === 'string' ? dateInput : formatGeneralDateAsString(dateInput);
		const reduxState = store.getState();
		const transLanguage =
			reduxState.project && reduxState.project.currentProject && reduxState.project.currentProject.language
				? reduxState.project.currentProject.language
				: 'en';
		return SportsConnectionsHttpService.instance(transLanguage).get(
			`suggest?name=${input}&sport=${sportsType}&event_start_time=${date}&translation_language=${transLanguage}`,
		);
	};

	static getCompetitionData = (input: string, sportsType: string, acceptLanguage: string) => {
		return SportsConnectionsHttpService.instance(acceptLanguage).get(
			`suggest?name=${input}&sport=${sportsType}&entity_type=competition&translation_language=${acceptLanguage}`,
		);
	};

	static getGamesData = (url: string, acceptLanguage: string) => {
		return SportsConnectionsHttpService.instance(acceptLanguage).get(url);
	};

	static requestMultipleSportsRequests = (urls: string[], acceptLanguage: string) => {
		let requests: any[] = [];

		urls.forEach((url: string) => {
			const request = SportsConnectionsHttpService.instance(acceptLanguage).get(url);
			requests.push(request);
		});

		return axios.all(requests);
	};
}
