import React, { FunctionComponent, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { DATA_QA } from '../../../helpers/data-qa.properties';
import { ScheduledContent } from '../../../models/models';

type ModalDeleteProps = {
	selectedRowForDelete: ScheduledContent | undefined;
	setSelectedRowForDelete: Function;
	deleteContent: (id: string) => void;
};

export const DeleteScheduleModal: FunctionComponent<ModalDeleteProps> = ({
	selectedRowForDelete,
	setSelectedRowForDelete,
	deleteContent,
}) => {
	const [modalOpenFlag, setModalOpenFlag] = useState(false);
	const [t] = useTranslation();

	useEffect(() => {
		setModalOpenFlag(!!(selectedRowForDelete && selectedRowForDelete.id && selectedRowForDelete.id.length > 0));
	}, [selectedRowForDelete]);

	const deleteEntity = () => {
		const idForDelete = selectedRowForDelete && selectedRowForDelete.id ? selectedRowForDelete.id : '';
		if (idForDelete) {
			deleteContent(idForDelete);
			closeModal();
		}
	};

	const closeModal = () => setSelectedRowForDelete(null);

	return (
		<Modal data-qa={DATA_QA.ARTICLE_AI_SCHEDULES_SCHEDULE_DELETE_MODAL} isOpen={modalOpenFlag} className='delete-schedule-modal'>
			<ModalHeader
				className='border-0'
				toggle={() => {
					closeModal();
				}}
			>
				<span className='delete-schedule-modal-text-header'>{t('delete_schedule')}</span>
			</ModalHeader>
			<ModalBody className='border-0'>
				<span className='delete-schedule-modal-text'>
					{`${t('schedule_delete_notice_1st_half')}`}
					<span className='delete-schedule-modal-title-text'>"{selectedRowForDelete && selectedRowForDelete.match_name}".</span>
					<span className='delete-schedule-modal-text'>{t('schedule_delete_notice_2nd_half')}</span>
				</span>
			</ModalBody>
			<ModalFooter className='border-0'>
				<div className='delete-schedule-modal-btns'>
					<div className='cancel-delete-btn' onClick={closeModal}>
						<span className='cancel-delete-btn-text'>{t('cancel')}</span>
					</div>
					<div className='confirm-delete-btn' onClick={deleteEntity}>
						<span className='confirm-delete-btn-text'>{t('delete')}</span>
					</div>
				</div>
			</ModalFooter>
		</Modal>
	);
};
