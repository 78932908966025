import React, { FC } from 'react';
import '../../../styles/competitions-filter.scss';
import '../../../../../Partials/Blocky/v2/widgets-v2/ice-hockey-livescore/subcomponents/competition-filter-modal.component.scss';
import { CompetitionSelectInput } from './competition-select-input';
import { useCompetitionsListByDateRange } from '../../../helpers/useCompetitionsListByDateRange';
import { IEventsByDate } from '../../../../../Partials/Blocky/v2/types/livescore';
import { GenerationErrorState } from '../../../helpers/article-ai-generation.helper';

interface Props {
	competitionIds: string[] | null;
	setCompetitionIds: React.Dispatch<React.SetStateAction<string[]>>;
	selectedDateRange: string[];
	sportType: string;
	onCompetitionEventsChange: (events: IEventsByDate[]) => void;
	competitionEvents: IEventsByDate[];
	errorState: GenerationErrorState;
	errorMessage: string | undefined;
	setErrorState: (errorState: GenerationErrorState) => void;
}

const CompetitionsSelect: FC<Props> = ({
	competitionIds,
	setCompetitionIds,
	selectedDateRange,
	competitionEvents,
	sportType,
	onCompetitionEventsChange,
	errorState,
	errorMessage,
	setErrorState,
}) => {
	const { competitions, events } = useCompetitionsListByDateRange(sportType, selectedDateRange);

	const allCompetitionIds = competitions.flatMap(({ competitions }) => competitions.map(({ value }) => value));
	const withAllSelectedCompetitions = !competitionIds || (competitionIds && allCompetitionIds.length === competitionIds.length);

	return (
		<CompetitionSelectInput
			competitions={competitions}
			events={events}
			withAllSelectedCompetitions={withAllSelectedCompetitions}
			allCompetitionIds={allCompetitionIds}
			competitionIds={competitionIds}
			setCompetitionIds={setCompetitionIds}
			selectedDateRange={selectedDateRange}
			onCompetitionEventsChange={onCompetitionEventsChange}
			competitionEvents={competitionEvents}
			errorState={errorState}
			errorMessage={errorMessage}
			setErrorState={setErrorState}
		/>
	);
};

export default CompetitionsSelect;
