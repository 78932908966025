import Project from '../../../../../../models/project/Project';
import { featuresService } from '../../../../../../App';
import { FeatureTypes } from '../../../../../../services/feature-service/features.enum';
import { SportTypes } from '../../../../../../constants/sport.types';
import BlockConfigModel from '../../../models/block-config.model/block-config.model';
import moment from 'moment';
import { WidgetsV2Ids } from '../../../constants/block.types';
import { WIDGET_ATTRIBUTE_NAMES, WIDGET_PREVIEW_ATTRIBUTE_NAMES } from './component-attributes.constants';
import REACT_APP_URLS from '../../../../../../global-helpers/global-url.helpers';
import { MatchTypes } from '../football-team/constants/football-team.constants';
import { constructDataAttribute as constructDataAttributeImported } from './block-v2-config.helper';
import { buildOddsDataAttributes } from './block-v2-config.helper';
import { isValueEmpty } from '../../../../../../global-helpers/global.helpers';

// will remove double spacing between attributes
const cleanAttributes = (htmlString: string) => htmlString.replace(/\s+/g, ' ');

// checks if spacing between attributes is correct
const isSeperatedCorrectly = (htmlString: string) => {
	const tagContent = htmlString.split('<div ')[1];
	const attributes = tagContent.split(' ');

	for (let i = 1; i < attributes.length; i++) {
		const prevAttribute = attributes[i - 1];
		const currentAttribute = attributes[i];

		if (prevAttribute.trim().length === 0 || currentAttribute.trim().length === 0) {
			return false;
		}
	}
	return true;
};

export const getFormattedContentData = (value: string): string => {
	if (isSeperatedCorrectly(value)) {
		return value;
	}
	return cleanAttributes(value);
};

export const constructBasketballEventWidgetContent = (config: BlockConfigModel) => {
	const { oddsDisplay, oddsBettingId, oddsMarket } = buildOddsDataAttributes(config, true);
	const widgetRefreshTime = constructDataAttributeImported(config, config.options.refreshTime, WIDGET_ATTRIBUTE_NAMES.REFRESH_TIME);

	let contentData = `<div data-widget-id='${config.widgetId}' data-match-id='${config.options.gameId}' data-widget-type='event' data-widget-sport='${SportTypes.BASKETBALL}'${widgetRefreshTime}`;

	const configSettings = [oddsBettingId, oddsDisplay, oddsMarket];

	configSettings.forEach((item: any) => {
		contentData = addValueIfNotEmpty(item, contentData);
	});
	contentData += '></div>';

	return getFormattedContentData(contentData);
};

export const constructBasketballTournamentWidgetContent = (config: BlockConfigModel) => {
	const date = config.options.date && JSON.stringify({ date: moment(config.options.date).format('DD-MM-YYYY'), dateFormat: 'DD-MM-YYYY' });
	const { oddsDisplay, oddsBettingId } = buildOddsDataAttributes(config);
	const dataConfigDate = date ? constructDataAttribute(date, WIDGET_ATTRIBUTE_NAMES.DATE) : ``;
	const dataConfigCompetition = constructDataAttribute(config.options.competitionId, WIDGET_ATTRIBUTE_NAMES.COMPETITION);
	const dataConfigSeason = constructDataAttribute(config.options.seasonId, WIDGET_ATTRIBUTE_NAMES.SEASON);
	const dataConfigStage = constructDataAttribute(config.options.stageId, WIDGET_ATTRIBUTE_NAMES.STAGE);
	const widgetRefreshTime = constructDataAttributeImported(config, config.options.refreshTime, WIDGET_ATTRIBUTE_NAMES.REFRESH_TIME);
	const dataRoundsFilter =
		WidgetsV2Ids.widgetBasketballTournamentProgramme || WidgetsV2Ids.widgetBasketballTeamProgramme
			? constructDataAttribute(JSON.stringify(config.options.roundsFilter), WIDGET_ATTRIBUTE_NAMES.ROUNDS_FILTER)
			: '';
	const dataLimit = constructDataAttribute(config.options.limit, WIDGET_ATTRIBUTE_NAMES.LIMIT);
	const dataDateFrom = config.options.dataDateFrom
		? constructDataAttribute(`{"date":"${config.options.dataDateFrom}","dateFormat":"YYYY-MM-DD"}`, WIDGET_ATTRIBUTE_NAMES.DATA_DATE_FROM)
		: '';
	const dataDateTo = config.options.dataDateTo
		? constructDataAttribute(`{"date":"${config.options.dataDateTo}","dateFormat":"YYYY-MM-DD"}`, WIDGET_ATTRIBUTE_NAMES.DATA_DATE_TO)
		: '';
	const dataConfigSortDirection = constructDataAttribute(config.options.sortDirection, WIDGET_ATTRIBUTE_NAMES.SORT_DIRECTION);
	const dataConfigSortDirectionFixtures = constructDataAttribute(
		config.options.sortDirectionFixtures,
		WIDGET_ATTRIBUTE_NAMES.SORT_DIRECTION_FIXTURES,
	);
	const dataConfigSortDirectionResults = constructDataAttribute(
		config.options.sortDirectionResults,
		WIDGET_ATTRIBUTE_NAMES.SORT_DIRECTION_RESULTS,
	);
	const dataHeaderDisplay = constructDataAttributeImported(config, config.options.displayHeader, WIDGET_ATTRIBUTE_NAMES.HEADER_DISPLAY);
	const headerDefaultOption = checkIfCorrectWidgetTypes(config.widgetId, [
		WidgetsV2Ids.widgetBasketballStandings,
		WidgetsV2Ids.widgetBasketballLivescore,
		WidgetsV2Ids.widgetBasketballTeamProgramme,
		WidgetsV2Ids.widgetBasketballTournamentProgramme,
	])
		? constructDataAttribute(config.options.headerDefaultOption, WIDGET_ATTRIBUTE_NAMES.HEADER_DEFAULT_OPTION)
		: '';
	const matchType =
		config.options.matchType && config.options.matchType !== MatchTypes.BOTH
			? constructDataAttribute(config.options.matchType, WIDGET_ATTRIBUTE_NAMES.MATCH_TYPE)
			: '';
	const dataTeam = config.options.teamId ? constructDataAttribute(config.options.teamId, WIDGET_ATTRIBUTE_NAMES.TEAM) : '';

	return getFormattedContentData(
		`<div data-widget-id='${config.widgetId}' data-widget-sport='${SportTypes.BASKETBALL}' data-widget-type='tournament' ${dataConfigDate}${oddsDisplay}${oddsBettingId}${dataConfigCompetition}${dataConfigSeason}${dataConfigStage}${dataHeaderDisplay}${headerDefaultOption}${dataRoundsFilter}${dataConfigSortDirection}${dataConfigSortDirectionFixtures}${dataConfigSortDirectionResults}${matchType}${dataTeam}${dataLimit}${dataDateFrom}${dataDateTo}${widgetRefreshTime}></div>`,
	);
};

export const constructTennisTournamentWidget = (config: BlockConfigModel) => {
	const date = config.options.date
		? constructDataAttribute(
				JSON.stringify({ date: moment(config.options.date).format('DD.MM.YYYY'), dateFormat: 'DD.MM.YYYY' }),
				WIDGET_ATTRIBUTE_NAMES.DATE,
		  )
		: ``;
	const dataHeaderDisplay = constructDataAttributeImported(config, config.options.displayHeader, WIDGET_ATTRIBUTE_NAMES.HEADER_DISPLAY);
	const { oddsDisplay, oddsBettingId } = buildOddsDataAttributes(config);

	const limit = WidgetsV2Ids.widgetTennisRanking ? constructDataAttribute(config.options.limit, WIDGET_ATTRIBUTE_NAMES.LIMIT) : '';
	const widgetRefreshTime = constructDataAttributeImported(config, config.options.refreshTime, WIDGET_ATTRIBUTE_NAMES.REFRESH_TIME);
	const offset = WidgetsV2Ids.widgetTennisRanking ? constructDataAttribute(config.options.offset, WIDGET_ATTRIBUTE_NAMES.OFFSET) : '';

	const standingType = WidgetsV2Ids.widgetTennisRanking
		? constructDataAttribute(config.options.standingType, WIDGET_ATTRIBUTE_NAMES.STANDING_TYPE)
		: '';

	const constructedHighlightPlayers =
		config.options.highlightPlayers && config.options.highlightPlayers.length > 0
			? `[${config.options.highlightPlayers.map((id: string) => `"${id}"`)}]`
			: '[]';

	const headerDefaultOption = checkIfCorrectWidgetTypes(config.widgetId, [
		WidgetsV2Ids.widgetTennisLivescore,
		WidgetsV2Ids.widgetTennisAthleteProgramme,
		WidgetsV2Ids.widgetTennisTournamentProgramme,
	])
		? constructDataAttribute(config.options.headerDefaultOption, WIDGET_ATTRIBUTE_NAMES.HEADER_DEFAULT_OPTION)
		: '';

	const highlightPlayers = WidgetsV2Ids.widgetTennisRanking
		? constructDataAttribute(constructedHighlightPlayers, WIDGET_ATTRIBUTE_NAMES.HIGHLIGHT_PLAYERS)
		: '';

	let contentData = `<div data-widget-id='${config.widgetId}' data-widget-sport='${SportTypes.TENNIS}' data-widget-type='tournament'${widgetRefreshTime}${headerDefaultOption}`;

	const configSettings = [date, oddsBettingId, oddsDisplay, dataHeaderDisplay, limit, offset, standingType, highlightPlayers];
	configSettings.forEach((item: any) => {
		contentData = addValueIfNotEmpty(item, contentData);
	});
	contentData += '></div>';

	return getFormattedContentData(contentData);
};

export const constructTennisSingleEventContent = (config: BlockConfigModel) => {
	const { oddsDisplay, oddsBettingId, oddsMarket } = buildOddsDataAttributes(config, true);
	const widgetRefreshTime = constructDataAttributeImported(config, config.options.refreshTime, WIDGET_ATTRIBUTE_NAMES.REFRESH_TIME);

	let contentData = `<div data-widget-id='${config.widgetId}' data-match-id='${config.options.matchId}' data-widget-type='event' data-widget-sport='${SportTypes.TENNIS}'${widgetRefreshTime}`;
	const configSettings = [oddsDisplay, oddsBettingId, oddsMarket];

	configSettings.forEach((item: any) => {
		contentData = addValueIfNotEmpty(item, contentData);
	});

	contentData += '></div>';

	return getFormattedContentData(contentData);
};

export const constructTennisPlayoffWidgetContent = (config: BlockConfigModel) => {
	const dataConfigCompetition = constructDataAttribute(config.options.competitionId, WIDGET_ATTRIBUTE_NAMES.COMPETITION);
	const dataConfigSeason = constructDataAttribute(config.options.seasonId, WIDGET_ATTRIBUTE_NAMES.SEASON);
	const dataConfigStage = constructDataAttribute(config.options.stageId, WIDGET_ATTRIBUTE_NAMES.STAGE);
	const dataHeaderDisplay = constructDataAttribute(config.options.displayHeader, WIDGET_ATTRIBUTE_NAMES.HEADER_DISPLAY);
	const widgetRefreshTime = constructDataAttributeImported(config, config.options.refreshTime, WIDGET_ATTRIBUTE_NAMES.REFRESH_TIME);

	return getFormattedContentData(
		`<div data-widget-id='${config.widgetId}' data-widget-sport='${SportTypes.TENNIS}' data-widget-type='tournament' ${dataConfigCompetition}${dataConfigSeason}${dataConfigStage}${dataHeaderDisplay}${widgetRefreshTime}></div>`,
	);
};

export const constructTennisProgrammeWidgetContent = (config: BlockConfigModel) => {
	const dataConfigPlayer = checkIfCorrectWidgetType(config.widgetId, WidgetsV2Ids.widgetTennisAthleteProgramme)
		? constructDataAttribute(config.options.playerId, WIDGET_ATTRIBUTE_NAMES.PLAYER)
		: ``;
	const dataConfigCompetition = constructDataAttribute(config.options.competitionId, WIDGET_ATTRIBUTE_NAMES.COMPETITION);
	const dataConfigSeason = constructDataAttribute(config.options.season, WIDGET_ATTRIBUTE_NAMES.SEASON);
	const dataConfigTournament = constructDataAttribute(config.options.tournamentId, WIDGET_ATTRIBUTE_NAMES.TOURNAMENT);
	const dataRoundsFilter =
		config.options.roundsFilter && config.options.roundsFilter.length > 0
			? constructDataAttribute(JSON.stringify(config.options.roundsFilter), WIDGET_ATTRIBUTE_NAMES.ROUNDS_FILTER)
			: ``;
	const dataLimit = constructDataAttribute(config.options.limit, WIDGET_ATTRIBUTE_NAMES.LIMIT);
	const dataDateFrom = config.options.dataDateFrom
		? constructDataAttribute(`{"date":"${config.options.dataDateFrom}","dateFormat":"YYYY-MM-DD"}`, WIDGET_ATTRIBUTE_NAMES.DATA_DATE_FROM)
		: '';
	const dataDateTo = config.options.dataDateTo
		? constructDataAttribute(`{"date":"${config.options.dataDateTo}","dateFormat":"YYYY-MM-DD"}`, WIDGET_ATTRIBUTE_NAMES.DATA_DATE_TO)
		: '';
	const dataConfigSortDirection = constructDataAttribute(config.options.sortDirection, WIDGET_ATTRIBUTE_NAMES.SORT_DIRECTION);
	const dataConfigSortDirectionFixtures = constructDataAttribute(
		config.options.sortDirectionFixtures,
		WIDGET_ATTRIBUTE_NAMES.SORT_DIRECTION_FIXTURES,
	);
	const dataConfigSortDirectionResults = constructDataAttribute(
		config.options.sortDirectionResults,
		WIDGET_ATTRIBUTE_NAMES.SORT_DIRECTION_RESULTS,
	);
	const oddsBettingId =
		config.options.displayOdds && constructDataAttribute(config.options.oddProviderIds, WIDGET_ATTRIBUTE_NAMES.BETTING_ID);
	const oddsDisplay = constructDataAttribute(config.options.displayOdds, WIDGET_ATTRIBUTE_NAMES.ODDS_DISPLAY);
	const dataHeaderDisplay = constructDataAttribute(config.options.displayHeader, WIDGET_ATTRIBUTE_NAMES.HEADER_DISPLAY);
	const widgetRefreshTime = constructDataAttributeImported(config, config.options.refreshTime, WIDGET_ATTRIBUTE_NAMES.REFRESH_TIME);
	const widgetGameType = constructDataAttributeImported(config, config.options.gameType, WIDGET_ATTRIBUTE_NAMES.GAME_TYPE);
	const widgetGender = constructDataAttributeImported(config, config.options.gender, WIDGET_ATTRIBUTE_NAMES.GENDER);

	const headerDefaultOption = constructDataAttribute(config.options.headerDefaultOption, WIDGET_ATTRIBUTE_NAMES.HEADER_DEFAULT_OPTION);

	let contentData = `<div data-widget-id='${config.widgetId}' data-widget-sport='${SportTypes.TENNIS}' data-widget-type='tournament'${widgetRefreshTime}${headerDefaultOption}${widgetGameType}${widgetGender}`;

	const configSettings = [
		dataConfigPlayer,
		dataConfigCompetition,
		dataConfigSeason,
		dataConfigTournament,
		dataRoundsFilter,
		dataLimit,
		dataDateFrom,
		dataDateTo,
		dataConfigSortDirection,
		dataConfigSortDirectionFixtures,
		dataConfigSortDirectionResults,
		oddsBettingId,
		oddsDisplay,
		dataHeaderDisplay,
	];

	configSettings.forEach((item: any) => {
		contentData = addValueIfNotEmpty(item, contentData);
	});
	contentData += '></div>';

	return getFormattedContentData(contentData);
};

//ice hockey
export const constructIceHockeyWidgetContent = (config: BlockConfigModel) => {
	const date = config.options.date && JSON.stringify({ date: moment(config.options.date).format('DD-MM-YYYY'), dateFormat: 'DD-MM-YYYY' });
	const { oddsDisplay, oddsBettingId } = buildOddsDataAttributes(config);
	const dataConfigDate = date ? constructDataAttribute(date, WIDGET_ATTRIBUTE_NAMES.DATE) : ``;
	const dataConfigCompetitions = constructDataAttribute(config.options.competitionIds, WIDGET_ATTRIBUTE_NAMES.COMPETITIONS);
	const dataConfigSeason = constructDataAttribute(config.options.seasonId, WIDGET_ATTRIBUTE_NAMES.SEASON);
	const dataConfigStage = constructDataAttribute(config.options.stageId, WIDGET_ATTRIBUTE_NAMES.STAGE);
	const widgetRefreshTime = constructDataAttributeImported(config, config.options.refreshTime, WIDGET_ATTRIBUTE_NAMES.REFRESH_TIME);
	const dataLimit = constructDataAttribute(config.options.limit, WIDGET_ATTRIBUTE_NAMES.LIMIT);

	const dataConfigSortDirection = constructDataAttribute(config.options.sortDirection, WIDGET_ATTRIBUTE_NAMES.SORT_DIRECTION);
	const dataConfigSortDirectionFixtures = constructDataAttribute(
		config.options.sortDirectionFixtures,
		WIDGET_ATTRIBUTE_NAMES.SORT_DIRECTION_FIXTURES,
	);
	const dataConfigSortDirectionResults = constructDataAttribute(
		config.options.sortDirectionResults,
		WIDGET_ATTRIBUTE_NAMES.SORT_DIRECTION_RESULTS,
	);
	const dataHeaderDisplay = constructDataAttributeImported(config, config.options.displayHeader, WIDGET_ATTRIBUTE_NAMES.HEADER_DISPLAY);

	const headerDefaultOption = checkIfCorrectWidgetTypes(config.widgetId, [WidgetsV2Ids.widgetIceHockeyLivescore])
		? constructDataAttribute(config.options.headerDefaultOption, WIDGET_ATTRIBUTE_NAMES.HEADER_DEFAULT_OPTION)
		: '';
	return getFormattedContentData(
		`<div data-widget-id='${config.widgetId}' data-widget-sport='ice-hockey' data-widget-type='tournament' ${dataConfigDate}${oddsDisplay}${oddsBettingId}${dataConfigCompetitions}${dataConfigSeason}${dataConfigStage}${dataHeaderDisplay}${headerDefaultOption}${dataConfigSortDirection}${dataConfigSortDirectionFixtures}${dataConfigSortDirectionResults}${dataLimit}${widgetRefreshTime}></div>`,
	);
};

export const constructIceHockeyEventWidgetContent = (config: BlockConfigModel) => {
	const { oddsDisplay, oddsBettingId, oddsMarket } = buildOddsDataAttributes(config, true);
	const widgetRefreshTime = constructDataAttributeImported(config, config.options.refreshTime, WIDGET_ATTRIBUTE_NAMES.REFRESH_TIME);

	let contentData = `<div data-widget-id='${config.widgetId}' data-match-id='${config.options.gameId}' data-widget-type='event' data-widget-sport='ice-hockey'${widgetRefreshTime}`;

	const configSettings = [oddsBettingId, oddsDisplay, oddsMarket];

	configSettings.forEach((item: any) => {
		contentData = addValueIfNotEmpty(item, contentData);
	});
	contentData += '></div>';

	return getFormattedContentData(contentData);
};

export const constructIceHockeyStandingsWidgetContent = (config: BlockConfigModel) => {
	const dataConfigCompetition = constructDataAttribute(config.options.competitionId, WIDGET_ATTRIBUTE_NAMES.COMPETITION);
	const dataConfigSeason = constructDataAttribute(config.options.seasonId, WIDGET_ATTRIBUTE_NAMES.SEASON);
	const dataConfigStage = constructDataAttribute(config.options.stageId, WIDGET_ATTRIBUTE_NAMES.STAGE);
	const widgetRefreshTime = constructDataAttributeImported(config, config.options.refreshTime, WIDGET_ATTRIBUTE_NAMES.REFRESH_TIME);

	const dataHeaderDisplay = constructDataAttributeImported(config, config.options.displayHeader, WIDGET_ATTRIBUTE_NAMES.HEADER_DISPLAY);
	const headerDefaultOption = checkIfCorrectWidgetTypes(config.widgetId, [WidgetsV2Ids.widgetIceHockeyStandings])
		? constructDataAttribute(config.options.headerDefaultOption, WIDGET_ATTRIBUTE_NAMES.HEADER_DEFAULT_OPTION)
		: '';
	const matchType =
		config.options.matchType && config.options.matchType !== MatchTypes.BOTH
			? constructDataAttribute(config.options.matchType, WIDGET_ATTRIBUTE_NAMES.MATCH_TYPE)
			: '';
	const dataTeam = config.options.teamId ? constructDataAttribute(config.options.teamId, WIDGET_ATTRIBUTE_NAMES.TEAM) : '';

	return getFormattedContentData(
		`<div data-widget-id='${config.widgetId}' data-widget-sport='ice-hockey' data-widget-type='tournament' ${dataConfigCompetition}${dataConfigSeason}${dataConfigStage}${dataHeaderDisplay}${headerDefaultOption}${matchType}${dataTeam}${widgetRefreshTime}></div>`,
	);
};

export const sportsConfigFromProject = (project: Project, sport: string) => {
	const config = featuresService.getFeatureConfig(sport);
	const SPORTS_URL = returnCorrectSportApiUrl(project, sport);
	const auth = config.authentication && `${config.authentication.username}:${config.authentication.password}`;
	const competitionList =
		config.competitionList && config.competitionList.length > 0 ? config.competitionList : project.defaultTournamentOrder;
	return {
		lang: project.language.split('-')[0],
		apiKey: auth,
		apiURL: SPORTS_URL,
		defaultCompetitionList: competitionList,
		timezone: project.timezone,
		project: project.domain,
		oddClient: config.oddClient ? config.oddClient : null,
	};
};

export const addValueIfNotEmpty = (value: string, string: string) => {
	if (value && value.length > 0) {
		return string.concat(value);
	} else {
		return string;
	}
};

export const returnCorrectSportApiUrl = (project: Project, sport: string) => {
	switch (sport) {
		case FeatureTypes.FOOTBALL_V2:
			return project.footballApiUrl;
		case FeatureTypes.BASKETBALL:
			return REACT_APP_URLS.REACT_APP_BASKETBALL_API_URL;
		case FeatureTypes.TENNIS:
			return REACT_APP_URLS.REACT_APP_TENNIS_API_URL;
		case FeatureTypes.ICE_HOCKEY:
			return REACT_APP_URLS.REACT_APP_MULTISPORT_API_URL;
	}
};

export const checkIfCorrectWidgetTypes = (widgetId: string, correctWidgetIds: string[]) => {
	return correctWidgetIds.includes(widgetId);
};

export const checkIfCorrectWidgetType = (widgetId: string, correctWidgetIds: string) => {
	return widgetId === correctWidgetIds;
};

export const constructDataPreviewAttribute = (attributeValue: any, attributeName: string) => {
	return attributeValue ? `${attributeName}${attributeValue};` : '';
};

export const constructDataAttribute = (attributeValue: any, attributeName: string) => {
	return !isValueEmpty(attributeValue) ? `${attributeName}'${attributeValue}'` : '';
};

export const buildOddProviderIds = (config: BlockConfigModel) => {
	return constructDataPreviewAttribute(config.options.oddProviderIds, WIDGET_PREVIEW_ATTRIBUTE_NAMES.BETTING_ID);
};
