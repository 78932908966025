import { ReactPaginateProps } from 'react-paginate';
import { PAGINATE_PROPS } from '../../../Partials/BaseComponents/ListingComponent/subcomponents/AuthorizedListing/properties/PaginateProps';
import i18next from 'i18next';
import { IScheduledContentPaginationResponse, ISchedulesAdvancedSearchFiltersModel } from '../models/models';
import { SelectMenuOptionType } from '../../../../models/v2/general/select.model';
import CreatedByModel from '../../../../models/v2/created-by/created-by.model';
import SportsEntityModel from '../../../../models/v2/sports-types/sports-entity.model';
import { assetsPlaceholder } from '../../../../constants/assetsPlaceholder';
import moment from 'moment-timezone';

export const PAGE_LIMIT = 20;

export enum SchedulesListingStatuses {
	SCHEDULED = 'SCHEDULED',
	IN_PROGRESS = 'INPROGRESS',
	RETRYING = 'RETRY',
	FAILED = 'FAILED',
}

export const formatScheduledDate = (dateString: string | null): string => {
	if (!dateString) return '';

	return moment(dateString).format('DD.MM.YYYY, HH:mm');
};

export const returnSchedulesListingStatusIcon = (status: SchedulesListingStatuses) => {
	switch (status) {
		case SchedulesListingStatuses.IN_PROGRESS:
			return require('../../../../assets/icons/general/in-progress.svg');
		case SchedulesListingStatuses.SCHEDULED:
			return require('../../../../assets/icons/general/scheduled.svg');
		case SchedulesListingStatuses.RETRYING:
			return require('../../../../assets/icons/general/error-icon-info.svg');
		case SchedulesListingStatuses.FAILED:
			return require('../../../../assets/icons/general/failed-icon.svg');
		default:
			return '';
	}
};

export const schedulesListingPaginateProps = (
	pagination: IScheduledContentPaginationResponse,
	handlePageClick: (selectedItem: { selected: number }) => void,
): ReactPaginateProps => {
	return {
		...PAGINATE_PROPS,
		previousLabel: i18next.t('previous_page'),
		nextLabel: i18next.t('next_page'),
		pageCount: pagination.total_pages ? pagination.total_pages : 1,
		onPageChange: handlePageClick,
		marginPagesDisplayed: 2,
		pageRangeDisplayed: 1,
		containerClassName: 'schedules-listing-pagination',
		forcePage: pagination && pagination.page ? pagination.page - 1 : 0,
	};
};

export const extractScheduledArticlesListingSearchQueryFromFilters = (filters: ISchedulesAdvancedSearchFiltersModel) => {
	let query: string = '';

	if (filters && Object.entries(filters).length > 0) {
		const searchText = filters.event_name && filters.event_name.length > 0 ? `event_name=${filters.event_name}` : '';
		const advancedContentSearchText = constructScheduledArticlesSearchContentQuery(filters);

		query = searchText + advancedContentSearchText;
	}

	return query;
};

export const constructScheduledArticlesSearchContentQuery = (data: ISchedulesAdvancedSearchFiltersModel) => {
	if (!data) return '';

	return Object.entries(data)
		.filter(([_, value]) => value && value.length)
		.map(([key, value]) => `&${key}=${value}`)
		.join('');
};

export const usersToOptions = (users: CreatedByModel[]): SelectMenuOptionType[] => {
	return users && users.length > 0
		? users.filter((user: CreatedByModel) => user.is_active).map((user: CreatedByModel) => userToOption(user) as SelectMenuOptionType)
		: [];
};

export const userToOption = (user: CreatedByModel) => {
	return {
		value: user.id,
		label: user.full_name,
		data: user,
	};
};

export const statusesToOptions = (): SelectMenuOptionType[] => {
	return Object.values(SchedulesListingStatuses).map((status) => ({
		value: status,
		label: i18next.t(status.toLowerCase()),
		data: status,
	}));
};

export const convertResultToOption = (response: SportsEntityModel[]): SelectMenuOptionType[] => {
	if (!response || response.length === 0) return [];

	return response.map((item) => ({
		value: item.id,
		label: item.name,
		logo:
			item.display_asset && item.display_asset.url !== '' && item.display_asset.url !== null
				? item.display_asset.url
				: assetsPlaceholder[item.entity_type],
		data: item,
	}));
};
