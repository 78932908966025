import * as React from 'react';
import { getListTypeTranslated } from '../../../../../Resources/Lists/helpers/list.helper';

type TitleProps = {
	title: string;
	contentType?: string;
};

export const Title: React.FunctionComponent<TitleProps> = ({ title, contentType }) => {
	const contentTitle = contentType === 'list' ? getListTypeTranslated() : title;

	return <div className='card-header d-flex'>{contentTitle}</div>;
};
