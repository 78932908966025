import React from 'react';
import { SearchProperties, State } from './properties/searchBarProperties';
import { Col, FormGroup, Input, InputGroupAddon, InputGroup, Button } from 'reactstrap';
import { appendJWFilter, isJWSection } from '../../../../../Resources/Videos/Helpers/VideoHelper';
import {
	appendCorrectArticleFilter,
	articleOrigins,
	isAiArticleSection,
	isExternalArticleSection,
} from '../../../../../Resources/Articles/Helpers/ArticleHelper';

class SearchBar extends React.Component<SearchProperties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			contentSearchInput: props.contentSearchInput ? props.contentSearchInput : '',
		};
	}

	onContentSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { advancedContentSearchInput, analytics, getContent, updateSearchText, currentProject, filters } = this.props;
		let searchText = event.target.value;

		this.setState({ ...this.state, contentSearchInput: event.target.value });
		if (searchText.length < 1 && advancedContentSearchInput.length < 1) {
			analytics && analytics.sendEvent('Click', 'List Page', 'Search Performed');
			getContent(1, currentProject);
			updateSearchText('', '');
		} else {
			const contentSearch = searchText.length > 0 ? searchText + advancedContentSearchInput : `*${advancedContentSearchInput}`;
			updateSearchText(searchText, contentSearch);

			updateSearchText(searchText, this.buildContentSearchQuery(searchText, advancedContentSearchInput, filters));
		}
	};

	onContentSearch = (event: any) => {
		if (event.keyCode === 13 && (this.state.contentSearchInput.length > 0 || this.props.advancedContentSearchInput.length > 0)) {
			this.searchContent(this.state.contentSearchInput);
		}
	};

	// Function to build the content search query with necessary filters
	buildContentSearchQuery = (text: string, advancedInput: string, filters: Record<string, any>) => {
		const filterHandlers: { condition: () => boolean; handler: (filters: Record<string, any>) => any; key: string }[] = [
			{ condition: isJWSection, handler: appendJWFilter, key: 'videoOrigin' },
			{
				condition: isExternalArticleSection,
				handler: (filters) => appendCorrectArticleFilter(filters, articleOrigins.EXTERNAL),
				key: 'articleOrigin',
			},
			{
				condition: isAiArticleSection,
				handler: (filters) => appendCorrectArticleFilter(filters, articleOrigins.AI_ARTICLE),
				key: 'articleOrigin',
			},
		];

		const filterQueries = filterHandlers
			.filter(({ condition }) => condition())
			.map(({ handler, key }) => {
				const result = handler(filters);
				return result && result[key] && result[key].id ? `&originId=${result[key].id}` : '';
			})
			.filter((query) => query.length > 0)
			.join('');

		return text.length > 0 ? `${text}${advancedInput}${filterQueries}` : `*${advancedInput}${filterQueries}`;
	};

	searchContent = (text: string) => {
		const { advancedContentSearchInput, currentPage, filters, searchContent, updateSearchText, getContent, currentProject } = this.props;

		if (text.match(/^ *$/) !== null && advancedContentSearchInput.match(/^ *$/) !== null) {
			this.setState({ ...this.state, contentSearchInput: '' });
			updateSearchText('', '');
			getContent(currentPage, currentProject);
		} else {
			const contentSearch = this.buildContentSearchQuery(text, advancedContentSearchInput, filters);
			searchContent(contentSearch, currentProject);
			updateSearchText(text, contentSearch);
		}
	};

	render() {
		const { showSearchBar, t } = this.props;
		const { contentSearchInput } = this.state;

		if (!showSearchBar) return null;

		return (
			<FormGroup row className='mb-0'>
				<Col md='12'>
					<InputGroup>
						<Input
							type='text'
							id='search-input'
							name='search-input'
							placeholder={t('content_search_placeholder')}
							onChange={this.onContentSearchInput}
							onKeyDown={this.onContentSearch}
							value={contentSearchInput}
						/>
						<InputGroupAddon addonType='append'>
							<Button type='button' color='primary' id='content-listing-search-button' onClick={() => this.searchContent(contentSearchInput)}>
								<i className='fa fa-search'>&nbsp;</i>
								{t('search')}
							</Button>
						</InputGroupAddon>
					</InputGroup>
				</Col>
			</FormGroup>
		);
	}
}

export default SearchBar;
