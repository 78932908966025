import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Properties, State } from './properties/dashboard.properties';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Project from '../../../models/project/Project';
import withGoogleAnalytics from '../../../services/hoc/withGoogleAnalytics';
import {
	returnObjectForAllStatisticsContentRequest,
	returnObjectForCategoryRelatedStatisticsRequest,
	returnObjectForPlayerStatisticsRequest,
	returnObjectForTeamStatisticsRequest,
	returnObjectForUserStatisticsContentRequest,
} from '../../../store/action-creators/statistics-creator';
import {
	ARTICLE_ENTITY_DELETE_SUCCESS,
	returnObjectForArticleDelete,
	returnObjectForArticleSearch,
} from '../../../store/action-creators/ArticleActionCreator';
import { GALLERY_DELETE_SUCCESS, galleryDelete, gallerySearch } from '../../../store/action-creators/GalleryActionCreator';
import { VIDEO_DELETE_SUCCESS, videoDelete, videoSearch } from '../../../store/action-creators/VideoActionCreator';
import moment from 'moment';
import GlobalChartContainer from './charts/global-chart-container';
import FilteredChart from './charts/filtered-chart';
import { extractChartOptionsData } from './charts/helper.ts/charts.helper';
import './Dashboard.scss';
import ConditionalRenderWrapper from '../../Partials/BaseComponents/ConditionalRenderWraper/ConditionalRenderWrapper';
import CustomCollapsibleElement from '../../Partials/BaseComponents/CustomCollapsibleElement/CustomCollapsibleElement';
import { RowTitleLink } from '../../Partials/BaseComponents/ListingComponent/subcomponents/Rows/subcomponents/RowTitleLink';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, Table } from 'reactstrap';
import RowContentStatisticsComponent from '../../Partials/BaseComponents/ListingComponent/subcomponents/Rows/subcomponents/row-content-statistic/row-content-statistics.component';
import PopoverItem from '../../Partials/BaseComponents/ListingComponent/subcomponents/Rows/PopoverItem';
import { RowEditButton } from '../../Partials/BaseComponents/ListingComponent/subcomponents/Rows/subcomponents/RowEditButton';
import RowCopyButton from '../../Partials/BaseComponents/ListingComponent/subcomponents/Rows/subcomponents/RowCopyButton';
import { RowDeleteButton } from '../../Partials/BaseComponents/ListingComponent/subcomponents/Rows/subcomponents/RowDeleteButton';
import { featuresService } from '../../../App';
import { FeatureTypes } from '../../../services/feature-service/features.enum';
import {
	mapArticleToRowModel,
	mapGalleryToRowModel,
	mapVideoToRowModel,
} from '../../Partials/BaseComponents/ListingComponent/subcomponents/Rows/helpers/RowsContainerHelper';
import { AppState } from '../../../store/store';

class Dashboard extends React.Component<Properties, State> {
	private contentStatisticsConfig = featuresService.getFeatureConfig(FeatureTypes.CONTENT_STATISTICS);

	constructor(props: any) {
		super(props);
		this.state = {
			items: [],
			isLoading: true,
		};
	}

	getArticlesListener = () => this.props.searchArticles(this.getSearchString(), this.props.currentProject);
	getVideosListener = () => this.props.searchVideos(this.getSearchString(), this.props.currentProject);
	getGalleriesListener = () => this.props.searchGalleries(this.getSearchString(), this.props.currentProject);

	componentDidMount(): void {
		const { profile, currentProject } = this.props;
		this.initPageTitle();
		this.props.analytics.init('wqasd');
		this.props.analytics.sendPageView(this.props.location.pathname, 'Dashboard');

		const startDate = moment().subtract(6, 'days').format('YYYY-MM-DD');

		this.props.getUserStatistics(startDate, profile.id, currentProject);
		this.props.getAllStatistics(startDate, currentProject);

		this.props.searchArticles(this.getSearchString(), currentProject);
		this.props.searchVideos(this.getSearchString(), currentProject);
		this.props.searchGalleries(this.getSearchString(), currentProject);

		window.addEventListener(ARTICLE_ENTITY_DELETE_SUCCESS, this.getArticlesListener);
		window.addEventListener(VIDEO_DELETE_SUCCESS, this.getVideosListener);
		window.addEventListener(GALLERY_DELETE_SUCCESS, this.getGalleriesListener);

		setTimeout(() => {
			let data: any = [];
			data.push(this.props.articles, this.props.videos, this.props.galleries);
			this.setState((state: State) => ({ ...state, items: data, isLoading: false }));
		}, 1000);
	}

	componentWillUnmount() {
		window.removeEventListener(ARTICLE_ENTITY_DELETE_SUCCESS, this.getArticlesListener);
		window.removeEventListener(VIDEO_DELETE_SUCCESS, this.getVideosListener);
		window.removeEventListener(GALLERY_DELETE_SUCCESS, this.getGalleriesListener);
	}

	onDelete = (contentId: string, contentType: string) => {
		if (contentType === 'ARTICLE') {
			this.props.deleteArticle(contentId, this.props.currentProject);
		}
		if (contentType === 'VIDEO') {
			this.props.deleteVideo(contentId, this.props.currentProject);
		}
		if (contentType === 'GALLERY') {
			this.props.deleteGallery(contentId, this.props.currentProject);
		}
	};

	getSearchString = () => {
		const { profile } = this.props;

		let start = new Date();
		start.setHours(0, 0, 0, 0);

		let end = new Date();
		end.setHours(23, 59, 59, 999);

		const searchString = `*&from=${start.toISOString()}&to=${end.toISOString()}&createdBy=${profile.id}`;

		return searchString;
	};

	initPageTitle() {
		document.title = this.props.t('dashboard_title');
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>, snapshot?: any) {
		if (
			this.props.articles !== prevProps.articles ||
			this.props.videos !== prevProps.videos ||
			this.props.galleries !== prevProps.galleries
		) {
			setTimeout(() => {
				let data: any = [];
				let articleRowItems = this.props.articles.map((item) => {
					return mapArticleToRowModel(item);
				});
				let videoRowItems = this.props.videos.map((item) => {
					return mapVideoToRowModel(item);
				});
				let galleryRowItems = this.props.galleries.map((item) => {
					return mapGalleryToRowModel(item);
				});
				data.push(articleRowItems, videoRowItems, galleryRowItems);
				this.setState((state: State) => ({ ...state, items: data, isLoading: false }));
			}, 1000);
		}
	}

	render() {
		const {
			allStatistic,
			currentProject,
			t,
			playerStatistic,
			categoryStatistic,
			teamStatistic,
			allCategories,
			adminCategories,
			profile,
		} = this.props;

		const { items, isLoading } = this.state;

		const types = {
			0: 'ARTICLE',
			1: 'VIDEO',
			2: 'GALLERY',
		};

		return (
			<div className={'min-h-500 container-fluid'}>
				<div className={'col text-right font-weight-bold mb-1'}>
					<span className='text-muted'>{this.props.t('welcome_to')} Sportal 365 CMS, </span>
					<span className='text-uppercase'>{this.props.profile.name}</span>
				</div>

				<h4 className='text-left mb-1'>{t('all_content')}</h4>
				<GlobalChartContainer
					t={t}
					statisticCount={extractChartOptionsData(allStatistic, 'count')}
					statisticDates={extractChartOptionsData(allStatistic, 'date')}
				/>
				<div className='dashboard-user-content mt-2'>
					<CustomCollapsibleElement status={'open'} title={`${t('my_content')}`} className='p-1' iconClass='fa fa-list-ul'>
						<div className={`${isLoading ? 'loading-overlay' : ''}`}>
							{items && items[0] && items[1] && items[2] && items[0].length === 0 && items[1].length === 0 && items[2].length === 0 ? (
								<div>{t('no_data_found')}</div>
							) : (
								<Table>
									<thead>
										<tr>
											<th className='w-type'>{t('type')}</th>
											<th className='w-title'>{t('title')}</th>
											<th className=''>{t('active')}</th>
											<th className='w-createdBy d-none d-sm-table-cell'>{t('created_by')}</th>
											<th className='w-date d-none d-sm-table-cell'>{t('published_at')}</th>
											<th className='w-category d-none d-sm-table-cell'>{t('category')}</th>
											{featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_STATISTICS) &&
												this.contentStatisticsConfig.mappings &&
												this.contentStatisticsConfig.mappings.length > 0 &&
												this.contentStatisticsConfig.mappings
													.filter((statistic: any) => statistic.is_main_statistic)
													.map((statistic: any) => {
														return (
															<th key={statistic.display_key} className='display-none d-sm-table-cell align-middle text-center'>
																{t(statistic.display_key)}
															</th>
														);
													})}
											<th className='w-buttons text-right'>{t('actions')}</th>
										</tr>
									</thead>
									<tbody>
										<ConditionalRenderWrapper expectedPermissions={['read_articles', 'write_articles', 'delete_articles']}>
											{items && items.length > 0
												? items.map((data: any, key: number) => {
														return data.map((item: any, index: number) => {
															return (
																<Row
																	content={item}
																	history={this.props.history}
																	type={types[key]}
																	index={index}
																	analytics={this.props.analytics}
																	profile={profile}
																	onDelete={this.onDelete}
																	t={t}
																	key={index}
																/>
															);
														});
												  })
												: null}
										</ConditionalRenderWrapper>
									</tbody>
								</Table>
							)}
						</div>
					</CustomCollapsibleElement>
				</div>
				<CustomCollapsibleElement status={'close'} title={`${t('player')}`} className='p-1' iconClass='fa fa-list-ul'>
					<FilteredChart
						t={t}
						footballApiUrl={currentProject.footballApiUrl}
						data={playerStatistic}
						getPlayerStatistics={this.props.getPlayerStatistics}
						currentProject={currentProject}
						type='player'
						profile={profile}
					/>
				</CustomCollapsibleElement>
				<CustomCollapsibleElement status={'close'} title={`${t('team')}`} className='p-1' iconClass='fa fa-list-ul'>
					<FilteredChart
						t={t}
						footballApiUrl={currentProject.footballApiUrl}
						data={teamStatistic}
						getTeamStatistics={this.props.getTeamStatistics}
						currentProject={currentProject}
						type='team'
						profile={profile}
					/>
				</CustomCollapsibleElement>
				<CustomCollapsibleElement status={'close'} title={`${t('category')}`} className='p-1' iconClass='fa fa-list-ul'>
					<FilteredChart
						t={t}
						currentProject={currentProject}
						data={categoryStatistic}
						type='category'
						allCategories={allCategories}
						adminCategories={adminCategories}
						getCategoryStatistics={this.props.getCategoryStatistics}
						profile={profile}
					/>
				</CustomCollapsibleElement>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch: any) {
	return {
		getAllStatistics: (startDate: string, project: Project) => dispatch(returnObjectForAllStatisticsContentRequest(startDate, project)),
		getUserStatistics: (startDate: string, createdBy: string, project: Project) =>
			dispatch(returnObjectForUserStatisticsContentRequest(startDate, createdBy, project)),
		getPlayerStatistics: (
			resourceType: string,
			relatedType: string,
			relatedId: string,
			startDate: string,
			endDate: string,
			project: Project,
		) => dispatch(returnObjectForPlayerStatisticsRequest(resourceType, relatedType, relatedId, startDate, endDate, project)),
		getTeamStatistics: (resourceType: string, relatedType: string, relatedId: string, startDate: string, endDate: string, project: Project) =>
			dispatch(returnObjectForTeamStatisticsRequest(resourceType, relatedType, relatedId, startDate, endDate, project)),
		getCategoryStatistics: (resourceType: string, startDate: string, endDate: string, categoryId: string, project: Project) =>
			dispatch(returnObjectForCategoryRelatedStatisticsRequest(resourceType, startDate, endDate, categoryId, project)),
		searchArticles: (searchString: string, project: Project) => dispatch(returnObjectForArticleSearch(searchString, project)),
		deleteArticle: (id: string, project: Project) => dispatch(returnObjectForArticleDelete(id, project)),
		searchGalleries: (searchString: string, project: Project) => dispatch(gallerySearch(searchString, project)),
		deleteGallery: (id: string, project: Project) => dispatch(galleryDelete(id, project)),
		searchVideos: (text: string, project: Project) => dispatch(videoSearch(text, project)),
		deleteVideo: (id: string, project: Project) => dispatch(videoDelete(id, project)),
	};
}

function mapStateToProps(state: any) {
	return {
		profile: state.profile.profile,
		currentProject: state.project.currentProject,
		userStatistic: state.statistics.userStatistic,
		allStatistic: state.statistics.allStatistic,
		playerStatistic: state.statistics.playerStatistic,
		teamStatistic: state.statistics.teamStatistic,
		categoryStatistic: state.statistics.categoryStatistic,
		allCategories: state.category.allCategories,
		adminCategories: state.category.adminCategories,
		articles: state.article.articles,
		articleDeleted: state.article.articleDeleted,
		lang: state.project.currentProject.language,
		galleries: state.gallery.galleries,
		galleryDeleted: state.gallery.galleryDeleted,
		videos: state.video.videos,
		videoDeleted: state.video.videoDeleted,
	};
}

export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps),
	withGoogleAnalytics,
)(Dashboard) as React.ComponentType;

const Row: React.FunctionComponent<any> = ({ content, index, t, profile, history, analytics, type, onDelete }) => {
	const urlTypes = {
		ARTICLE: 'articles',
		VIDEO: 'videos',
		GALLERY: 'galleries',
	};

	const iconTypes = {
		ARTICLE: 'fa fa-file-text p-1',
		VIDEO: 'fa fa-video-camera p-1',
		GALLERY: 'fa fa-camera p-1',
	};

	const [dropdownOpen, setDropdownOpen] = useState({ id: '', isOpen: false });
	const contentLanguages = useSelector((state: AppState) => state.project.currentProject.languages);

	const toggle = (id: string) =>
		setDropdownOpen((prevState: any) => {
			return { id, isOpen: !prevState.isOpen };
		});

	return (
		<tr key={`table-row ${index}`}>
			<td className='align-middle'>
				<i className={iconTypes[type]} />
			</td>
			<td className='align-middle'>
				<RowTitleLink
					contentTitle={content.title}
					strapline={content.strapline}
					editContentPath={`/smp/${urlTypes[type]}/edit/${content.id}`}
					canEditContent={true}
					analytics={analytics}
					contentId={`${urlTypes[type]}-${content.id}`}
				/>
			</td>
			<td className='d-none d-sm-table-cell align-middle text-center'>
				{content.status === 'active' ? (
					<i className='fa-lg fa fa-check-circle text-success mx-2' />
				) : (
					<i className='fa-lg fa fa-times-circle text-danger mx-2' />
				)}
			</td>
			<PopoverItem t={t} content={content} lang={profile.language} />
			<td className='d-none d-sm-table-cell align-middle'>
				{moment(content.publishedAt).lang(profile.language.split('_')[0]).format('DD MMM YYYY, HH:mm')}
			</td>
			<td className='d-none d-sm-table-cell align-middle'>{content.mainCategory}</td>
			<RowContentStatisticsComponent content={content} />
			<td className='align-middle d-sm-table-cell'>
				<Nav className='justify-content-end'>
					<Dropdown nav isOpen={dropdownOpen.id === content.id && dropdownOpen.isOpen} toggle={() => toggle(content.id)}>
						<DropdownToggle id={`toggle-menu-caret-${content.id}`} nav caret className='d-flex align-items-center p-0 text-secondary'>
							<RowEditButton
								contentId={content.id}
								display={true}
								editContentPath={`/smp/${urlTypes[type]}/edit/${content.id}`}
								t={t}
								analytics={analytics}
								history={history}
							/>
						</DropdownToggle>
						<DropdownMenu className='p-0'>
							<DropdownItem className='p-0 border-0'>
								<RowCopyButton
									contentType={type.toLowerCase()}
									createContentPath={`/smp/${urlTypes[type]}/create`}
									content={content}
									history={history}
									display={true}
									t={t}
									analytics={analytics}
								/>
							</DropdownItem>
							<DropdownItem className='p-0 border-0'>
								<RowDeleteButton
									contentType={type.toLowerCase()}
									dropdownItem
									analytics={analytics}
									display={true}
									t={t}
									onDelete={(contentId) => {
										onDelete(contentId, type);
									}}
									content={content}
									contentTitle={content.title}
									contentId={content.id}
								/>
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				</Nav>
			</td>
		</tr>
	);
};
