import BlockConfigModel from '../../models/block-config.model/block-config.model';
import Project from '../../../../../models/project/Project';
import { WidgetTypesV2, WidgetsIds } from '../../constants/block.types';
import BlockModel from '../../models/block.model';
import BookmakerModel from '../../partials/bookmaker-select/models/bookmaker.model';
import TeamPlayerModel from '../../partials/team-players-select/models/team-player.model';
import { widgetOptionsFieldMap } from '../../constants/player.constants';
import { extractMatchIdsFromFixtures } from '../../blocks/widgets/fixtures/components/helpers/fixtures-widget.helper';
import { BLOCK_LIST, bugBlocks } from '../../constants/block-list.constants';
import moment from 'moment';
import MatchListConfigModel from '../../partials/match-list-config/models/match-list-config.model';
import { store } from '../../../../../store/store';
import TeamModel from '../../partials/team-select/models/team.model';
import GroupModel from '../../partials/stage-groups-select/models/group.model';
import SeasonModel from '../../blocks/widgets/player-profile/models/season/season.model';
import PlayerModel from '../../blocks/widgets/most-decorated-players/models/player/player.model';
import { extractColor } from '../../blocks/widgets/match/components/helpers/color-picker.helper';
import { customBlocksService, featuresService } from '../../../../../App';

const constructWidgetContent = (config: BlockConfigModel) => {
	const options = JSON.stringify(config.options);
	return `<div data-widgetid='${config.widgetId}' data-options='${options}'></div>`;
};

const configFromProject = (project: Project) => {
	return {
		lang: project.language.split('-')[0],
		apiKey: '2f852b4de2f3a5cf3188e74b33087bb4:2c18422f8e826a7d11aeed52edb2449e',
		apiURL: project.footballApiUrl,
		oddClient: project.defaultOddClient,
	};
};

const widgetOddsConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview.odds;

	const options = {
		...configFromProject(project),
		market: block.data.preview.odds.market && block.data.preview.odds.market.name ? block.data.preview.odds.market : null,
		eventId: block.data.preview.odds.match && block.data.preview.odds.match.id ? block.data.preview.odds.match.id : null,
		oddProviderIds:
			block.data.preview.odds.bookmakers.length > 0 ? block.data.preview.odds.bookmakers.map((bookmaker: BookmakerModel) => bookmaker.id) : [],
		canSelectMarkets: block.data.preview.odds.canSelectMarkets,
		displayMatchHeader: block.data.preview.odds.displayMatchHeader,
		displayTeamShortNamesOnMobile:
			config.threeLetterCodeType && config.threeLetterCodeType.id && config.threeLetterCodeType.id === 'mobile' ? true : false,
		displayTeamShortNamesOnDesktop:
			config.threeLetterCodeType && config.threeLetterCodeType.id && config.threeLetterCodeType.id === 'all' ? true : false,
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsIds[block.data.widget_type]).build();
	const blockContent = constructWidgetContent(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;

	return block;
};

const widgetMatchConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview.match;

	const options = {
		...configFromProject(project),
		activeTab: block.data.preview && block.data.preview.match && block.data.preview.match.activeTab ? block.data.preview.match.activeTab : '',
		displayOdds: block.data.preview.match.displayOdds,
		eventId: block.data.preview.match.match && block.data.preview.match.match.id ? block.data.preview.match.match.id : null,
		market: block.data.preview.odds.market && block.data.preview.odds.market.name ? block.data.preview.odds.market : null,
		oddProviderIds:
			block.data.preview.odds.bookmakers && block.data.preview.odds.bookmakers.length > 0
				? block.data.preview.odds.bookmakers.map((bookmaker: BookmakerModel) => bookmaker.id)
				: [],
		mainOddProvider: block.data.preview.odds.mainBookmaker ? block.data.preview.odds.mainBookmaker.id : null,
		canSelectMarkets: block.data.preview.odds.canSelectMarkets,
		displayMatchHeader: true,
		hideEvents: block.data.preview.match.hideEvents,
		homeTeamColor: extractColor(block.data.preview.match.homeTeamColor),
		awayTeamColor: extractColor(block.data.preview.match.awayTeamColor),
		displayTeamShortNamesOnMobile:
			config.threeLetterCodeType && config.threeLetterCodeType.id && config.threeLetterCodeType.id === 'mobile' ? true : false,
		displayTeamShortNamesOnDesktop:
			config.threeLetterCodeType && config.threeLetterCodeType.id && config.threeLetterCodeType.id === 'all' ? true : false,
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsIds[block.data.widget_type]).build();
	const blockContent = constructWidgetContent(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;

	return block;
};

const widgetTeamProfileConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview.teamProfile;

	const options = {
		...configFromProject(project),
		display: [
			'info',
			block.data.preview.teamProfile.displayStatistics ? 'stats' : '',
			block.data.preview.teamProfile.displayOdds ? 'odds' : '',
		],
		teamId: block.data.preview.teamProfile.team && block.data.preview.teamProfile.team.id ? block.data.preview.teamProfile.team.id : null,
		seasonId:
			block.data.preview.teamProfile.season && block.data.preview.teamProfile.season.id ? block.data.preview.teamProfile.season.id : null,
		eventId:
			block.data.preview.odds && block.data.preview.odds.match && block.data.preview.odds.match.id ? block.data.preview.odds.match.id : null,
		market:
			block.data.preview.odds && block.data.preview.odds.market && block.data.preview.odds.market.name ? block.data.preview.odds.market : null,
		canSelectMarkets: block.data.preview.odds && block.data.preview.odds.canSelectMarkets,
		displayTeamShortNamesOnMobile:
			config.threeLetterCodeType && config.threeLetterCodeType.id && config.threeLetterCodeType.id === 'mobile' ? true : false,
		displayTeamShortNamesOnDesktop:
			config.threeLetterCodeType && config.threeLetterCodeType.id && config.threeLetterCodeType.id === 'all' ? true : false,
		oddProviderIds:
			block.data.preview.odds.bookmakers && block.data.preview.odds.bookmakers.length > 0
				? block.data.preview.odds.bookmakers.map((bookmaker: BookmakerModel) => bookmaker.id)
				: [],
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsIds[block.data.widget_type]).build();
	const blockContent = constructWidgetContent(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;

	return block;
};

const widgetPlayerProfileConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview.playerProfile;

	const options = {
		...configFromProject(project),
		display: [
			'info',
			block.data.preview.playerProfile.displayStatistics ? 'stats' : '',
			block.data.preview.playerProfile.displayOdds ? 'odds' : '',
		],
		playerId:
			block.data.preview.playerProfile.player && block.data.preview.playerProfile.player.id
				? block.data.preview.playerProfile.player.id
				: null,
		eventId: block.data.preview.odds.match && block.data.preview.odds.match.id ? block.data.preview.odds.match.id : null,
		teamId:
			block.data.preview.playerProfile.team && block.data.preview.playerProfile.team.id ? block.data.preview.playerProfile.team.id : null,
		tournamentId:
			block.data.preview.playerProfile.tournament && block.data.preview.playerProfile.tournament.id
				? block.data.preview.playerProfile.tournament.id
				: null,
		tournamentSeasonId:
			block.data.preview.playerProfile.tournament &&
			block.data.preview.playerProfile.tournament.season &&
			block.data.preview.playerProfile.tournament.season.id
				? block.data.preview.playerProfile.tournament.season.id
				: null,
		market: block.data.preview.odds.market && block.data.preview.odds.market.name ? block.data.preview.odds.market : null,
		canSelectMarkets: block.data.preview.odds.canSelectMarkets,
		oddProviderIds:
			block.data.preview.odds.bookmakers && block.data.preview.odds.bookmakers.length > 0
				? block.data.preview.odds.bookmakers.map((bookmaker: BookmakerModel) => bookmaker.id)
				: [],
		displayTeamShortNamesOnMobile:
			config.threeLetterCodeType && config.threeLetterCodeType.id && config.threeLetterCodeType.id === 'mobile' ? true : false,
		displayTeamShortNamesOnDesktop:
			config.threeLetterCodeType && config.threeLetterCodeType.id && config.threeLetterCodeType.id === 'all' ? true : false,
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsIds[block.data.widget_type]).build();
	const blockContent = constructWidgetContent(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;

	return block;
};

const categoriesDisplay = (preview: any) => {
	return Object.keys(preview)
		.filter((key: string) => preview[key] === true)
		.map((caterogy: string) => caterogy.replace('display', '').toLocaleLowerCase());
};

const widgetTeamPlayersConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const options = {
		...configFromProject(project),
		teamId: block.data.preview.teamPlayers.team && block.data.preview.teamPlayers.team.id ? block.data.preview.teamPlayers.team.id : null,
		injuredPlayers: block.data.preview.teamPlayers.injuredPlayers
			? block.data.preview.teamPlayers.injuredPlayers.map((player: TeamPlayerModel) => player.id)
			: [],
		suspendedPlayers: block.data.preview.teamPlayers.suspendedPlayers
			? block.data.preview.teamPlayers.suspendedPlayers.map((player: TeamPlayerModel) => player.id)
			: [],
		categoriesDisplay: categoriesDisplay(block.data.preview.teamPlayers),
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsIds[block.data.widget_type]).build();
	const blockContent = constructWidgetContent(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;

	return block;
};

const mapIds = (array: any[], key: string) => {
	if (array && array.length > 0) {
		return array.map((item: any) => item[key]);
	}

	return [];
};

const widgetPlayerH2HConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview.playerH2H;

	const options = {
		...configFromProject(project),
		playerOne: {
			playerId:
				block.data.preview.playerH2H.player1 && block.data.preview.playerH2H.player1.id ? block.data.preview.playerH2H.player1.id : null,
			seasonIds: mapIds(block.data.preview.playerH2H.player1Seasons, 'value'),
		},
		playerTwo: {
			playerId:
				block.data.preview.playerH2H.player2 && block.data.preview.playerH2H.player2.id ? block.data.preview.playerH2H.player2.id : null,
			seasonIds: mapIds(block.data.preview.playerH2H.player2Seasons, 'value'),
		},
		statFields:
			block.data.preview.playerH2H.player1 && block.data.preview.playerH2H.player1.id
				? widgetOptionsFieldMap[block.data.preview.playerH2H.player1.position]
				: [],
		displayOdds: block.data.preview.playerH2H.displayOdds,
		eventId: block.data.preview.odds.match && block.data.preview.odds.match.id ? block.data.preview.odds.match.id : null,
		market: block.data.preview.odds.market && block.data.preview.odds.market.name ? block.data.preview.odds.market : null,
		oddProviderIds:
			block.data.preview.odds.bookmakers.length > 0 ? block.data.preview.odds.bookmakers.map((bookmaker: BookmakerModel) => bookmaker.id) : [],
		mainOddProvider: block.data.preview.odds.mainBookmaker ? block.data.preview.odds.mainBookmaker.id : null,
		canSelectMarkets: block.data.preview.odds.canSelectMarkets,
		displayMatchHeader: true,
		displayTeamShortNamesOnMobile:
			config.threeLetterCodeType && config.threeLetterCodeType.id && config.threeLetterCodeType.id === 'mobile' ? true : false,
		displayTeamShortNamesOnDesktop:
			config.threeLetterCodeType && config.threeLetterCodeType.id && config.threeLetterCodeType.id === 'all' ? true : false,
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsIds[block.data.widget_type]).build();
	const blockContent = constructWidgetContent(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;

	return block;
};

const widgetTopScorersConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview.topScorers;

	const options = {
		...configFromProject(project),
		seasonId: block.data.preview.topScorers.tournament.season.id,
		selections: block.data.preview.topScorers.selections,
		highlightPlayers: block.data.preview.topScorers.topScorers.map((player: any) => player.id),
		filter:
			block.data.preview.topScorers.team && block.data.preview.topScorers.team.id ? { team: block.data.preview.topScorers.team.id } : null,
		displayTeamShortNamesOnMobile:
			config.threeLetterCodeType && config.threeLetterCodeType.id && config.threeLetterCodeType.id === 'mobile' ? true : false,
		displayTeamShortNamesOnDesktop:
			config.threeLetterCodeType && config.threeLetterCodeType.id && config.threeLetterCodeType.id === 'all' ? true : false,
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsIds[block.data.widget_type]).build();
	const blockContent = constructWidgetContent(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;

	return block;
};

const widgetTeamComparisonConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview.teamComparison;

	const options = {
		...configFromProject(project),
		team1: {
			id:
				block.data.preview.teamComparison.team1 && block.data.preview.teamComparison.team1.id
					? `${block.data.preview.teamComparison.team1.id}`
					: '',
			seasonIds:
				block.data.preview.teamComparison.team1 &&
				block.data.preview.teamComparison.team1.tournament &&
				block.data.preview.teamComparison.team1.tournament.season
					? [`${block.data.preview.teamComparison.team1.tournament.season.id}`]
					: [],
		},
		team2: {
			id:
				block.data.preview.teamComparison.team2 && block.data.preview.teamComparison.team2.id
					? `${block.data.preview.teamComparison.team2.id}`
					: '',
			seasonIds:
				block.data.preview.teamComparison.team2 &&
				block.data.preview.teamComparison.team2.tournament &&
				block.data.preview.teamComparison.team2.tournament.season
					? [`${block.data.preview.teamComparison.team2.tournament.season.id}`]
					: [],
		},
		statFields: block.data.preview.teamComparison.statFields ? block.data.preview.teamComparison.statFields : [],
		displayOdds: block.data.preview.teamComparison.displayOdds,
		eventId: block.data.preview.odds.match && block.data.preview.odds.match.id ? block.data.preview.odds.match.id : null,
		market: block.data.preview.odds.market && block.data.preview.odds.market.name ? block.data.preview.odds.market : null,
		oddProviderIds:
			block.data.preview.odds.bookmakers && block.data.preview.odds.bookmakers.length > 0
				? block.data.preview.odds.bookmakers.map((bookmaker: BookmakerModel) => bookmaker.id)
				: [],
		mainOddProvider: block.data.preview.odds.mainBookmaker ? block.data.preview.odds.mainBookmaker.id : null,
		canSelectMarkets: block.data.preview.odds.canSelectMarkets,
		displayTeamShortNamesOnMobile:
			config.threeLetterCodeType && config.threeLetterCodeType.id && config.threeLetterCodeType.id === 'mobile' ? true : false,
		displayTeamShortNamesOnDesktop:
			config.threeLetterCodeType && config.threeLetterCodeType.id && config.threeLetterCodeType.id === 'all' ? true : false,
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsIds[block.data.widget_type]).build();
	const blockContent = constructWidgetContent(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;

	return block;
};

const widgetFixturesConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const options = {
		...configFromProject(project),
		title: block.data.preview.fixtures.title,
		matchIds:
			block.data.preview.fixtures.matches && block.data.preview.fixtures.matches.length > 0
				? extractMatchIdsFromFixtures(block.data.preview.fixtures)
				: [],
		displayOdds: block.data.preview.fixtures.displayOdds,
		oddProviderIds:
			block.data.preview.odds.bookmakers.length > 0 ? block.data.preview.odds.bookmakers.map((bookmaker: BookmakerModel) => bookmaker.id) : [],
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsIds[block.data.widget_type]).build();
	const blockContent = constructWidgetContent(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;

	return block;
};

const widgetStandingsConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview.standings;

	const options = {
		...configFromProject(project),
		limit: block.data.preview.standings.limit,
		offset: block.data.preview.standings.startFrom,
		tournamentId: block.data.preview.standings.stage.id,
		expand: 'standing.rules',
		highlightTeams: block.data.preview.standings.highlightTeams.map((team: TeamModel) => team.id),
		teamId: block.data.preview.standings.team ? block.data.preview.standings.team.id : null,
		teamOffset: block.data.preview.standings.offset,
		displayForm: block.data.preview.standings.displayLastMatchesResults,
		groups: block.data.preview.standings.group.map((group: GroupModel) => group.id),
		displayTeamShortNamesOnMobile:
			config.threeLetterCodeType && config.threeLetterCodeType.id && config.threeLetterCodeType.id === 'mobile' ? true : false,
		displayTeamShortNamesOnDesktop:
			config.threeLetterCodeType && config.threeLetterCodeType.id && config.threeLetterCodeType.id === 'all' ? true : false,
	};
	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsIds[block.data.widget_type]).build();
	const blockContent = constructWidgetContent(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;

	return block;
};

const widgetMatchListStageRoundConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const model = block.data.preview.matchListStageRound;
	const config = model.config ? model.config : ({} as MatchListConfigModel);

	const fromDate = config && config.from ? config.from : null;
	const toDate = config && config.to ? config.to : null;

	const oddsToggleButton =
		config.oddsConfiguration && config.oddsConfiguration.showToggleButton !== null && config.oddsConfiguration.showToggleButton !== undefined
			? config.oddsConfiguration.showToggleButton
			: false;

	try {
		const options = {
			lang: project.language.split('-')[0],
			apiKey: '2f852b4de2f3a5cf3188e74b33087bb4:2c18422f8e826a7d11aeed52edb2449e',
			apiURL: project.footballApiUrl,
			oddClient: config.bookmaker ? project.defaultOddClient : 'default',
			season: {
				id: `${block.data.preview.matchListStageRound.season.id}`,
				round: block.data.preview.matchListStageRound.round !== null ? block.data.preview.matchListStageRound.round.round : null,
			},
			widgetId: `${block.id}`,
			view: config.view ? config.view : null,
			title: config.title ? config.title : null,
			viewMore: { url: config.viewMoreUrl, label: config.viewMoreLabel },
			oddProviderId: config.bookmaker ? `${config.bookmaker.id}` : null,
			filters: {
				date: {
					from: fromDate ? moment(fromDate).format('YYYY-MM-DD') : fromDate,
					to: toDate ? moment(toDate).format('YYYY-MM-DD') : toDate,
				},
				order: {
					criteria: config.order ? config.order : null,
					direction: config.orderDirection ? config.orderDirection : null,
				},
				grouping: {
					criteria: config.grouping ? config.grouping : null,
				},
			},
			oddsConfiguration: {
				showToggleButton: oddsToggleButton,
			},
			displayTeamShortNamesOnMobile:
				config.threeLetterCodeType && config.threeLetterCodeType.id && config.threeLetterCodeType.id === 'mobile' ? true : false,
			displayTeamShortNamesOnDesktop:
				config.threeLetterCodeType && config.threeLetterCodeType.id && config.threeLetterCodeType.id === 'all' ? true : false,
		};
		const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsIds[block.data.widget_type]).build();
		const blockContent = constructWidgetContent(blockConfig);
		block.data.config = blockConfig;
		block.data.content = blockContent;

		return block;
	} catch (e) {
		console.error(e);
	}

	return block;
};

const widgetTeamFormConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview.teamForm;

	const options = {
		...configFromProject(project),
		teamId: config && config.team && config.team.id ? config.team.id : null,
		seasonIds: config.season && config.season.length > 0 ? config.season.map((season: SeasonModel) => season.id) : [],
		limit: config.limit,
		title: config.title,
		displayTeamShortNamesOnMobile:
			config.threeLetterCodeType && config.threeLetterCodeType.id && config.threeLetterCodeType.id === 'mobile' ? true : false,
		displayTeamShortNamesOnDesktop:
			config.threeLetterCodeType && config.threeLetterCodeType.id && config.threeLetterCodeType.id === 'all' ? true : false,
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsIds[block.data.widget_type]).build();
	const blockContent = constructWidgetContent(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;
	return block;
};

const widgetMostDecoratedPlayersConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const decoratedPlayers = block.data.preview.decoratedPlayers;

	const options = {
		...configFromProject(project),
		limit: decoratedPlayers.limit,
		offset: decoratedPlayers.startFrom,
		tournamentId: decoratedPlayers.tournament.id,
		seasonId: decoratedPlayers.season.id,
		highlightPlayers: decoratedPlayers.highlightPlayers.map((player: PlayerModel) => player.id),
		playerId: decoratedPlayers.player ? decoratedPlayers.player.id : null,
		playerOffset: +decoratedPlayers.playerOffset,
		cardsColumns: decoratedPlayers.columns,
		displayTeamShortNamesOnMobile:
			decoratedPlayers.threeLetterCodeType && decoratedPlayers.threeLetterCodeType.id && decoratedPlayers.threeLetterCodeType.id === 'mobile'
				? true
				: false,
		displayTeamShortNamesOnDesktop:
			decoratedPlayers.threeLetterCodeType && decoratedPlayers.threeLetterCodeType.id && decoratedPlayers.threeLetterCodeType.id === 'all'
				? true
				: false,
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsIds[block.data.widget_type]).build();
	const blockContent = constructWidgetContent(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;

	return block;
};

const widgetPlayerCareerConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview.playerCareer;

	const options = {
		...configFromProject(project),
		playerId: config.player && config.player.id ? config.player.id : null,
		careerFilters: config.championshipFilters.length > 0 ? config.championshipFilters.map((filter: any) => filter.value) : [],
		statsColumns: config.statisticColumns.length > 0 ? config.statisticColumns.map((filter: any) => filter.value) : [],
		tableColumns: config.tableColumns.length > 0 ? config.tableColumns.map((filter: any) => filter.value) : [],
		displayTeamShortNamesOnMobile:
			config.threeLetterCodeType && config.threeLetterCodeType.id && config.threeLetterCodeType.id === 'mobile' ? true : false,
		displayTeamShortNamesOnDesktop:
			config.threeLetterCodeType && config.threeLetterCodeType.id && config.threeLetterCodeType.id === 'all' ? true : false,
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsIds[block.data.widget_type]).build();
	const blockContent = constructWidgetContent(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;

	return block;
};

const widgetSquadStatisticsConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const filteredColumnsArray: any[] = [];
	const columns =
		block.data.preview.squadStatistics.statisticColumns && block.data.preview.squadStatistics.statisticColumns.length > 0
			? block.data.preview.squadStatistics.statisticColumns.map((item: any) => item)
			: [];

	const positions =
		block.data.preview.squadStatistics.playerPosition && block.data.preview.squadStatistics.playerPosition.length > 0
			? block.data.preview.squadStatistics.playerPosition.map((position: any) => position.value)
			: [];

	positions.forEach((position: any) => {
		columns.forEach((item: any) => {
			if (item.position === position) {
				if (item.columns) {
					return filteredColumnsArray.push(item.columns);
				}
			}
		});
	});

	const positionObj = Object.values(
		Object.fromEntries(
			positions.map((position: any, index: number) => [
				position,
				{
					position: position,
					columns: filteredColumnsArray[index].map((item: any) => item.value),
				},
			]),
		),
	);

	const options = {
		...configFromProject(project),
		teamId:
			block.data.preview.squadStatistics.team && block.data.preview.squadStatistics.team.id
				? block.data.preview.squadStatistics.team.id
				: null,
		playerPositions: positionObj,
		seasonId:
			block.data.preview.squadStatistics.season &&
			block.data.preview.squadStatistics.season.data &&
			block.data.preview.squadStatistics.season.data.id
				? block.data.preview.squadStatistics.season.data.id
				: null,
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsIds[block.data.widget_type]).build();
	const blockContent = constructWidgetContent(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;
	return block;
};

export const extractBlockIcons = (blockType: string) => {
	return customBlocksService.isFeatureEnabled(featuresService) && BLOCK_LIST(true)[6] !== null
		? [
				...BLOCK_LIST(true)[3].blocks,
				...BLOCK_LIST(true)[2].blocks,
				...BLOCK_LIST(true)[4].blocks,
				...bugBlocks,
				...BLOCK_LIST(true)[5].blocks,
				...BLOCK_LIST(true)[6].blocks,
		  ]
				.filter((block: any) =>
					block.default_data.data.widget_type ? block.default_data.data.widget_type === blockType : block.default_data.type === blockType,
				)
				.map((block: any) => {
					return {
						blockType: block.default_data.data.widget_type ? block.default_data.data.widget_type : block.default_data.type,
						icon: block.icon,
					};
				})
		: [
				...BLOCK_LIST(true)[3].blocks,
				...BLOCK_LIST(true)[2].blocks,
				...BLOCK_LIST(true)[4].blocks,
				...BLOCK_LIST(true)[6].blocks,
				...bugBlocks,
		  ]
				.filter((block: any) =>
					block.default_data.data.widget_type ? block.default_data.data.widget_type === blockType : block.default_data.type === blockType,
				)
				.map((block: any) => {
					return {
						blockType: block.default_data.data.widget_type ? block.default_data.data.widget_type : block.default_data.type,
						icon: block.icon,
					};
				});
};

export const isCustomBlock = (name: string) => {
	return name === 'custom_block';
};

export const getViewName = (block: BlockModel, editName?: string): string => {
	if (editName) {
		return editName;
	}

	return block.data.widget_type ? block.data.widget_type : block.type;
};

export const mapWidgetConfig = {
	widgetOdds: widgetOddsConfig,
	widgetMatch: widgetMatchConfig,
	teamProfile: widgetTeamProfileConfig,
	teamPlayers: widgetTeamPlayersConfig,
	playerProfile: widgetPlayerProfileConfig,
	widgetPlayerH2H: widgetPlayerH2HConfig,
	widgetTopScorers: widgetTopScorersConfig,
	widgetTeamComparison: widgetTeamComparisonConfig,
	widgetFixtures: widgetFixturesConfig,
	standings: widgetStandingsConfig,
	widgetMatchListStageRound: widgetMatchListStageRoundConfig,
	widgetTeamForm: widgetTeamFormConfig,
	widgetMostDecoratedPlayers: widgetMostDecoratedPlayersConfig,
	widgetPlayerCareer: widgetPlayerCareerConfig,
	widgetSquadStatistics: widgetSquadStatisticsConfig,
};
