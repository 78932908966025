import React, { FunctionComponent } from 'react';
import { DATA_QA } from '../../../helpers/data-qa.properties';
import { useTranslation } from 'react-i18next';
import '../../../styles/article-ai-generation-panel.scss';

type Properties = {
	infoText: string | string[];
};

const ArticleGenerationInfoPanel: FunctionComponent<Properties> = ({ infoText }) => {
	const [t] = useTranslation();

	return Array.isArray(infoText) ? (
		<div>
			{infoText.map((message, index) => (
				<ArticleGenerationInfoPanel key={index} infoText={message} />
			))}
		</div>
	) : (
		<div className='custom-info-panel' data-qa={DATA_QA.ARTICLE_AI_GENERATION_INFO_PANEL}>
			<div className='info-icon' />
			<span className='info-panel-label'>{t(infoText)}</span>
		</div>
	);
};

export default ArticleGenerationInfoPanel;
