import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import { useTranslation } from 'react-i18next';
import { dateFormat, langs } from '../../../../../Partials/Sidebar/general-content/constants/date-time.attributes';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../store/store';
import { DATA_QA } from '../../../helpers/data-qa.properties';
import '../../../styles/article-ai-generation-panel.scss';
import '../../../styles/date-picker.styles.scss';
import { getLocalEquivalentOfMidnightInProjectTimezone } from '../../../helpers/article-ai-generation.helper';

type Props = {
	generationDateFrom: string;
	onGenerationDateFromChange: (date: string) => void;
};

export const GenerationDateFromPicker: FunctionComponent<Props> = ({ generationDateFrom, onGenerationDateFromChange }) => {
	const { t } = useTranslation();
	const project = useSelector((state: AppState) => state.project.currentProject);
	const language = useSelector((state: AppState) => state.profile.profile.language);
	const [toggleInfoPanel, setToggleInfoPanel] = useState<boolean>(false);
	const flatpickrRef = useRef<any>(null);

	const onInfoToggle = () => setToggleInfoPanel(!toggleInfoPanel);

	// Manual formatter to avoid any timezone influence
	const formatDateWithoutTimezone = (date: Date) => {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		const hour = String(date.getHours()).padStart(2, '0');
		const minute = String(date.getMinutes()).padStart(2, '0');
		const second = String(date.getSeconds()).padStart(2, '0');
		const ms = String(date.getMilliseconds()).padStart(3, '0');
		return `${year}-${month}-${day}T${hour}:${minute}:${second}.${ms}`;
	};

	const areDatesDifferent = (newDate: string) => {
		if (!generationDateFrom) return true;
		return newDate !== generationDateFrom;
	};

	//  Load stored value into picker as local time
	useEffect(() => {
		if (generationDateFrom && flatpickrRef.current) {
			const [datePart, timePart] = generationDateFrom.split('T');
			const [year, month, day] = datePart.split('-').map(Number);
			const [hour, minute, secondPart] = timePart.split(':');
			const [second, ms = '0'] = secondPart.split('.');
			const localDate = new Date(year, month - 1, day, Number(hour), Number(minute), Number(second), Number(ms));

			flatpickrRef.current.flatpickr.setDate(localDate, false);
		}
	}, [generationDateFrom]);

	return (
		<div className='input-fields-group' data-qa={DATA_QA.ARTICLE_AI_GENERATION_DATE_AND_TIME_FROM_SELECT}>
			<div className='datepicker-label-container'>
				<span className='input-fields-label'>{t('generation_date_from')}</span>
				<div className='info-icon' onClick={onInfoToggle} />
				{toggleInfoPanel && (
					<div className='info-message'>
						<div className='info-message-title'>
							{t('generation_date_from_title_info')}
							<div className='info-close-icon' onClick={onInfoToggle} />
						</div>
						<div className='info-message-description'>{t('generation_date_title_from_info_description')}</div>
					</div>
				)}
			</div>
			<div className='datepicker-container'>
				<div className='datepicker-wrapper'>
					<Flatpickr
						id='generation-date-from'
						className='article-generation-date-picker'
						options={{
							defaultDate: generationDateFrom || '',
							dateFormat: dateFormat,
							mode: 'single',
							time_24hr: true,
							enableTime: true,
							locale: langs[language],
							minDate: getLocalEquivalentOfMidnightInProjectTimezone(project.timezone),
							defaultHour: generationDateFrom ? Number(generationDateFrom.split('T')[1].split(':')[0]) : 0,
							defaultMinute: generationDateFrom ? Number(generationDateFrom.split('T')[1].split(':')[1]) : 0,
							onClose: (selectedDates) => {
								if (selectedDates.length > 0) {
									const selected = selectedDates[0];
									const formatted = formatDateWithoutTimezone(selected);

									if (areDatesDifferent(formatted)) {
										onGenerationDateFromChange(formatted);
									}
								}
							},
						}}
						placeholder={t('select_date')}
						ref={flatpickrRef}
					/>
					<span className='calendar-icon' />
				</div>
			</div>
		</div>
	);
};
