import React from 'react';
import { connect } from 'react-redux';
import Project from '../../../../models/project/Project';
import ListingComponent from '../../../Partials/BaseComponents/ListingComponent/ListingComponent';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import ConditionalRenderWrapper from '../../../Partials/BaseComponents/ConditionalRenderWraper/ConditionalRenderWrapper';
import { ContentTypes } from '../../../../constants/content-types';
import { actionService, featuresService } from '../../../../App';
import { FeatureTypes } from '../../../../services/feature-service/features.enum';
import PaginationMeta from '../../../../models/pagination/PaginationMeta';
import {
	ARTICLE_ENTITY_DELETE_SUCCESS,
	returnObjectForArticleDelete,
	returnObjectForArticlePaginationUpdate,
	returnObjectForExternalArticleListingRequest,
	returnObjectForExternalArticleSearch,
} from '../../../../store/action-creators/ArticleActionCreator';
import { returnObjectForGalleryPaginationUpdate } from '../../../../store/action-creators/GalleryActionCreator';
import { returnObjectForVideoPaginationUpdate } from '../../../../store/action-creators/VideoActionCreator';
import { returnObjectForWikiPaginationUpdate } from '../../../../store/action-creators/WikiActionCreator';
import { returnObjectForImagesPaginationUpdate } from '../../../../store/action-creators/ImageActionCreator';
import AdvancedFilterModel from '../../../Partials/Shared/advanced-content-filter/models/advanced-content-filter.model';
import { extractSearchQueryFromFilters } from '../../../Partials/Shared/advanced-content-filter/helpers/advanced-content-filter.helper';
import { returnObjectForContentFiltersUpdate } from '../../../../store/action-creators/content-resources';
import {
	appendCorrectArticleFilter,
	articleOrigins,
	isExternalArticleSection,
	remmapContentFilters,
	shouldRenderArticlesRows,
} from '../Helpers/ArticleHelper';
import { LANGUAGE_CODE_UPDATE_SUCCESS } from '../../../../store/action-creators/ProjectResourcesActionCreators';
import { Subscription } from 'rxjs';
import Profile from '../../../../models/profile/Profile';
import Article from '../../../../models/article/Article';
import { updateTempArticle } from '../../../../store/action-creators/ArticleTempActionCreator';
import i18n from 'i18next';
import { CopyToProjectsModal } from '../../../Partials/Modals/CopyToProjects/CopyToProjectsModal';

export type Properties = {
	getArticles: (page: string, project: Project, text: string) => void;
	searchArticles: (text: string, project: Project) => void;
	currentProject: Project;
	deleteArticle: (id: string, project: Project) => void;
	analytics: any;
	articles: Article[];
	pagination: PaginationMeta;
	articleDeleted: boolean;
	profile: Profile;
	location: any;
	history: any;
	updateArticlesPagination: (pagination: PaginationMeta) => void;
	updateVideosPagination: (pagination: PaginationMeta) => void;
	updateGalleriesPagination: (pagination: PaginationMeta) => void;
	updateImagesPagination: (pagination: PaginationMeta) => void;
	updateWikiPagesPagination: (pagination: PaginationMeta) => void;
	updateContentFilters: (filters: any) => void;
	filters: AdvancedFilterModel;
	updateTempArticle: (article: Article) => void;
	tempArticle: Article;
};

export type State = {
	toggleModal: boolean;
	currentPage: string;
};

class ExternalArticlesListing extends React.Component<Properties, State> {
	private actionServiceSubject = new Subscription();

	constructor(props: Properties) {
		super(props);
		this.state = {
			toggleModal: false,
			currentPage: this.props.pagination && this.props.pagination.currentPage ? this.props.pagination.currentPage.toString() : '1',
		};
		this.registerActionEventListener();
	}

	getArticlesListener = () => this.applyFilters(this.props.filters);

	registerActionEventListener() {
		window.addEventListener(ARTICLE_ENTITY_DELETE_SUCCESS, this.getArticlesListener);

		this.actionServiceSubject = actionService.onActionReceived().subscribe((action: string) => {
			if (action === LANGUAGE_CODE_UPDATE_SUCCESS && isExternalArticleSection()) {
				this.setState({ currentPage: '1' });
				// this.getArticlesListener();
				const page = '1';
				const resultFilters = appendCorrectArticleFilter(this.props.filters || {}, articleOrigins.EXTERNAL);
				this.applyFilters(resultFilters, page);

				//Update language in temp article
				const article = Article.builder(this.props.tempArticle)
					.withId('temp-id')
					.withLanguage(this.props.currentProject.languages.defaultLanguageCode.languageCode)
					.build();
				this.props.updateTempArticle(article);
			}
		});
	}

	setCurrentPage(page: string) {
		this.props.analytics.sendPageView(
			this.props.location.pathname,
			`${parseInt(page) > 1 ? 'RSM Articles Listing - Page ' + page : 'RSM Articles Listing'}`,
		);
		this.setState({
			...this.state,
			currentPage: page,
		});
	}

	componentDidMount(): void {
		this.initPageTitle();
		this.props.analytics.sendPageView(this.props.location.pathname, 'RSM Articles Listing');

		if (!featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.RSM_ARTICLES)) {
			this.props.history.push('/dashboard');
		}
		this.props.updateArticlesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateVideosPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateGalleriesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateImagesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateWikiPagesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.applyFilters(this.props.filters);

		const filters = remmapContentFilters({}, {}, {}, {}, {}, {}, {}, {}, {}, {}, this.props.filters);
		this.props.updateContentFilters(filters);
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>, snapshot?: any) {
		if (this.state.currentPage !== prevState.currentPage) {
			this.registerActionEventListener();
		}
	}

	componentWillUnmount() {
		window.removeEventListener(ARTICLE_ENTITY_DELETE_SUCCESS, this.getArticlesListener);
		this.actionServiceSubject.unsubscribe();
	}

	initPageTitle() {
		document.title = i18n.t('rsm_articles');
	}

	applyFilters = (filters: AdvancedFilterModel, page?: string) => {
		const resultFilters = appendCorrectArticleFilter(filters || {}, articleOrigins.EXTERNAL);
		const query = extractSearchQueryFromFilters(resultFilters);
		this.props.getArticles(
			page ? page : this.props.pagination && this.props.pagination.currentPage && this.props.pagination.currentPage.toString(),
			this.props.currentProject,
			query,
		);
	};

	onDeleteContent = (id: string) => {
		this.props.deleteArticle(id, this.props.currentProject);
	};

	render() {
		return (
			<ConditionalRenderWrapper expectedPermissions={['read_articles', 'write_articles', 'delete_articles']}>
				<ListingComponent
					contentType={ContentTypes.ARTICLE}
					getContent={this.props.getArticles}
					analytics={this.props.analytics}
					searchContent={this.props.searchArticles}
					currentProject={this.props.currentProject}
					onPageChange={this.setCurrentPage.bind(this)}
					history={this.props.history}
					onDeleteContent={(id: string) => this.onDeleteContent(id)}
					content={shouldRenderArticlesRows(ContentTypes.ARTICLE, articleOrigins.EXTERNAL, isExternalArticleSection) ? this.props.articles : []}
					contentPagination={this.props.pagination}
					showSearchBar={true}
				/>
				<CopyToProjectsModal />
			</ConditionalRenderWrapper>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		profile: state.profile.profile,
		articles: state.article.articles,
		articleDeleted: state.article.articleDeleted,
		currentProject: state.project.currentProject,
		pagination: state.article.externalArticlesPagination,
		filters: state.article.externalArticlesFilters,
		tempArticle: state.tempArticle.article,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		getArticles: (page: string, project: Project, text: string) =>
			dispatch(returnObjectForExternalArticleListingRequest(page, project, text)),
		searchArticles: (text: string, project: Project) => dispatch(returnObjectForExternalArticleSearch(text, project)),
		deleteArticle: (id: string, project: Project) => dispatch(returnObjectForArticleDelete(id, project)),
		updateArticlesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForArticlePaginationUpdate(pagination)),
		updateVideosPagination: (pagination: PaginationMeta) => dispatch(returnObjectForVideoPaginationUpdate(pagination)),
		updateGalleriesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForGalleryPaginationUpdate(pagination)),
		updateImagesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForImagesPaginationUpdate(pagination)),
		updateWikiPagesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForWikiPaginationUpdate(pagination)),
		updateContentFilters: (filters: AdvancedFilterModel) => dispatch(returnObjectForContentFiltersUpdate(filters)),
		updateTempArticle: (article: Article) => dispatch(updateTempArticle(article)),
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(ExternalArticlesListing) as React.ComponentType;
