import { getScheduledArticlesCountData, useReactQueryMutationWrapper } from '../../../../services/react-query/react-query.helper';
import { updateAiSchedulesCount } from '../../../../store/action-creators/ai-articles-action-creator';
import ArticleSchedulerHttpService from './article-scheduler-http-service';
import { showCustomToast } from '../components/subcomponents/schedules-listing-components/subcomponents/custom-toast-norification';
import i18next from 'i18next';
import { GenerateArticleRequestStatuses } from './article-ai-generation.helper';
import { IScheduledContentGeneration, ISchedulesAdvancedSearchFiltersModel } from '../models/models';

export const fetchSchedulesCount = async (dispatch: Function) => {
	try {
		const data = await getScheduledArticlesCountData();
		dispatch(updateAiSchedulesCount(data));
	} catch (error) {
		console.error('Failed to fetch schedules count:', error);
	}
};

export const useGenerateSchedule = (dispatch: Function) => {
	return useReactQueryMutationWrapper(
		async (dataForRequestSchedule) => {
			const { data, hasSuccess, hasWarning } = await ArticleSchedulerHttpService.generateSchedule(
				dataForRequestSchedule as IScheduledContentGeneration,
			);
			return { data, hasSuccess, hasWarning };
		},
		{
			onSuccess: ({ hasSuccess, hasWarning }) => {
				if (hasWarning) {
					showCustomToast(i18next.t('similar_event'), i18next.t('similar_event_message'), GenerateArticleRequestStatuses.WARN);
				}

				if (hasSuccess) {
					showCustomToast(
						i18next.t('scheduled_creation_success'),
						i18next.t('schedule_generated_successfully'),
						GenerateArticleRequestStatuses.SUCCESS,
					);
				}

				fetchSchedulesCount(dispatch);
			},
			onError: (error) => {
				showCustomToast(i18next.t('error'), error.message, GenerateArticleRequestStatuses.ERROR);
			},
		},
	);
};

export const useDeleteSchedule = (
	dispatch: Function,
	filters: ISchedulesAdvancedSearchFiltersModel,
	fetchSchedulesCount: Function,
	getSchedules: Function,
) => {
	return useReactQueryMutationWrapper((id: string) => ArticleSchedulerHttpService.deleteSchedule(id), {
		onSuccess: () => {
			showCustomToast(i18next.t('schedule_deletion'), i18next.t('schedule_delete_success'), GenerateArticleRequestStatuses.SUCCESS);

			fetchSchedulesCount(dispatch);
			getSchedules(filters);
		},
		onError: () => {
			showCustomToast(i18next.t('schedule_deletion'), i18next.t('schedule_delete_error'), GenerateArticleRequestStatuses.ERROR);
		},
		refetchQueries: ['aiArticles'],
	});
};
