import React from 'react';
import { connect } from 'react-redux';
import Project from '../../../models/project/Project';
import { Properties, State } from './Properties/GalleryListingProperties';
import ListingComponent from '../../Partials/BaseComponents/ListingComponent/ListingComponent';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import {
	galleriesRequest,
	GALLERY_DELETE_SUCCESS,
	galleryDelete,
	galleryDeleteFailed,
	gallerySearch,
} from '../../../store/action-creators/GalleryActionCreator';
import { ContentTypes } from '../../../constants/content-types';
import ConditionalRenderWrapper from '../../Partials/BaseComponents/ConditionalRenderWraper/ConditionalRenderWrapper';
import PaginationMeta from '../../../models/pagination/PaginationMeta';
import { returnObjectForVideoPaginationUpdate } from '../../../store/action-creators/VideoActionCreator';
import { returnObjectForArticlePaginationUpdate } from '../../../store/action-creators/ArticleActionCreator';
import AdvancedFilterModel from '../../Partials/Shared/advanced-content-filter/models/advanced-content-filter.model';
import { extractSearchQueryFromFilters } from '../../Partials/Shared/advanced-content-filter/helpers/advanced-content-filter.helper';
import { returnObjectForContentFiltersUpdate } from '../../../store/action-creators/content-resources';
import { remmapContentFilters } from '../Articles/Helpers/ArticleHelper';
import { Subscription } from 'rxjs';
import { actionService } from '../../../App';
import { LANGUAGE_CODE_UPDATE_SUCCESS } from '../../../store/action-creators/ProjectResourcesActionCreators';
import Gallery from '../../../models/gallery/Gallery';
import { updateTempGallery } from '../../../store/action-creators/GalleryTempActionCreator';
import { returnObjectForWikiPaginationUpdate } from '../../../store/action-creators/WikiActionCreator';

class GalleryListing extends React.Component<Properties, State> {
	private actionServiceSubject = new Subscription();

	constructor(props: any) {
		super(props);
		this.state = {
			gallerySearchInput: '',
			toggleModal: false,
			currentPage: this.props.pagination.currentPage ? this.props.pagination.currentPage.toString() : '1',
		};
		this.registerActionEventListener();
	}

	getGalleriesListener = () => this.applyFilters(this.props.filters);

	registerActionEventListener() {
		window.addEventListener(GALLERY_DELETE_SUCCESS, this.getGalleriesListener);

		this.actionServiceSubject = actionService.onActionReceived().subscribe((action: string) => {
			if (action === LANGUAGE_CODE_UPDATE_SUCCESS) {
				this.setState({ currentPage: '1' });
				this.getGalleriesListener();

				//Update language in temp gallery
				const gallery = Gallery.builder(this.props.tempGallery)
					.withId('temp-id')
					.withLanguage(this.props.currentProject.languages.defaultLanguageCode.languageCode)
					.build();
				this.props.updateTempGallery(gallery);
			}
		});
	}

	setCurrentPage(page: string) {
		this.props.analytics.sendPageView(
			this.props.location.pathname,
			`${parseInt(page) > 1 ? 'GalleryListing - Page ' + page : 'GalleryListing'}`,
		);
		this.setState({
			...this.state,
			currentPage: page,
		});
	}

	componentDidMount(): void {
		this.initPageTitle();
		this.props.analytics.sendPageView(this.props.location.pathname, 'GalleryListing');
		this.applyFilters(this.props.filters);
		this.props.updateArticlesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateVideosPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateWikiPagesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		const filters = remmapContentFilters({}, {}, this.props.filters, {}, {}, {}, {}, {}, {}, {} as AdvancedFilterModel);
		this.props.updateContentFilters(filters);
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>, snapshot?: any) {
		if (this.state.currentPage !== prevState.currentPage) {
			this.registerActionEventListener();
		}
	}

	componentWillUnmount() {
		window.removeEventListener(GALLERY_DELETE_SUCCESS, this.getGalleriesListener);
		this.actionServiceSubject.unsubscribe();
	}

	initPageTitle() {
		document.title = this.props.t('galleries');
	}

	applyFilters = (filters: AdvancedFilterModel) => {
		const query = extractSearchQueryFromFilters(filters);

		query.length > 0 && query !== '*'
			? this.props.getGalleries(this.props.pagination.currentPage, this.props.currentProject, query)
			: this.props.getGalleries(this.props.pagination.currentPage, this.props.currentProject);
	};

	onDeleteContent = (id: string) => {
		this.props.deleteGallery(id, this.props.currentProject);
	};

	render() {
		return (
			<ConditionalRenderWrapper expectedPermissions={['read_galleries', 'write_galleries', 'delete_galleries']}>
				<ListingComponent
					contentType={ContentTypes.GALLERY}
					getContent={this.props.getGalleries}
					searchContent={this.props.searchGalleries}
					analytics={this.props.analytics}
					currentProject={this.props.currentProject}
					onPageChange={this.setCurrentPage.bind(this)}
					content={this.props.galleries}
					history={this.props.history}
					onDeleteContent={(id: string) => this.onDeleteContent(id)}
					contentPagination={this.props.pagination}
					showSearchBar={true}
				/>
			</ConditionalRenderWrapper>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		profile: state.profile.profile,
		galleries: state.gallery.galleries,
		galleryDeleted: state.gallery.galleryDeleted,
		currentProject: state.project.currentProject,
		pagination: state.gallery.pagination,
		filters: state.gallery.filters,
		tempGallery: state.tempGallery.gallery,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		getGalleries: (page: string, project: Project, text: string) => dispatch(galleriesRequest(page, project, text)),
		searchGalleries: (text: string, project: Project) => dispatch(gallerySearch(text, project)),
		deleteGallery: (id: string, project: Project) => dispatch(galleryDelete(id, project)),
		clearGalleryDeleted: (id: string, project: Project) => dispatch(galleryDeleteFailed()),
		updateArticlesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForArticlePaginationUpdate(pagination)),
		updateVideosPagination: (pagination: PaginationMeta) => dispatch(returnObjectForVideoPaginationUpdate(pagination)),
		updateWikiPagesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForWikiPaginationUpdate(pagination)),
		updateContentFilters: (filters: any) => dispatch(returnObjectForContentFiltersUpdate(filters)),
		updateTempGallery: (gallery: Gallery) => dispatch(updateTempGallery(gallery)),
	};
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(GalleryListing) as React.ComponentType;
