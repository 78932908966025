import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ISchedulesAdvancedSearchFiltersModel } from '../../../models/models';
import '../../../styles/article-ai-generation-panel.scss';
import { DATA_QA } from '../../../helpers/data-qa.properties';
import { Input } from 'reactstrap';
import { extractScheduledArticlesListingSearchQueryFromFilters } from '../../../helpers/listing.helper';

type Properties = {
	filters: ISchedulesAdvancedSearchFiltersModel;
	setFilters: (filters: ISchedulesAdvancedSearchFiltersModel) => void;
	getContent: (filters: ISchedulesAdvancedSearchFiltersModel) => void;
	setToggle: (toggle: boolean) => void;
};

const SchedulesListingSearch: FunctionComponent<Properties> = ({ filters, getContent, setFilters, setToggle }) => {
	const [t] = useTranslation();
	const [searchQuery, setSearchQuery] = useState(filters && filters.event_name ? filters.event_name : '');
	const [toggleFilters, setToggleAdvancedFilters] = useState(false);
	const searchQueryFromFilters = extractScheduledArticlesListingSearchQueryFromFilters(filters);

	const toggleAdvancedFilters = () => {
		setToggleAdvancedFilters(!toggleFilters);
		setToggle(!toggleFilters);
	};

	const onSearchInput = (data: ChangeEvent<HTMLInputElement>) => {
		let searchText = data.target.value;
		setSearchQuery(searchText);

		if (searchText.length < 1 && searchQueryFromFilters.length < 1) {
			setFilters({ ...filters, event_name: '', page: 1 });
		} else {
			setFilters({ ...filters, event_name: searchText });
		}
	};

	const searchContent = (text: string) => {
		// Checks if a string contains only spaces or is completely empty
		if (text.match(/^ *$/) !== null && searchQueryFromFilters.match(/^ *$/) !== null) {
			setSearchQuery('');
			setFilters({ ...filters, event_name: '', page: 1 });
			getContent(filters);
		} else {
			getContent(filters);
			setFilters({ ...filters, event_name: text });
		}
	};

	return (
		<div className='schedules-listing-search-container' data-qa={DATA_QA.ARTICLE_AI_SCHEDULES_LISTING_SEARCH_BAR}>
			<div className='schedules-listing-search-bar-input-group'>
				<div className='schedules-listing-search-bar-wrapper'>
					<span className='search-icon' />
					<Input
						id={DATA_QA.ARTICLE_AI_SCHEDULES_LISTING_SEARCH_BAR}
						value={searchQuery}
						onChange={(el) => onSearchInput(el)}
						placeholder={t('schedules_search_placeholder')}
						type='text'
						element='input'
						className='schedules-listing-search-bar'
					/>
					<div className='schedules-search-button' onClick={() => searchContent(searchQuery)}>
						<span className='schedules-search-button-label'>{t('search')}</span>
					</div>
				</div>
			</div>
			<span
				data-qa={DATA_QA.ARTICLE_AI_SCHEDULES_LISTING_ADVANCED_FILERS_TOGGLE}
				className='filters-icon'
				onClick={() => toggleAdvancedFilters()}
			/>
		</div>
	);
};

export default SchedulesListingSearch;
