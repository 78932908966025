import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../../styles/article-ai-generation-panel.scss';
import '../../../../styles/date-picker.styles.scss';
import { DATA_QA } from '../../../../helpers/data-qa.properties';
import { ISchedulesAdvancedSearchFiltersModel } from '../../../../models/models';
import Select from 'react-select';
import { SelectMenuOptionType } from '../../../../../../../models/v2/general/select.model';
import { articleAiGenerationSelectStylesVariant } from '../../../../helpers/article-ai-generation.helper';
import { statusesToOptions } from '../../../../helpers/listing.helper';

type Properties = {
	schedulesFilters: ISchedulesAdvancedSearchFiltersModel;
	updateSchedulesAdvancedSearchText: (filters: ISchedulesAdvancedSearchFiltersModel) => void;
};

const StatusSelect: FunctionComponent<Properties> = ({ schedulesFilters, updateSchedulesAdvancedSearchText }) => {
	const [t] = useTranslation();

	const onStatusSelect = (selectedStatus: SelectMenuOptionType) => {
		let advancedContentModel = schedulesFilters;
		advancedContentModel.status = selectedStatus && selectedStatus.data ? selectedStatus.data : '';
		updateSchedulesAdvancedSearchText(advancedContentModel);
	};

	const statusOptions = statusesToOptions();
	const selectedStatus = statusOptions.find((option) => option.value === schedulesFilters.status) || null;

	return (
		<div className='input-fields-group' data-qa={DATA_QA.ARTICLE_AI_GENERATION_GENERATE_ARTICLE_STATUS_SELECT}>
			<span className='input-fields-label'>{t('status')}</span>
			{
				<Select
					id={DATA_QA.ARTICLE_AI_GENERATION_GENERATE_ARTICLE_STATUS_SELECT}
					value={selectedStatus}
					options={statusOptions}
					onChange={(selection) => onStatusSelect(selection as SelectMenuOptionType)}
					placeholder={t('select')}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					// @ts-ignore because of styles
					styles={articleAiGenerationSelectStylesVariant(false, '374px')}
				/>
			}
		</div>
	);
};

export default StatusSelect;
