import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../../styles/article-ai-generation-panel.scss';
import '../../../../styles/date-picker.styles.scss';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import { AppState } from '../../../../../../../store/store';
import { DATA_QA } from '../../../../helpers/data-qa.properties';
import { dateFormat, langs } from '../../../../../../Partials/Sidebar/general-content/constants/date-time.attributes';
import { ISchedulesAdvancedSearchFiltersModel } from '../../../../models/models';
import { getLocalEquivalentOfMidnightInProjectTimezone } from '../../../../helpers/article-ai-generation.helper';

type Properties = {
	schedulesFilters: ISchedulesAdvancedSearchFiltersModel;
	updateSchedulesAdvancedSearchText: (filters: ISchedulesAdvancedSearchFiltersModel) => void;
};

const ScheduledListingGenerationTimeAndDateSelect: FunctionComponent<Properties> = ({
	schedulesFilters,
	updateSchedulesAdvancedSearchText,
}) => {
	const [t] = useTranslation();
	const project = useSelector((state: AppState) => state.project.currentProject);
	const language = useSelector((state: AppState) => state.profile.profile.language);
	const [selectedDate, setSelectedDate] = useState<string>(schedulesFilters.date);
	const flatpickrRef = useRef<any>(null);

	useEffect(() => {
		setSelectedDate(schedulesFilters.date);
	}, [schedulesFilters.date]);

	const formatDateToISO = (date: Date | string) => {
		return moment(date).tz(project.timezone).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
	};

	return (
		<div className='input-fields-group' data-qa={DATA_QA.ARTICLE_AI_GENERATION_DATE_AND_TIME_SELECT}>
			<div className='datepicker-label-container'>
				<span className='input-fields-label'>{t('generation_date_and_time')}</span>
			</div>
			<div className='datepicker-container'>
				<div className='datepicker-wrapper'>
					<Flatpickr
						id={DATA_QA.ARTICLE_AI_GENERATION_DATE_AND_TIME_SELECT}
						className='article-generation-advanced-search-date-picker'
						options={{
							defaultDate: selectedDate || '',
							dateFormat: dateFormat,
							mode: 'single',
							time_24hr: true,
							enableTime: true,
							locale: langs[language],
							minDate: getLocalEquivalentOfMidnightInProjectTimezone(project.timezone),
							defaultHour: 0,
							defaultMinute: 11,
							onClose: (selectedDates) => {
								if (selectedDates.length === 1) {
									const newDate = formatDateToISO(selectedDates[0]);
									if (newDate !== selectedDate) {
										let advancedContentModel = schedulesFilters;
										advancedContentModel.date = newDate ? newDate : '';
										updateSchedulesAdvancedSearchText(advancedContentModel);
									}
								}
							},
						}}
						value={selectedDate}
						placeholder={t('select_date')}
						ref={flatpickrRef}
					/>
					<span className='calendar-icon' />
				</div>
			</div>
		</div>
	);
};

export default ScheduledListingGenerationTimeAndDateSelect;
