import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import {
	articleAiGenerationSelectStylesVariant,
	GENERATE_BASED_ON_OPTIONS,
	GenerationErrorState,
	SelectMenuOptionType,
} from '../../../helpers/article-ai-generation.helper';
import Select from 'react-select';
import { IScheduledContentGeneration } from '../../../models/models';
import '../../../styles/article-ai-generation-panel.scss';
import { DATA_QA } from '../../../helpers/data-qa.properties';
import { useDispatch } from 'react-redux';
import { updateScheduledGenerationContent } from '../../../../../../store/action-creators/ai-articles-action-creator';

type Properties = {
	scheduledGenerationContent: IScheduledContentGeneration;
	errorState: GenerationErrorState;
	errorMessage: string | undefined;
	setErrorState: (errorState: GenerationErrorState) => void;
};

const GenerateBaseOnField: FunctionComponent<Properties> = ({ scheduledGenerationContent, errorState, errorMessage, setErrorState }) => {
	const [t] = useTranslation();
	const dispatch = useDispatch();

	const hasErrorMessage = !!errorMessage && errorMessage.length > 0;

	const onValueSelect = (selectedOption: SelectMenuOptionType) => {
		const selected = (selectedOption && selectedOption.value) || null;

		dispatch(
			updateScheduledGenerationContent({
				...scheduledGenerationContent,
				generate_based_on: selected && selected,
				matches: [],
				team: null,
				generation_date: null,
				competition_ids: null,
				competition_events: null,
			}),
		);

		setErrorState({
			...errorState,
			generate_based_on: false,
			competition_events: false,
		});
	};

	const selectedValue = GENERATE_BASED_ON_OPTIONS(t).find((option) => option.value === scheduledGenerationContent.generate_based_on);

	return (
		<div className='input-fields-group' data-qa={DATA_QA.ARTICLE_AI_GENERATION_GENERATE_ARTICLE_GENERATE_BASED_ON}>
			<span className='input-fields-label'>{t('generate_article_based_on')}</span>
			{
				<Select
					id={DATA_QA.ARTICLE_AI_GENERATION_GENERATE_ARTICLE_GENERATE_BASED_ON}
					value={scheduledGenerationContent.generate_based_on ? selectedValue : null}
					options={GENERATE_BASED_ON_OPTIONS(t)}
					onChange={(selection) => onValueSelect(selection as SelectMenuOptionType)}
					placeholder={t('select')}
					// @ts-ignore because of styles
					styles={articleAiGenerationSelectStylesVariant(hasErrorMessage, '242px')}
				/>
			}
			{errorMessage && (
				<div className='error-container'>
					<span className='error-icon' />
					<div className='error-message'> {errorMessage} </div>
				</div>
			)}
		</div>
	);
};

export default GenerateBaseOnField;
