import { GalleryJson } from './GalleryJson';
import Gallery from './Gallery';
import Author from '../author/Author';
import Urls from '../urls/Urls';
import Seo from '../seo/Seo';
import Category from '../category/Category';
import { GalleryRequestJson } from './GalleryRequestJson';
import MainMedia from '../mainmedia/MainMedia';
import GalleryItem from './item/GalleryItem';
import { MainMediaJson } from '../mainmedia/MainMediaJson';
import ContentAttributes from '../content-attributes/content-attributes-model';
import BlockModel from '../../views/Partials/Blocky/models/block.model';
import { filterEmptyBlocks, removeTagsFromParagraphBlocks } from '../helpers/content-helpers';
import { DynamicContentProperty } from '../../constants/content-types';

export default class GalleryBuilder {
	private json: GalleryJson;

	constructor(gallery?: Gallery | GalleryJson) {
		if (gallery && gallery instanceof Gallery) {
			this.json = gallery.toJSON();
		} else if (gallery) {
			this.json = gallery;
		} else {
			this.json = {} as GalleryJson;
		}
	}

	withId(id: string): GalleryBuilder {
		this.json.id = id;

		return this;
	}

	withTitle(title: string): GalleryBuilder {
		this.json.title = title;

		return this;
	}

	withSubtitle(subtitle: string): GalleryBuilder {
		this.json.subtitle = subtitle;

		return this;
	}

	withStrapline(strapline: string): GalleryBuilder {
		this.json.strapline = strapline;

		return this;
	}

	withBody(body: BlockModel[]): GalleryBuilder {
		this.json.body = body;

		return this;
	}

	withPublishedAt(publishedAt: string): GalleryBuilder {
		this.json.publishedAt = publishedAt;

		return this;
	}

	withUpdatedAt(updatedAt: string): GalleryBuilder {
		this.json.updatedAt = updatedAt;

		return this;
	}

	withPublishedUntil(publishedUntil: string): GalleryBuilder {
		this.json.publishedUntil = publishedUntil;

		return this;
	}

	withStatus(status: string): GalleryBuilder {
		this.json.status = status;

		return this;
	}

	withComments(comments: any): GalleryBuilder {
		this.json.comments = comments;

		return this;
	}

	withCustomAuthor(customAuthor: string): GalleryBuilder {
		this.json.customAuthor = customAuthor;

		return this;
	}

	withAuthors(authors: Author[]): GalleryBuilder {
		this.json.authors = authors;

		return this;
	}

	withImage(image: any): GalleryBuilder {
		this.json.image = image;

		return this;
	}

	withGeneric(generic: any): GalleryBuilder {
		this.json.generic = generic;

		return this;
	}

	withUrls(urls: Urls): GalleryBuilder {
		this.json.urls = urls;

		return this;
	}

	withSeo(seo: Seo): GalleryBuilder {
		this.json.seo = seo;

		return this;
	}

	withCategory(category: Category): GalleryBuilder {
		this.json.category = category;

		return this;
	}

	withAdditionalCategories(additionalCategories: Category[]): GalleryBuilder {
		this.json.additionalCategories = additionalCategories;

		return this;
	}

	withMainMedia(mainMedia: MainMedia[]): GalleryBuilder {
		this.json.mainMedia = mainMedia;

		return this;
	}

	withItems(items: GalleryItem[]): GalleryBuilder {
		this.json.items = items;

		return this;
	}

	withAllowComments(allowComments: boolean): GalleryBuilder {
		this.json.allowComments = allowComments;

		return this;
	}

	withCreatedBy(createdBy: any): GalleryBuilder {
		this.json.createdBy = createdBy;

		return this;
	}

	withCreatedAt(createdAt: string): GalleryBuilder {
		this.json.createdAt = createdAt;

		return this;
	}

	withType(type: string): GalleryBuilder {
		this.json.type = type;

		return this;
	}

	withDistributionRegions(distributionRegions: ContentAttributes[]): GalleryBuilder {
		this.json.distributionRegions = distributionRegions;

		return this;
	}

	withDistributionChannels(distributionChannels: ContentAttributes[]): GalleryBuilder {
		this.json.distributionChannels = distributionChannels;

		return this;
	}

	withOrigin(origin: any): GalleryBuilder {
		this.json.origin = origin;

		return this;
	}

	withContentFooter(footer: string): GalleryBuilder {
		this.json.footer = footer;

		return this;
	}

	withViewsCount(viewsCount: any): GalleryBuilder {
		this.json.viewsCount = viewsCount;

		return this;
	}

	withCommentsCount(commentsCount: any): GalleryBuilder {
		this.json.commentsCount = commentsCount;

		return this;
	}

	withLanguage(language: any): GalleryBuilder {
		this.json.language = language;

		return this;
	}

	withContentUpdatedAt(contentUpdatedAt: string): GalleryBuilder {
		this.json.contentUpdatedAt = contentUpdatedAt;

		return this;
	}

	// this is used to set dynamic 'properties' placed in general content tab
	withDynamicProperties(dynamicProperties: DynamicContentProperty[]): GalleryBuilder {
		this.json.properties = dynamicProperties.reduce((obj, item) => Object.assign(obj, { [item.slug]: item.value }), {});

		return this;
	}

	toRequestJson(): GalleryRequestJson {
		const authorsForRequest = this.json.authors
			? this.json.authors.filter((author: any) => author && author.id).map((author: Author) => author.id)
			: [];

		let json = {} as GalleryRequestJson;
		json.seo = {
			slug: '',
			title: '',
			description: '',
			keywords: [],
			index: false,
			follow: false,
			redirect_type: '',
			jsonld: '',
			automatic_seo_title: false,
			automatic_slug: false,
		};

		json.id = this.json.id;
		json.title = this.json.title;
		json.subtitle = this.json.subtitle;
		json.strapline = this.json.strapline;
		json.body = removeTagsFromParagraphBlocks(filterEmptyBlocks(this.json.body));
		json.category_id = this.json && this.json.category ? this.json.category.id : '';
		json.published_at = this.json.publishedAt;
		json.updated_at = this.json.updatedAt;
		json.published_until = this.json.publishedUntil;
		json.status = this.json.status;
		json.comment_collection_id = '';
		json.authors = authorsForRequest;
		json.custom_author = this.json.customAuthor;
		json.language = this.json.language ? this.json.language : '';
		json.image_id = '';
		json.image_description = '';
		json.additional_categories = this.json.additionalCategories
			? this.json.additionalCategories.map((category: Category) => category.id)
			: [];
		json.generic = this.json.generic;
		json.footer = this.json.footer;
		json.published_regions =
			this.json.distributionRegions && this.json.distributionRegions.length > 0
				? this.json.distributionRegions.map((region: ContentAttributes) => region.id)
				: [];
		json.published_channels =
			this.json.distributionChannels && this.json.distributionChannels.length > 0
				? this.json.distributionChannels.map((channel: ContentAttributes) => channel.id)
				: [];
		json.origin_id = this.json.origin && this.json.origin.id ? this.json.origin.id : '';
		json.external_url = this.json.urls && this.json.urls.externalUrl ? this.json.urls.externalUrl : '';
		json.canonical_url = this.json.urls && this.json.urls.canonicalUrl ? this.json.urls.canonicalUrl : '';
		json.seo.slug = this.json.seo && this.json.seo.slug ? this.json.seo.slug.toLowerCase() : '';
		json.seo.title = this.json.seo && this.json.seo.title ? this.json.seo.title : '';
		json.seo.description = this.json.seo && this.json.seo.description ? this.json.seo.description : '';
		json.seo.keywords = this.json.seo && this.json.seo.keywords ? this.json.seo.keywords : [];
		json.seo.index = this.json.seo && this.json.seo.index ? this.json.seo.index : false;
		json.seo.follow = this.json.seo && this.json.seo.follow ? this.json.seo.follow : false;
		json.seo.redirect_type = this.json.seo && this.json.seo.redirectType ? this.json.seo.redirectType : '';
		json.seo.jsonld = this.json.seo && this.json.seo.jsonld ? this.json.seo.jsonld : '';
		json.seo.automatic_seo_title = this.json.seo && this.json.seo.autoTitle ? this.json.seo.autoTitle : false;
		json.seo.automatic_slug = this.json.seo && this.json.seo.autoSlug ? this.json.seo.autoSlug : false;
		json.allow_comments = this.json.allowComments !== undefined ? this.json.allowComments : false;
		json.type = this.json.type ? this.json.type : '';
		json.comment_policy_id =
			this.json.comments && this.json.comments.policy && this.json.comments.policy.id ? this.json.comments.policy.id : '';
		json.main_media = this.json.mainMedia
			? this.json.mainMedia.map((media: MainMediaJson) => {
					return {
						description: media.description,
						resource_id: media.resourceId,
						resource_type: media.resourceType,
						resource_subtype: media.resourceSubtype,
						provider: media.provider,
						data: media.data,
					};
			  })
			: [];
		json.items = this.json.items
			? this.json.items.map((item: GalleryItem) => {
					return {
						id: item.id,
						comment: item.comment,
						description: item.description,
						type: 'image',
					};
			  })
			: [];
		json.content_updated_at = this.json.contentUpdatedAt;
		// this will extract 'properties' object and will populate the 'json' object with its dynamic entities
		Object.keys(this.json.properties).forEach((propertyKey: string) => (json[propertyKey] = this.json.properties[propertyKey]));

		return json;
	}

	isEmpty(): boolean {
		if (this.json) {
			const isTitleSet = this.json.title && this.json.title.length > 0;
			const isStraplineSet = this.json.strapline && this.json.strapline.length > 0;
			const isSubtitleSet = this.json.subtitle && this.json.subtitle.length > 0;
			const isBodySet =
				this.json.body &&
				((this.json.body.length === 1 &&
					this.json.body[0].data.content &&
					this.json.body[0].data.content.replace(/(<([^>]+)>)/gi, '') !== '') ||
					this.json.body.length > 1);
			const isCategorySet = this.json.category;
			const isAdditionalCategoriesSet = this.json.additionalCategories && this.json.additionalCategories.length > 0;
			const isPublishedAtSet = this.json.publishedAt && this.json.publishedAt.length > 0;
			const isPublishedUntilSet = this.json.publishedUntil && this.json.publishedUntil.length > 0;
			const isStatusSet = this.json.status && this.json.status.length > 0;
			const isCustomAuthorSet = this.json.customAuthor && this.json.customAuthor.length > 0;
			const isSeoSet = !Seo.builder(this.json.seo).isEmpty();
			const isUrlsSet = !Urls.builder(this.json.urls).isEmpty();
			const isMainMediaSet = this.json.mainMedia && this.json.mainMedia.length > 0;
			const areItemsSet = this.json.items && this.json.items.length > 0;
			const isContentUpdatedAtSet = this.json.contentUpdatedAt && this.json.contentUpdatedAt.length > 0;

			return !(
				isTitleSet ||
				isStraplineSet ||
				isSubtitleSet ||
				isBodySet ||
				isPublishedAtSet ||
				isPublishedUntilSet ||
				isStatusSet ||
				isCustomAuthorSet ||
				isAdditionalCategoriesSet ||
				isSeoSet ||
				isUrlsSet ||
				isMainMediaSet ||
				isCategorySet ||
				areItemsSet ||
				isContentUpdatedAtSet
			);
		}

		return true;
	}

	build(): Gallery {
		return Gallery.fromJSON(this.json);
	}
}
