import React, { FC } from 'react';
import { useCompetitions } from '../../helpers/hooks/useCompetitions';
import { CompetitionSelectInput } from './competition-select-input.component';
import './competition-filter-modal.component.scss';
import { SportTypes } from '../../../../../../../constants/sport.types';

interface Props {
	competitionIds: string[];
	setCompetitionIds: React.Dispatch<React.SetStateAction<string[]>>;
}

const CompetitionSelect: FC<Props> = ({ competitionIds, setCompetitionIds }) => {
	const { competitions, isLoading } = useCompetitions(SportTypes.ICE_HOCKEY);

	if (isLoading) return null;

	const allCompetitionIds = competitions.flatMap(({ competitions }) => competitions.map(({ value }) => value));
	const withAllSelectedCompetitions = !competitionIds || (competitionIds && allCompetitionIds.length === competitionIds.length);

	return (
		<CompetitionSelectInput
			competitions={competitions}
			withAllSelectedCompetitions={withAllSelectedCompetitions}
			allCompetitionIds={allCompetitionIds}
			competitionIds={competitionIds}
			setCompetitionIds={setCompetitionIds}
		/>
	);
};

export default CompetitionSelect;
