import i18next from 'i18next';
import { CompetitionResponse } from '../../types/livescore';
import { IEventsResponse } from '../../types/livescore';
import { assetsPlaceholder } from '../../../../../../constants/assetsPlaceholder';
import { EventStatuses } from '../../../../../../constants/event.types';

export const sortCompetitionsByCountry = (competitions: CompetitionResponse[], t: i18next.TFunction) => {
	const competitionsByCountry = new Map();

	const getCountryInfo = (competition: CompetitionResponse) => {
		if (!competition.country) {
			return {
				name: t('tournament_filter_modal.empty_competition_name'),
				flagUrl: '',
			};
		}
		return {
			name: competition.country.name,
			flagUrl:
				(competition.country &&
					competition.country.display_asset &&
					competition.country.display_asset.url &&
					competition.country.display_asset.url) ||
				'',
		};
	};

	const transformCompetition = (comp: CompetitionResponse) => ({
		label: comp.name,
		value: comp.id,
		flag: (comp.country && comp.country.display_asset && comp.country.display_asset.url && comp.country.display_asset.url) || '',
		gender: comp.gender,
	});

	competitions.forEach((comp) => {
		const countryInfo = getCountryInfo(comp);
		if (!competitionsByCountry.has(countryInfo.name)) {
			competitionsByCountry.set(countryInfo.name, {
				flagUrl: countryInfo.flagUrl,
				competitions: [],
			});
		}
		competitionsByCountry.get(countryInfo.name).competitions.push(transformCompetition(comp));
	});

	const sortedResult = Array.from(competitionsByCountry.entries())
		.sort(([countryA], [countryB]) => countryA.localeCompare(countryB))
		.map(([country, data]) => ({
			country,
			flagUrl: data.flagUrl,
			competitions: data.competitions,
		}));

	return sortedResult;
};

export const sortEventsByCompetitionAndDate = (events: IEventsResponse[]) => {
	const eventsByDate = new Map<
		string,
		{
			date: string;
			competitions: Map<string, { competition_name: string; competition_id: string; competition_logo: string; events: IEventsResponse[] }>;
		}
	>();

	const transformEvent = (event: IEventsResponse) => ({
		id: event.id,
		name: event.name,
		start_time: event.start_time,
	});

	// Filter only events that have a status of NOT_STARTED
	const filteredEvents = events.filter((event) => event.status_type === EventStatuses.UPCOMING);

	filteredEvents.forEach((event) => {
		const eventDate = event.start_time.split('T')[0]; // Extract YYYY-MM-DD
		const competition_id = event.competition_id;
		const competition_name = event.competition && event.competition.name ? event.competition.name : 'Unknown Competition';
		const competition_logo =
			event.competition && event.competition.display_asset ? event.competition.display_asset.url : assetsPlaceholder.competition;

		// Initialize the date group if not existing
		if (!eventsByDate.has(eventDate)) {
			eventsByDate.set(eventDate, { date: eventDate, competitions: new Map() });
		}

		// Get the date group
		const dateGroup = eventsByDate.get(eventDate)!;

		// Initialize the competition group within the date
		if (!dateGroup.competitions.has(competition_id)) {
			dateGroup.competitions.set(competition_id, { competition_name, competition_id, competition_logo, events: [] });
		}

		// Push the event into the corresponding competition group
		dateGroup.competitions.get(competition_id)!.events.push(transformEvent(event));
	});

	// Convert the Map to an array, sorting first by date, then by competition name
	const sortedResult = Array.from(eventsByDate.values())
		.sort((a, b) => a.date.localeCompare(b.date)) // Sort by date (ascending)
		.map(({ date, competitions }) => ({
			date,
			competitions: Array.from(competitions.values()).sort((a, b) => a.competition_name.localeCompare(b.competition_name)), // Sort competitions alphabetically within each date
		}));

	return sortedResult;
};
