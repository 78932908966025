import React, { FunctionComponent, useEffect, useState } from 'react';
import { DATA_QA } from '../helpers/data-qa.properties';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import '../styles/article-ai-generation-panel.scss';
import { ArticleAiGenerationTabs } from '../helpers/article-ai-generation.helper';
import { ArticleGenerateScreen } from './mainComponents/generate-article-screen';
import { AppState } from '../../../../store/store';
import SchedulesListingScreen from './mainComponents/schedules-listing-screen';
import { fetchSchedulesCount } from '../helpers/article-ai-requests.helper';
import AiArticlesListing from '../../ContentListing/components/article/ai-articles-listing';
import {
	updateAiSchedulesAdvancedSearchFilters,
	updateScheduledGenerationContent,
} from '../../../../store/action-creators/ai-articles-action-creator';
import { initialScheduledState, initialSchedulesAdvancedFiltersState } from '../models/models';

const ArticleAiGenerationPanel: FunctionComponent = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const user = useSelector((state: AppState) => state.profile);
	const scheduledContent = useSelector((state: AppState) => state.aiArticles.scheduledGenerationContent);
	const schedulesCount = useSelector((state: AppState) => state.aiArticles.schedulesCount);
	const [selectedTab, setSelectedTab] = useState<ArticleAiGenerationTabs>(ArticleAiGenerationTabs.GENERATE_ARTICLE);
	let activityInterval: NodeJS.Timeout | null = null;

	useEffect(() => {
		fetchSchedulesCount(dispatch);
		activityInterval = setInterval(() => fetchSchedulesCount(dispatch), 60000);

		dispatch(
			updateScheduledGenerationContent({
				...scheduledContent,
				user_id: user.profile.id,
				user_name: user.profile.name,
			}),
		);

		return () => {
			if (activityInterval) clearInterval(activityInterval);

			dispatch(
				updateScheduledGenerationContent({
					...initialScheduledState,
				}),
			);

			dispatch(
				updateAiSchedulesAdvancedSearchFilters({
					...initialSchedulesAdvancedFiltersState,
				}),
			);
		};
	}, []);

	const renderTabContent = () => {
		switch (selectedTab) {
			case ArticleAiGenerationTabs.GENERATE_ARTICLE:
				return (
					<div id={DATA_QA.ARTICLE_AI_GENERATION_WRAPPER}>
						<div>
							<ArticleGenerateScreen scheduledGenerationContent={scheduledContent} />
						</div>
					</div>
				);
			case ArticleAiGenerationTabs.SCHEDULED:
				return (
					<div id={DATA_QA.ARTICLE_AI_SCHEDULES_LISTING_WRAPPER}>
						<SchedulesListingScreen />
					</div>
				);
			case ArticleAiGenerationTabs.CREATED:
				return (
					<div data-qa={DATA_QA.ARTICLE_AI_CREATED_ARTICLES_LISTING_WRAPPER}>
						<AiArticlesListing />
					</div>
				);
			default:
				return null;
		}
	};

	return (
		<>
			<div id={DATA_QA.ARTICLE_AI_GENERATION_TABS_MENU}>
				<div
					className={`tab-option ${selectedTab === ArticleAiGenerationTabs.GENERATE_ARTICLE ? 'selected-tab' : ''}`}
					style={selectedTab === ArticleAiGenerationTabs.GENERATE_ARTICLE ? { backgroundColor: '#FFF' } : { backgroundColor: '#FAFAFA' }}
					onClick={() => setSelectedTab(ArticleAiGenerationTabs.GENERATE_ARTICLE)}
				>
					<span className='tab-label'>{t('generate_article')}</span>
				</div>
				<div
					className={`tab-option ${selectedTab === ArticleAiGenerationTabs.SCHEDULED ? 'selected-tab' : ''}`}
					onClick={() => setSelectedTab(ArticleAiGenerationTabs.SCHEDULED)}
				>
					<div className='tab-option-container'>
						<span className='tab-label'>{t('schedules')}</span>
						{schedulesCount > 0 && (
							<div className='schedules-bubble-count'>
								<span className='schedules-count-label'>{schedulesCount}</span>
							</div>
						)}
					</div>
				</div>
				<div
					className={`tab-option ${selectedTab === ArticleAiGenerationTabs.CREATED ? 'selected-tab' : ''}`}
					onClick={() => setSelectedTab(ArticleAiGenerationTabs.CREATED)}
				>
					<span className='tab-label'>{t('created_articles')}</span>
				</div>
			</div>
			{renderTabContent()}
		</>
	);
};

export default ArticleAiGenerationPanel;
