import React, { ChangeEvent } from 'react';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Title } from '../../Partials/Fields/title/TitleComponent';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Project from '../../../models/project/Project';
import List from '../../../models/list/List';
import { Properties, State } from './Properties/ListCreateProperties';
import {
	LIST_ENTITY_CREATE,
	LIST_ENTITY_CREATE_FAILED,
	LIST_ENTITY_CREATE_SUCCESS,
	returnObjectForListEntityCreate,
} from '../../../store/action-creators/ListActionCreators';
import ListConfiguration from '../../../models/list/configuration/ListConfiguration';
import BlockableButtonWrapper from '../../Partials/BaseComponents/BlockableButton/BlockableButtonWrapper';
import ErrorAsync from '../../Partials/BaseComponents/ErrorComponent/ErrorAsync';
import { ContentTypes, ListContentTypes } from '../../../constants/content-types';
import GeneralContentTypeAttribute from '../../Partials/Sidebar/general-content-attribute-custom/general-content-attribute-custom';
import { GeneralContentCustomModel } from '../../Partials/Sidebar/general-content-attribute-custom/models/general-content-attribute-custom.model';
import SidebarCollapsibleElements from '../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-collapsible-elements';
import SidebarElementsToggle from '../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-elements-toggle/sidebar-elements-toggle.component';
import Category from '../../../models/category/Category';
import CategorySelectContainer from '../../Partials/Sidebar/GeneralContentAttributes/subcomponents/CategorySelect/CategorySelectContainer';
import FootballConnectionsContainer from '../../Partials/Sidebar/tags/subcomponents/football-connections/football-connections-container';
import Related from '../../../models/related/Related';
import { featuresService, sportsConnectionsService } from '../../../App';
import { SportsTypeSelect } from '../../Partials/Shared/advanced-content-filter/subcomponents/v2/sports-select/sports-type-select.component';
import {
	filterFootballRelated,
	removeDuplicateItems,
} from '../../Partials/Shared/advanced-content-filter/subcomponents/v2/sports-select/sports-connections.helper';
import { SportsConnectionsContainer } from '../../Partials/Shared/advanced-content-filter/subcomponents/v2/sports-select/sports-connections-container';
import SportsTypesModel from '../../../models/v2/sports-types/sports-types.model';
import { FeatureTypes } from '../../../services/feature-service/features.enum';
import {
	ContentSidebarChangeCollapseStatus,
	onChangeCollapseStatus,
	onSidebarElemSort,
	toggleSidebarEditMode,
} from '../../../services/content-sidebar/content-sidebar.helper';
import { FormattedSidebarSectionValue } from '../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.types';
import GlobalModal from '../../Partials/Modals/Global/global-modal';
import {
	appendBeforeUnloadResetTempSidebar,
	defaultTempSidebarFromApiResponse,
	overwriteTempSidebarByContentType,
	saveSidebarSettingsByContentType,
} from '../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.helper';
import { getListType, getListTypeContentTranslated, getListTypeEditsUrl, isAutomatedLists } from './helpers/list.helper';
import { EditorialTypeItemsMultiselect, AutomaticContentSelect, TimeRangeSelect, FrequencySelect } from './subcomponents';
import {
	editorialTypesSelectOptions,
	automaticContentSelectOptions,
	timeRangeSelectOptions,
	frequencySelectOptions,
} from '../../../constants/automatic-lists-constants';
import PopularitySettings from '../../../models/list/popularity-settings/PopularitySettings';
import AutomaticListCriteria from '../../../models/list/criteria/AutomaticListCriteria';

class ListCreate extends React.Component<Properties, State> {
	componentDidMount(): void {
		this.initPageTitle();
		this.initListCreateSuccessListener();
		appendBeforeUnloadResetTempSidebar(ContentTypes.LIST);

		let langCode = this.props.project.language;

		this.setState({ ...this.state, contentType: this.state.list.contentType });
	}

	initPageTitle() {
		document.title = this.props.t('list_create');
	}

	//TODO remove related filtration logic when the football data is migrated to sports-search-api
	constructor(props: Properties) {
		super(props);

		const listBuilder = List.builder()
			.withType('')
			.withTitle('')
			.withStatus('')
			.withSlug('')
			.withConfiguration({ minItems: '0', maxItems: '0' } as ListConfiguration)
			.withContentType(getListType());

		if (isAutomatedLists()) {
			listBuilder.withConfiguration({ minItems: '', maxItems: '' } as ListConfiguration);
			listBuilder.withCriteria({} as AutomaticListCriteria);
			listBuilder.withAutomaticContent(true);
			listBuilder.withPopularitySettings({} as PopularitySettings);
		}

		this.state = {
			isSidebarInEdit: false,
			list: listBuilder.build(),
			contentType: '',
			sportsType: featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL, FeatureTypes.FOOTBALL_CONNECTIONS])
				? this.props.sports[0]
				: ({} as SportsTypesModel),
			footballRelated: [],
			sportsRelated: [],
			openSidebarSettingsModalFlag: false,
		};
	}

	initListCreateSuccessListener() {
		window.addEventListener(LIST_ENTITY_CREATE_SUCCESS, (data: any) => {
			const { editUrl } = getListTypeEditsUrl(data.detail);
			this.props.history.push(editUrl);
		});
	}

	onTitleChange(title: string) {
		this.setState({ list: List.builder(this.state.list).withTitle(title).build() });
	}

	onSlugChange(event: any) {
		const list = List.builder(this.state.list).withSlug(event.target.value).build();
		this.setState({ list });
	}

	onMinItemsChange(event: any) {
		const configuration = ListConfiguration.builder(this.state.list.configuration).withMinItems(event.target.value).build();

		const list = List.builder(this.state.list).withConfiguration(configuration).build();
		this.setState({ list });
	}

	onMaxItemsChange(event: any) {
		const configuration = ListConfiguration.builder(this.state.list.configuration).withMaxItems(event.target.value).build();

		const list = List.builder(this.state.list).withConfiguration(configuration).build();
		this.setState({ list });
	}

	onEditorialItemsChange(event: ChangeEvent<HTMLInputElement>) {
		this.setState({
			list: List.builder(this.state.list).withAutomaticContentThreshold(event.target.value).build(),
		});
	}

	blockIfNotPositive = (value: string) => {
		if (typeof value === 'string') {
			if (value === '') {
				return true;
			}

			if (value.match(/[-]/g)) {
				return true;
			}
		}

		return false;
	};

	onSubmit() {
		this.props.postList(this.state.list, this.props.project);
	}

	onGeneralContentChange(data: GeneralContentCustomModel) {
		let list = List.builder(this.state.list)
			.withType(data.type ? data.type : '')
			.withStatus(data.status)
			.build();
		this.setState({ ...this.state, list });
	}

	onCategoryChange = (category: any) => {
		const { list } = this.state;
		const listConfig = ListConfiguration.builder(list.configuration).withCategory(category).build();
		this.setState({
			list: List.builder(list).withConfiguration(listConfig).build(),
		});
	};

	toggleSidebarEditMode = () => {
		const newState = toggleSidebarEditMode({ ...this.state }, ContentTypes.LIST);
		this.setState(newState);
	};

	//TODO unify onSportsDataChange when the football data is migrated to sports-search-api
	onFootballConnectionsChange = (football: Related[]) => {
		const { list } = this.state;
		const footballRelatedCopy = football.concat(this.state.sportsRelated);
		this.setState({ footballRelated: football });
		const listConfig = ListConfiguration.builder(list.configuration).withSports(footballRelatedCopy).build();
		this.setState({
			list: List.builder(list).withConfiguration(listConfig).build(),
		});
	};

	onSportsConnectionsChange = (related: Related[]) => {
		const { list, footballRelated, sportsRelated, sportsType } = this.state;

		const tempData = sportsRelated.filter((element: Related) => element.data.sport !== sportsType.sport);
		const relatedData = related.concat(tempData);

		const sportsRelatedCopy = relatedData.concat(footballRelated);
		this.setState({ sportsRelated: removeDuplicateItems(sportsRelatedCopy) });
		const listConfig = ListConfiguration.builder(list.configuration).withSports(removeDuplicateItems(sportsRelatedCopy)).build();
		this.setState({
			list: List.builder(list).withConfiguration(listConfig).build(),
		});
	};

	onSportsTypesSelect = (type: any) => {
		this.setState({ ...this.state, sportsType: type });
	};

	onEditorialTypesSelect = (editorialTypes: string[]) => {
		const criteria = AutomaticListCriteria.builder(this.state.list.criteria).withEditorialTypes(editorialTypes).build();

		this.setState({
			list: List.builder(this.state.list).withCriteria(criteria).build(),
		});
	};

	onAutomaticContentModeSelect = (automaticContentMode: string) => {
		this.setState({
			list: List.builder(this.state.list).withAutomaticContentMode(automaticContentMode).build(),
		});
	};

	onTimeRangeSelect = (value: string) => {
		const popularitySettings = PopularitySettings.builder(this.state.list.popularitySettings).withTimeRange(value).build();

		this.setState({
			list: List.builder(this.state.list).withPopularitySettings(popularitySettings).build(),
		});
	};

	onDataSourceSelect = (value: string) => {
		const popularitySettings = PopularitySettings.builder(this.state.list.popularitySettings).withDataSource(value).build();

		this.setState({
			list: List.builder(this.state.list).withPopularitySettings(popularitySettings).build(),
		});
	};

	onFrequencySelect = (value: string) => {
		const popularitySettings = PopularitySettings.builder(this.state.list.popularitySettings).withFrequency(value).build();

		this.setState({
			list: List.builder(this.state.list).withPopularitySettings(popularitySettings).build(),
		});
	};

	render() {
		const { t, types, statuses, allCategories, adminCategories, project, sports } = this.props;
		const { list, isSidebarInEdit, contentType, sportsType, sportsRelated, footballRelated, openSidebarSettingsModalFlag } = this.state;
		const shouldShowSidebar = (types && types.length > 0) || (statuses && statuses.length > 0);
		const style = shouldShowSidebar ? '8' : '12';
		const sidebarComponentsMap = {
			general: (
				<GeneralContentTypeAttribute
					onChange={this.onGeneralContentChange.bind(this)}
					value={{ type: list.type, status: list.status }}
					contentType={ContentTypes.LIST}
					isNewContent={true}
				/>
			),
		};

		const overRideValidation = isAutomatedLists()
			? (list.configuration && !list.configuration.maxItems) ||
			  (list.configuration && !list.configuration.minItems) ||
			  !list.automaticContentThreshold ||
			  !list.title ||
			  !list.automaticContentMode ||
			  (list.automaticContentMode !== 'most_popular' && !list.criteria.entityTypes) ||
			  (list.automaticContentMode !== 'most_popular' && list.criteria.entityTypes && !list.criteria.entityTypes.length) ||
			  (list.automaticContentMode === 'most_popular' && !list.popularitySettings.frequency) ||
			  (list.automaticContentMode === 'most_popular' && !list.popularitySettings.timeRange)
			: list.configuration && (this.blockIfNotPositive(list.configuration.maxItems) || this.blockIfNotPositive(list.configuration.minItems));
		const releaseActionValidation = isAutomatedLists()
			? (list.configuration && !list.configuration.maxItems) ||
			  (list.configuration && !list.configuration.minItems) ||
			  !list.automaticContentThreshold ||
			  !list.title ||
			  !list.automaticContentMode ||
			  (list.automaticContentMode !== 'most_popular' && !list.criteria.entityTypes) ||
			  (list.automaticContentMode !== 'most_popular' && list.criteria.entityTypes && !list.criteria.entityTypes.length) ||
			  (list.automaticContentMode === 'most_popular' && !list.popularitySettings.frequency) ||
			  (list.automaticContentMode === 'most_popular' && !list.popularitySettings.timeRange)
			: list.configuration && (this.blockIfNotPositive(list.configuration.maxItems) || this.blockIfNotPositive(list.configuration.minItems));

		return (
			<div className='animated fadeIn'>
				<Row>
					<Col col={style} lg={style} md='12' sm='12' xs='12'>
						<div className='card'>
							<div className='card-header'>
								<div className={'d-flex align-items-center'}>
									<span className={'mr-auto mb-0'}>{t('list_create_title')}</span>
									<BlockableButtonWrapper
										blockOnActions={[LIST_ENTITY_CREATE]}
										blockOverride={overRideValidation}
										releaseOnActions={[LIST_ENTITY_CREATE_SUCCESS, LIST_ENTITY_CREATE_FAILED] || releaseActionValidation}
									>
										<Button size='sm' color='primary' id='list-create-save-top' className={'ml-auto'} onClick={this.onSubmit.bind(this)}>
											<i className='fa fa-floppy-o'></i> {t('save_list')}
										</Button>
									</BlockableButtonWrapper>
								</div>
							</div>
							<div className='card-body'>
								<Row>
									<Col xs='12'>
										<Label className='font-weight-bold' htmlFor='content-type'>
											{t('content_type')}
										</Label>
										<div className={`tip-container p-3 mb-3 my-2`}>
											<div className='tip-message pb-1'>
												<strong className='mt-1 info '>{getListTypeContentTranslated()}</strong>
											</div>
										</div>
									</Col>
									<Col xs='12'>
										<Label htmlFor='title'>
											{t('title')} <span className='text-danger'>*</span>
										</Label>

										<Title t={t} onChange={this.onTitleChange.bind(this)} value={this.state.list.title} dataQaProperty={'default-title'} />
									</Col>
								</Row>

								<Row className={'mb-3'}>
									<Col xs='12'>
										<Label htmlFor='slug'>{t('slug')}</Label>
										<Input placeholder={t('slug')} value={this.state.list ? this.state.list.slug : ''} onChange={this.onSlugChange.bind(this)} />
										<ErrorAsync t={t} errorType='slug' />
									</Col>
								</Row>

								{list.contentType !== ListContentTypes.SCHEDULED && (
									<Row className={'mb-3'}>
										<Col xs='6'>
											<Label htmlFor='title'>
												{t('min_items')} {isAutomatedLists() && <span className='text-danger'>*</span>}
											</Label>
											<Input
												type={'number'}
												min='0'
												value={this.state.list && this.state.list.configuration ? this.state.list.configuration.minItems : '0'}
												placeholder={t('min_items_placeholder')}
												onChange={this.onMinItemsChange.bind(this)}
											/>
											{list.configuration && this.blockIfNotPositive(list.configuration.minItems) && !isAutomatedLists() && (
												<div className='mt-1 alert alert-danger'>{t('positive_value_required')}</div>
											)}
										</Col>
										<Col xs='6'>
											<Label htmlFor='title'>
												{t('max_items')} {isAutomatedLists() && <span className='text-danger'>*</span>}
											</Label>
											<Input
												type={'number'}
												min='0'
												value={this.state.list && this.state.list.configuration ? this.state.list.configuration.maxItems : '0'}
												placeholder={t('max_items_placeholder')}
												onChange={this.onMaxItemsChange.bind(this)}
											/>
											{list.configuration && this.blockIfNotPositive(list.configuration.maxItems) && !isAutomatedLists() && (
												<div className='mt-1 alert alert-danger'>{t('positive_value_required')}</div>
											)}
										</Col>
									</Row>
								)}

								{contentType !== 'metadata' && (
									<Row>
										<Col xs='6'>
											<Label htmlFor='content-main-category-select'>{t('main_category')}</Label>
											<CategorySelectContainer
												inputId='content-main-category-select'
												t={t}
												isClearable={true}
												categories={allCategories}
												adminCategories={adminCategories}
												selectedCategory={list.configuration && list.configuration.category ? list.configuration.category : Category.builder().build()}
												onCategorySelect={this.onCategoryChange}
											/>
										</Col>
										<Col xs='6'>
											<SportsTypeSelect sports={sports} t={t} selectedSportsType={sportsType} onSportsTypeSelect={this.onSportsTypesSelect} />
										</Col>
										{sportsType && sportsConnectionsService.isFootballSelected(sportsType.sport) ? (
											<Col md='6'>
												<Label htmlFor='tags'>{t('football_connections')}</Label>
												<FootballConnectionsContainer
													related={filterFootballRelated(footballRelated)}
													onSelect={this.onFootballConnectionsChange}
													footballApiUrl={project.footballApiUrl}
													allowMoreFootballConnections={true}
													withSeason={false}
													inputId={'football-connections-drop-down'}
													t={t}
													contentLanguage={this.props.project.language}
												/>
											</Col>
										) : (
											sportsType.sport && (
												<Col md='6'>
													<Label htmlFor='sports-connections'>{t('sports_connections')}</Label>
													<SportsConnectionsContainer
														related={sportsRelated}
														onSportsConnectionSelect={this.onSportsConnectionsChange}
														contentLanguage={this.props.project.language}
														t={t}
														sportType={sportsType.sport}
														sportsEntities={sportsType.entity_types}
														allowMoreEntityTypes={true}
													/>
												</Col>
											)
										)}
									</Row>
								)}
								{list.contentType === ListContentTypes.AUTOMATED && (
									<Row className={'mb-3'}>
										<Col xs='6'>
											<Label htmlFor='title'>
												{t('number_of_automatic_items')} <span className='text-danger'>*</span>
											</Label>
											<Input
												type={'number'}
												min='0'
												value={this.state.list ? this.state.list.automaticContentThreshold : '0'}
												placeholder={t('number_of_automatic_items_placeholder')}
												onChange={this.onEditorialItemsChange.bind(this)}
											/>
										</Col>
										<Col xs='6'>
											<Label htmlFor='type-of-automatic-items-multiselect'>
												{t('type_of_automatic_items')} {list.automaticContentMode === 'most_popular' ? '' : <span className='text-danger'>*</span>}
											</Label>
											<EditorialTypeItemsMultiselect
												inputId='type-of-automatic-items-multiselect'
												isClearable
												options={editorialTypesSelectOptions}
												editorialTypes={this.state.list.criteria.entityTypes || []}
												onEditorialTypesSelect={this.onEditorialTypesSelect}
											/>
										</Col>
									</Row>
								)}
								{list.contentType === ListContentTypes.AUTOMATED && (
									<>
										<Row className={'mt-3'}>
											<Col xs='6'>
												<Label htmlFor='automatic-content-select'>
													{t('automatic_content')} <span className='text-danger'>*</span>
												</Label>
												<AutomaticContentSelect
													inputId='automatic-content-select'
													isClearable
													options={automaticContentSelectOptions}
													automaticContentMode={this.state.list.automaticContentMode}
													onDataSourceSelect={this.onDataSourceSelect}
													onAutomaticContentModeSelect={this.onAutomaticContentModeSelect}
												/>
											</Col>
										</Row>
										{this.state.list.automaticContentMode === 'most_popular' && (
											<Row className={'mt-3'}>
												<Col xs='6'>
													<Label htmlFor='time-range-select'>
														{t('time_range')} <span className='text-danger'>*</span>
													</Label>
													<TimeRangeSelect
														inputId='time-range-select'
														isClearable
														options={timeRangeSelectOptions}
														timeRange={this.state.list.popularitySettings.timeRange}
														onTimeRangeSelect={this.onTimeRangeSelect}
													/>
												</Col>
												<Col xs='6'>
													<Label htmlFor='frequency-select'>
														{t('update_frequency')} <span className='text-danger'>*</span>
													</Label>
													<FrequencySelect
														inputId='frequency-select'
														isClearable
														options={frequencySelectOptions}
														frequency={this.state.list.popularitySettings.frequency}
														onFrequencySelect={this.onFrequencySelect}
													/>
												</Col>
											</Row>
										)}
									</>
								)}
								<Row className={'mt-4'}>
									<Col xs='12'>
										<BlockableButtonWrapper
											blockOnActions={[LIST_ENTITY_CREATE]}
											blockOverride={overRideValidation}
											releaseOnActions={[LIST_ENTITY_CREATE_SUCCESS, LIST_ENTITY_CREATE_FAILED] || releaseActionValidation}
										>
											<Button
												color={'primary'}
												id='list-create-save-bottom'
												className={'text-uppercase font-weight-bold'}
												onClick={this.onSubmit.bind(this)}
												disabled={Number(this.state.list.configuration.maxItems) < 0 || Number(this.state.list.configuration.minItems) < 0}
											>
												<i className='fa fa-floppy-o'></i> {t('save_list')}
											</Button>
										</BlockableButtonWrapper>
									</Col>
								</Row>
							</div>
						</div>
					</Col>
					{shouldShowSidebar && (
						<Col col='4' lg='4' md='12' sm='12' xs='12' className='position-relative'>
							<SidebarElementsToggle t={t} toggleSidebarEditMode={this.toggleSidebarEditMode} isSidebarInEdit={isSidebarInEdit} />
							<SidebarCollapsibleElements
								isSidebarInEdit={isSidebarInEdit}
								onElemSort={(settingsValue: FormattedSidebarSectionValue[]) => onSidebarElemSort(settingsValue, ContentTypes.LIST)}
								onChangeCollapseStatus={(data: ContentSidebarChangeCollapseStatus) => onChangeCollapseStatus(data)}
								contentType={ContentTypes.LIST}
								sidebarComponentsMap={sidebarComponentsMap}
								t={t}
							/>
						</Col>
					)}
				</Row>
				<GlobalModal
					isOpen={openSidebarSettingsModalFlag}
					t={this.props.t}
					headerContent='save_sidebar_settings'
					bodyContent='unsaved_changes'
					submitFunction={() => saveSidebarSettingsByContentType(ContentTypes.LIST, t)}
					beforeCloseFunction={() =>
						overwriteTempSidebarByContentType(ContentTypes.LIST, defaultTempSidebarFromApiResponse[ContentTypes.LIST].value)
					}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		list: state.list,
		project: state.project.currentProject,
		profile: state.profile.profile,
		types: state.types.listTypes,
		statuses: state.statuses.listStatuses,
		allCategories: state.category.allCategories,
		adminCategories: state.category.adminCategories,
		sports: state.sports.sportsTypes,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		postList: (list: List, project: Project) => dispatch(returnObjectForListEntityCreate(list, project)),
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(ListCreate) as React.ComponentType;
