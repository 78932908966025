import GalleryBlockModel from '../../models/gallery-block.model';
import { store } from '../../../../../../../../store/store';

export const STEPS = ['info', 'images'];

export const STEPS_BUTTON_TEXT = {
	info: 'Continue to adding images',
	images: 'Save images and choose cover image',
};

export const galleryToJsonRequest = (gallery: GalleryBlockModel, copyAdditionalCategories: boolean) => {
	const contentLanguages = store.getState().project.currentProject;

	const json = {
		title: gallery.title ? gallery.title : '',
		category_id: gallery.category && gallery.category.id ? gallery.category.id : '',
		status: 'active',
		items:
			gallery.items && gallery.items.length > 0
				? gallery.items.map((item: any) => {
						return {
							id: item.id,
							comment: null,
							type: 'image',
						};
				  })
				: [],
		additional_categories: [],
		main_media: {},
		language: '',
	};

	json.language = contentLanguages.language;

	if (copyAdditionalCategories && gallery.additionalCategories) {
		json.additional_categories = gallery.additionalCategories.map((category: any) => category.id);
	}

	if (gallery.mainImage && Object.entries(gallery.mainImage).length > 0) {
		json.main_media = [constructGalleryMainMediaImage(gallery.mainImage)];
	}

	return json;
};

export const constructGalleryMainMediaImage = (image: any) => {
	return {
		resource_id: image.id,
		resource_type: 'image',
		resource_subtype: 'main_image',
		provider: 'smp',
	};
};

export const constructGalleryRelatedContent = (copySportsRelated: boolean, copyTagsRelated: boolean, related: any) => {
	const relatedBody = [];

	if (related.sports && related.sports.length > 0 && copySportsRelated) {
		relatedBody.push(...related.sports);
	}

	if (related.matches && related.matches.length > 0 && copySportsRelated) {
		relatedBody.push(...related.matches);
	}

	if (related.tags && related.tags.length > 0 && copyTagsRelated) {
		relatedBody.push(
			...related.tags.map((tag: any) => {
				tag.data = tag.data.id;

				return tag;
			}),
		);
	}

	return relatedBody;
};
