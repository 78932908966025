import React, { FunctionComponent, useEffect, useState } from 'react';
import { IScheduledContentGeneration } from '../../models/models';
import { DATA_QA } from '../../helpers/data-qa.properties';
import { useTranslation } from 'react-i18next';
import '../../styles/article-ai-generation-panel.scss';
import {
	formatDataForPostRequest,
	GENERATE_TYPES_OPTIONS,
	GenerateArticleBasedOn,
	GenerateArticleRequestStatuses,
	GenerationErrorState,
	getArticleGenerationInfoLabel,
	getArticleGenerationInfoLabelBasedOnDate,
} from '../../helpers/article-ai-generation.helper';
import CategorySelect from '../subcomponents/configurationFields/category-select';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../../store/store';
import GenericGenerateField from '../subcomponents/configurationFields/generic-generate-field';
import GenerateBaseOnField from '../subcomponents/configurationFields/generate-based-on-select';
import TeamSelect from '../subcomponents/configurationFields/article-generation-team-select';
import EventSelect from '../subcomponents/configurationFields/article-generation-event-select';
import ArticleGenerationInfoPanel from '../subcomponents/configurationFields/generate-article-info-panel';
import ScheduleGenerationButton from '../subcomponents/schedule-button';
import { SportTypes } from '../../../../../constants/sport.types';
import CompetitionsSelect from '../subcomponents/competition-filer/competitions-select.component';
import { IEventsByDate } from '../../../../Partials/Blocky/v2/types/livescore';
import { showCustomToast } from '../subcomponents/schedules-listing-components/subcomponents/custom-toast-norification';
import { useGenerateSchedule } from '../../helpers/article-ai-requests.helper';
import { updateScheduledGenerationContent } from '../../../../../store/action-creators/ai-articles-action-creator';
import { ContentTypes } from '../../../../../constants/content-types';
import { GenerationDateToPicker } from '../subcomponents/configurationFields/generation-date-to';
import { GenerationDateFromPicker } from '../subcomponents/configurationFields/generation-date-from';

interface Props {
	scheduledGenerationContent: IScheduledContentGeneration;
}

export const ArticleGenerateScreen: FunctionComponent<Props> = ({ scheduledGenerationContent }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const project = useSelector((state: AppState) => state.project.currentProject);
	const [competitionEvents, setCompetitionEvents] = useState<IEventsByDate[]>([]);
	const [errorState, setErrorState] = useState<GenerationErrorState>({
		category: false,
		matches: false,
		generate_based_on: false,
		competition_events: false,
	});

	const { mutate: generateSchedule, isLoading } = useGenerateSchedule(dispatch);

	const onGenerateSchedule = () => {
		const postMatchesData =
			scheduledGenerationContent && scheduledGenerationContent.generate_based_on === GenerateArticleBasedOn.EVENT
				? scheduledGenerationContent.matches
				: scheduledGenerationContent.competition_events;

		setErrorState({
			...errorState,
			category: !scheduledGenerationContent.category,
			matches: !(scheduledGenerationContent.matches && scheduledGenerationContent.matches.length > 0),
			generate_based_on: !scheduledGenerationContent.generate_based_on,
			competition_events: !(scheduledGenerationContent.competition_events && scheduledGenerationContent.competition_events.length > 0),
		});

		if (
			scheduledGenerationContent &&
			scheduledGenerationContent.category &&
			scheduledGenerationContent.category.id &&
			postMatchesData &&
			postMatchesData.length > 0 &&
			scheduledGenerationContent.generate_based_on
		) {
			const dataForRequestSchedule = formatDataForPostRequest(scheduledGenerationContent);
			generateSchedule(dataForRequestSchedule);
		} else {
			showCustomToast(t('error_generating_schedule'), t('fill_required_fields'), GenerateArticleRequestStatuses.ERROR);
		}
	};

	const onCompetitionIdsChange = (ids: string[]) => {
		dispatch(
			updateScheduledGenerationContent({
				...scheduledGenerationContent,
				competition_ids: ids && ids.length > 0 ? ids : null,
			}),
		);
	};

	const onCompetitionEventsChange = (updatedEvents: IEventsByDate[]) => {
		setCompetitionEvents(updatedEvents);

		dispatch(
			updateScheduledGenerationContent({
				...scheduledGenerationContent,
				competition_events: competitionEvents,
			}),
		);
	};

	useEffect(() => {
		dispatch(
			updateScheduledGenerationContent({
				...scheduledGenerationContent,
				competition_events: competitionEvents,
			}),
		);
	}, [competitionEvents]);

	return (
		<div data-qa={DATA_QA.ARTICLE_AI_GENERATION_ARTICLE_GENERATION_SCREEN} className={isLoading ? 'loading-overlay' : ''}>
			<div className='article-generation-header'>
				<h1>{t('ai_article_generation')}</h1>
				<span className='header-description-label'>{t('articles_ai_generation_description')}</span>
			</div>
			<div className='input-fields-row'>
				<CategorySelect
					scheduledGenerationContent={scheduledGenerationContent}
					errorState={errorState}
					errorMessage={errorState.category ? t('field_mandatory') : undefined}
					setErrorState={setErrorState}
				/>
			</div>
			<div className='input-fields-row'>
				<GenericGenerateField
					scheduledGenerationContent={scheduledGenerationContent}
					dataQa={DATA_QA.ARTICLE_AI_GENERATION_GENERATE_ARTICLE_GENERATE_STRAPLINE}
					labelText={'generate_strapline'}
					options={GENERATE_TYPES_OPTIONS(t)}
					type={'strapline'}
					isOptional
				/>
				<GenericGenerateField
					scheduledGenerationContent={scheduledGenerationContent}
					dataQa={DATA_QA.ARTICLE_AI_GENERATION_GENERATE_ARTICLE_GENERATE_SUMMARY}
					labelText={'generate_summary'}
					options={GENERATE_TYPES_OPTIONS(t)}
					type={'summary'}
					isOptional
				/>
				<GenerateBaseOnField
					scheduledGenerationContent={scheduledGenerationContent}
					errorState={errorState}
					errorMessage={errorState.generate_based_on ? t('field_mandatory') : undefined}
					setErrorState={setErrorState}
				/>
			</div>
			<div className='input-fields-row'>
				{scheduledGenerationContent && scheduledGenerationContent.generate_based_on === GenerateArticleBasedOn.EVENT && (
					<>
						<TeamSelect sport={SportTypes.FOOTBALL} entityTypes={[ContentTypes.TEAM]} scheduledGenerationContent={scheduledGenerationContent} />
						<EventSelect
							scheduledGenerationContent={scheduledGenerationContent}
							errorState={errorState}
							errorMessage={errorState.matches ? t('field_mandatory') : undefined}
							setErrorState={setErrorState}
							sport={SportTypes.FOOTBALL}
						/>
					</>
				)}
				{scheduledGenerationContent && scheduledGenerationContent.generate_based_on === GenerateArticleBasedOn.DATE && (
					<>
						<GenerationDateFromPicker
							generationDateFrom={(scheduledGenerationContent.generation_date && scheduledGenerationContent.generation_date[0]) || ''}
							onGenerationDateFromChange={(fromDate) => {
								dispatch(
									updateScheduledGenerationContent({
										...scheduledGenerationContent,
										generation_date: [fromDate, (scheduledGenerationContent.generation_date && scheduledGenerationContent.generation_date[1]) || ''],
										competition_ids: null,
										competition_events: null,
									}),
								);
							}}
						/>
						<GenerationDateToPicker
							generationDateFrom={(scheduledGenerationContent.generation_date && scheduledGenerationContent.generation_date[0]) || ''}
							generationDateTo={(scheduledGenerationContent.generation_date && scheduledGenerationContent.generation_date[1]) || ''}
							onGenerationDateToChange={(toDate) => {
								dispatch(
									updateScheduledGenerationContent({
										...scheduledGenerationContent,
										generation_date: [(scheduledGenerationContent.generation_date && scheduledGenerationContent.generation_date[0]) || '', toDate],
										competition_ids: null,
										competition_events: null,
									}),
								);
							}}
						/>
					</>
				)}
			</div>
			{scheduledGenerationContent && scheduledGenerationContent.matches && scheduledGenerationContent.matches.length > 0 && (
				<div>
					<ArticleGenerationInfoPanel infoText={getArticleGenerationInfoLabel(scheduledGenerationContent.matches, project.timezone)} />
				</div>
			)}
			{scheduledGenerationContent && scheduledGenerationContent.generate_based_on === GenerateArticleBasedOn.DATE && (
				<>
					<div className='input-fields-row'>
						<CompetitionsSelect
							competitionIds={scheduledGenerationContent.competition_ids}
							setCompetitionIds={onCompetitionIdsChange as React.Dispatch<React.SetStateAction<string[]>>}
							onCompetitionEventsChange={onCompetitionEventsChange}
							selectedDateRange={scheduledGenerationContent.generation_date ? scheduledGenerationContent.generation_date : []}
							sportType={SportTypes.FOOTBALL}
							competitionEvents={competitionEvents}
							errorState={errorState}
							errorMessage={errorState.competition_events ? t('field_mandatory') : undefined}
							setErrorState={setErrorState}
						/>
					</div>
					{scheduledGenerationContent &&
						scheduledGenerationContent.competition_events &&
						scheduledGenerationContent.competition_events.length > 0 && (
							<div>
								<ArticleGenerationInfoPanel
									infoText={getArticleGenerationInfoLabelBasedOnDate(scheduledGenerationContent.competition_events, project.timezone)}
								/>
							</div>
						)}
				</>
			)}
			<div>
				<ScheduleGenerationButton text={t('schedule_generation')} onClickAction={onGenerateSchedule} title={t('schedule_generation')} />
			</div>
		</div>
	);
};
