import { ContentMode, ContentTypes, ContentTypesPluralNaming, DynamicContentProperty } from '../../constants/content-types';
import {
	capitalize,
	extractContentModeBasedOnUrl,
	extractMainContentTypeBasedOnUrl,
	getCurrentUserUrl,
} from '../../global-helpers/global.helpers';
import Article from '../../models/article/Article';
import Banner from '../../models/banner/Banner';
import ContentAttributes from '../../models/content-attributes/content-attributes-model';
import Gallery from '../../models/gallery/Gallery';
import SeoBuilder from '../../models/seo/SeoBuilder';
import Video from '../../models/video/Video';
import Wiki from '../../models/wiki/Wiki';
import { ARTICLE_DRAFT_CLEAR, updateTempArticleProperty } from '../../store/action-creators/ArticleTempActionCreator';
import { updateTempVideoProperty } from '../../store/action-creators/VideoTempActionCreator';
import { updateTempGalleryProperty } from '../../store/action-creators/GalleryTempActionCreator';
import { updateTempWikiProperty } from '../../store/action-creators/WikiTempActionCreator';
import { store } from '../../store/store';
import { EditorTypes } from '../../views/Partials/Blocky/constants/block.types';
import Global from '../../views/Partials/Blocky/helpers/global.helpers';
import BlockModelBuilder from '../../views/Partials/Blocky/models/block-model.builder';
import BlockModel from '../../views/Partials/Blocky/models/block.model';
import { updateCategoryProperty } from '../../store/action-creators/CategoriesActionCreators';
import Category from '../../models/category/Category';

export const createStartingBlocky = (): BlockModel[] => {
	const defaultBodyData = {
		data: { type: EditorTypes.paragraph, content: '<p></p>' },
		id: `${Global.makeId(6)}`,
		type: EditorTypes.editor,
	};
	return [new BlockModelBuilder(defaultBodyData).build()];
};

export type availableContentTypes =
	| ContentTypes.ARTICLE
	| ContentTypes.VIDEO
	| ContentTypes.GALLERY
	| ContentTypes.WIKI_PAGE
	| ContentTypes.LIVE_BLOG
	| ContentTypes.CATEGORY
	| ContentTypes.TAG;
export type entitiesType = Article | Video | Gallery | Wiki;

export const getModelByContentTypeGlobal = (contentType: string) => {
	switch (contentType) {
		case ContentTypes.ARTICLE:
			return Article;
		case ContentTypes.VIDEO:
			return Video;
		case ContentTypes.GALLERY:
			return Gallery;
		case ContentTypes.WIKI_PAGE:
			return Wiki;
		case ContentTypes.BANNER:
			return Banner;
		case ContentTypes.CATEGORY:
			return Category;
		default:
			return null;
	}
};

export const getModelByContentType = (contentType: availableContentTypes) => {
	switch (contentType) {
		case ContentTypes.ARTICLE:
			return Article;
		case ContentTypes.VIDEO:
			return Video;
		case ContentTypes.GALLERY:
			return Gallery;
		case ContentTypes.WIKI_PAGE:
			return Wiki;
		default:
			return null;
	}
};

const getDefaultContentPropertiesByContentType = (contentType: availableContentTypes): ContentAttributes[] => {
	switch (contentType) {
		case ContentTypes.ARTICLE:
			return store.getState().contentProperties.articleProperties;
		case ContentTypes.VIDEO:
			return store.getState().contentProperties.videoProperties;
		case ContentTypes.GALLERY:
			return store.getState().contentProperties.galleryProperties;
		case ContentTypes.WIKI_PAGE:
			return store.getState().contentProperties.wikipageProperties;
		default:
			return [];
	}
};

// isReducerState -> cannot access the redux store when
// the function is called when the redux is initialized
export const createStartingEntity = (contentType: availableContentTypes, isReducerState: boolean = false) => {
	const project = store && store.getState().project && store.getState().project.currentProject;
	const lang: string = isReducerState ? '' : project.language;
	const contentEntity = getModelByContentType(contentType);

	if (contentEntity) {
		const dynamicProperties: DynamicContentProperty[] = !isReducerState
			? getDefaultContentPropertiesByContentType(contentType).map((el) => {
					return {
						name: el.name,
						slug: el.slug,
						value: el.isDefault,
					};
			  })
			: [];

		return contentEntity
			.builder()
			.withSeo(new SeoBuilder().withFollow(true).withIndex(true).build())
			.withCommentsCount({ count: '0' })
			.withViewsCount({ count: 0 })
			.withBody(createStartingBlocky())
			.withLanguage(lang)
			.withDynamicProperties(dynamicProperties)
			.build();
	}

	return null;
};

export const populateExistingEntity = (
	contentType: availableContentTypes,
	existingEntity: entitiesType,
	withNewStartingBody: boolean = false,
) => {
	const project = store.getState().project.currentProject;
	let entityLangCode = existingEntity.language ? existingEntity.language : project.language;
	const contentEntity = getModelByContentType(contentType);
	let entityResult = null;

	try {
		if (contentEntity) {
			if (withNewStartingBody) {
				entityResult = contentEntity
					.builder(existingEntity as any) // ts gives warning because existingEntity type can be diff to contentType
					.withId('temp-id')
					.withLanguage(entityLangCode)
					.withBody(createStartingBlocky())
					.build();
			} else {
				entityResult = contentEntity
					.builder(existingEntity as any)
					.withId('temp-id')
					.withLanguage(entityLangCode)
					.build();
			}

			return entityResult;
		}
	} catch {
		console.log('populateExistingEntityError');
	}

	return null;
};

// Redux props naming functions
export const generatePropsNamingByContentType = (contentType: string, modifyWikiString = true): string => {
	switch (contentType) {
		case ContentTypes.ARTICLE:
			return ContentTypes.ARTICLE;
		case ContentTypes.VIDEO:
			return ContentTypes.VIDEO;
		case ContentTypes.GALLERY:
			return ContentTypes.GALLERY;
		case ContentTypes.WIKI_PAGE:
			return modifyWikiString ? 'wiki' : ContentTypes.WIKI_PAGE;
		case ContentTypes.CATEGORY:
			return ContentTypes.CATEGORY;
		default:
			return contentType;
	}
};

const generatePropsNamingByContentMode = (contentMode: ContentMode): string => {
	switch (contentMode) {
		case ContentMode.EDIT:
			return capitalize(ContentMode.EDIT);
		default:
			return '';
	}
};

export const generateReduxPropsNamingForContentModels = (contentType: availableContentTypes, contentMode: ContentMode) => {
	const contentTypeNaming = generatePropsNamingByContentType(contentType);
	const contentModeNaming = generatePropsNamingByContentMode(contentMode);
	const tempPropertyNaming = `temp${capitalize(contentTypeNaming)}`;
	const nestedTempPropertyNaming = `${contentTypeNaming}${contentModeNaming}`;

	return {
		tempPropertyNaming,
		nestedTempPropertyNaming,
	};
};

export const autoGenerateReduxPropsNamingForContentModels = () => {
	const contentType = extractMainContentTypeBasedOnUrl() || ContentTypes.ARTICLE;
	const contentMode = extractContentModeBasedOnUrl();
	return generateReduxPropsNamingForContentModels(contentType, contentMode);
};
// END Redux props naming functions

export const generateReduxPropsUpdateActionByContentType = (contentType: availableContentTypes | null): Function | null => {
	switch (contentType) {
		case ContentTypes.ARTICLE:
			return updateTempArticleProperty;
		case ContentTypes.VIDEO:
			return updateTempVideoProperty;
		case ContentTypes.GALLERY:
			return updateTempGalleryProperty;
		case ContentTypes.WIKI_PAGE:
			return updateTempWikiProperty;
		case ContentTypes.CATEGORY:
			return updateCategoryProperty;
		default:
			return null;
	}
};

export const generateContentTypeIcon = (contentModelType: string) => {
	switch (contentModelType) {
		case ContentTypes.ARTICLE:
			return 'fa fa-file-text';
		case ContentTypes.VIDEO:
			return 'fa fa-video-camera';
		case ContentTypes.GALLERY:
			return 'fa fa-camera';
		case ContentTypes.LIVE_BLOG:
			return 'fa-solid fa-newspaper';
		default:
			return '';
	}
};

export const extractContentTypePluralNaming = (contentModelType: string) => {
	switch (contentModelType) {
		case ContentTypes.ARTICLE:
			return ContentTypesPluralNaming.ARTICLE;
		case ContentTypes.VIDEO:
			return ContentTypesPluralNaming.VIDEO;
		case ContentTypes.GALLERY:
			return ContentTypesPluralNaming.GALLERY;
		case ContentTypes.WIKI_PAGE:
			return ContentTypesPluralNaming.WIKI_PAGE;
		default:
			return '';
	}
};

export const createListenerForContentCreateRedirect = (historyObj: any, eventName: string, path: string) => {
	window.addEventListener(eventName, (data: any) => {
		const contentId = data.detail;
		contentId && historyObj.push(`/smp/${path}/edit/${contentId}`);
	});
};

export const extractActionDraftDeleteTypeBasedOnUrl = (): string | null => {
	const currentLocation = getCurrentUserUrl();
	// Made this structure so it can be extended in future if needed
	if (currentLocation) {
		if (currentLocation.includes('/articles/')) {
			return ARTICLE_DRAFT_CLEAR;
		}
	}

	return null;
};
