import React from 'react';
import './option-component.scss';
import { imagePlaceHolder } from '../../../../../../constants/resource-constants';

export function customOption(options: any) {
	const { logo, label, type, value } = options;

	return (
		<div style={{ gap: '4px' }} id={`football-connections-drop-down-option-${value}`} className={`option-container ${type}-option-border`}>
			<img width={23} height={23} className={`option-logo-${type}`} src={logo} onError={(i: any) => (i.target.src = imagePlaceHolder)} />
			<div className={'option-label'}>{label} </div>
		</div>
	);
}
