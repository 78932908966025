import { toast, ToastOptions } from 'react-toastify';
import React from 'react';
import '../../../../styles/custom-toast-notification.scss';
import { GenerateArticleRequestStatuses } from '../../../../helpers/article-ai-generation.helper';

export const showCustomToast = (title: string, message: string, type: GenerateArticleRequestStatuses) => {
	toast(
		<div className='custom-toast-content'>
			<span className='custom-toast-title'>{title}</span>
			<span className='custom-toast-message'>{message}</span>
		</div>,
		{
			className: `${type}`,
			position: 'top-right',
			autoClose: 10000,
			closeOnClick: true,
			hideProgressBar: true,
			closeButton: true,
		} as ToastOptions,
	);
};
