import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../../../store/store';
import { DATA_QA } from '../../../helpers/data-qa.properties';
import { initialSchedulesAdvancedFiltersState, ISchedulesAdvancedSearchFiltersModel } from '../../../models/models';
import { updateAiSchedulesAdvancedSearchFilters } from '../../../../../../store/action-creators/ai-articles-action-creator';
import ScheduledListingGenerationTimeAndDateSelect from './subcomponents/date-and-time';
import { useTranslation } from 'react-i18next';
import ScheduledListingCategorySelect from './subcomponents/category-select';
import CreatedBySelect from './subcomponents/created-by-select';
import StatusSelect from './subcomponents/status-select';
import CompetitionSelect from './subcomponents/competition-select';
import { SportTypes } from '../../../../../../constants/sport.types';

type Properties = {
	updateSchedulesAdvancedSearchText: (filters: ISchedulesAdvancedSearchFiltersModel) => void;
	contentSearchInput: string;
	getContent: (filters: ISchedulesAdvancedSearchFiltersModel) => void;
};

export const ScheduledArticlesAdvancedFilters: React.FunctionComponent<Properties> = ({
	updateSchedulesAdvancedSearchText,
	contentSearchInput,
	getContent,
}) => {
	const dispatch = useDispatch();
	const [t] = useTranslation();
	const schedulesFilters = useSelector((state: AppState) => state.aiArticles.scheduleListingFilters);
	const [schedulesAdvancedFiltersModel, setSchedulesAdvancedFiltersModel] = useState<ISchedulesAdvancedSearchFiltersModel>(
		schedulesFilters ? schedulesFilters : ({} as ISchedulesAdvancedSearchFiltersModel),
	);

	useEffect(() => {
		updateSchedulesAdvancedSearchInput(schedulesAdvancedFiltersModel);
	}, []);

	const updateSchedulesAdvancedSearchInput = (advancedContentModel: ISchedulesAdvancedSearchFiltersModel) => {
		updateSchedulesAdvancedSearchText(advancedContentModel);

		const filters = advancedContentModel;
		if (filters) {
			filters.event_name = contentSearchInput;
		}

		dispatch(updateAiSchedulesAdvancedSearchFilters(filters));
		setSchedulesAdvancedFiltersModel(advancedContentModel);
	};

	const clearAdvancedFilters = () => {
		const advancedContentFilters = { ...initialSchedulesAdvancedFiltersState };
		updateSchedulesAdvancedSearchInput(advancedContentFilters);
		setSchedulesAdvancedFiltersModel(advancedContentFilters);
		getContent(advancedContentFilters);
	};

	return (
		<>
			<div data-qa={DATA_QA.ARTICLE_AI_SCHEDULES_SCHEDULE_ADVANCED_SEARCH_FILTERS}>
				<div className='advanced-filters-row'>
					<CompetitionSelect
						sport={SportTypes.FOOTBALL}
						schedulesFilters={schedulesAdvancedFiltersModel}
						updateSchedulesAdvancedSearchText={updateSchedulesAdvancedSearchInput}
					/>
					<ScheduledListingCategorySelect
						schedulesFilters={schedulesAdvancedFiltersModel}
						updateSchedulesAdvancedSearchText={updateSchedulesAdvancedSearchInput}
					/>
					<ScheduledListingGenerationTimeAndDateSelect
						schedulesFilters={schedulesAdvancedFiltersModel}
						updateSchedulesAdvancedSearchText={updateSchedulesAdvancedSearchInput}
					/>
					<CreatedBySelect
						schedulesFilters={schedulesAdvancedFiltersModel}
						updateSchedulesAdvancedSearchText={updateSchedulesAdvancedSearchInput}
					/>
					<StatusSelect
						schedulesFilters={schedulesAdvancedFiltersModel}
						updateSchedulesAdvancedSearchText={updateSchedulesAdvancedSearchInput}
					/>
				</div>
			</div>
			<div className='advanced-filters-buttons-group'>
				<div className='advanced-search-button' onClick={() => getContent(schedulesAdvancedFiltersModel)}>
					<span className='advanced-search-button-label'>{t('search')}</span>
				</div>
				<div className='clear-filters-button' onClick={() => clearAdvancedFilters()}>
					<span className='clear-filters-button-label'>{t('clear_filters')}</span>
				</div>
			</div>
		</>
	);
};
